import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SignUpButton from "./SignUpButton";
import { InfoCards } from "./InfoCards";
import { homeContent } from "./content";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReactGA from 'react-ga';
import CardMedia from '@mui/material/CardMedia';
import TermsAndConditionsNotice from '../../components/TermsAndConditionsNote';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Partners } from './Partners';

function Home() {

    const myProfile = useSelector(state => state.appState.myProfile);
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + 'LandingPage');
    }, []);


    const onEventCardClick = function (cardName) {
        ReactGA.event({
            category: 'List_Item_Clicks',
            action: 'HomePageEventCardClick',
            label: cardName,
            dimension1: myProfile ? "registeredUser" : "newUser"
        });
        // navigate(myProfile ? '/roundtables' : '/signup');
    };

    const EventCard = ({ hint, title, image, host, role, aboutHost }) => {
        return <Box
            onClick={() => onEventCardClick(host)}
            sx={{
                background: "#120a64",
                backgroundImage: 'url("/images/home/events/event_bg.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
                p: 1,
                height: '100%'
            }}>
            <Typography fontSize={10} color="#0090fb" fontWeight={800}>{hint}</Typography>
            <Typography
                fontSize={12}
                color="antiquewhite"
                fontWeight={700}
                pt={0.25}
                maxHeight="4em"
                minHeight={{ xs: "2em", md: "4em" }}
            >{title}</Typography>
            <Box display={{ xs: 'none', sm: 'none', md: 'unset' }}>
                <Box display="flex" justifyContent="center" py={0.75}>
                    <img src={image} alt={title}
                        style={{
                            width: '50%',
                            maxWidth: '100px'
                        }}>
                    </img>
                </Box>
                <Typography
                    fontSize={12}
                    color="antiquewhite"
                    fontWeight={700}
                    py={0.25}
                >{host}</Typography>
                <Typography
                    component="div"
                    fontSize={10}
                    color="antiquewhite"
                    fontWeight={400}
                    maxHeight="6em"
                    minHeight="6em"
                    overflow="hidden"
                    mb={0.75}
                >
                    {role}
                </Typography>
                <Typography
                    component="div"
                    fontSize={10}
                    color="#febe00"
                    fontWeight={400}
                    maxHeight="6em"
                    minHeight="6em"
                    overflow="hidden"
                >{aboutHost}</Typography>
            </Box>
            <Box display={{ md: 'none', lg: 'none', xl: 'none' }}>
                <Box display="flex" justifyContent="center" py={0.75} gap={1}>
                    <Box minWidth="80px">
                        <img src={image} alt={title}
                            style={{
                                width: '100%',
                                maxWidth: '100px'
                            }}>
                        </img>
                    </Box>
                    <Box>
                        <Typography
                            fontSize={12}
                            color="antiquewhite"
                            fontWeight={700}
                            py={0.25}
                        >{host}</Typography>
                        <Typography
                            component="div"
                            fontSize={10}
                            color="antiquewhite"
                            fontWeight={400}
                            minHeight="2em"
                            overflow="hidden"
                            mb={0.75}
                        >
                            {role}
                        </Typography>

                        <Typography
                            component="div"
                            fontSize={10}
                            color="#febe00"
                            fontWeight={400}
                            minHeight="3em"
                            overflow="hidden"
                        >{aboutHost}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    }

    const EventCardStatic = () => {
        const GroupEvent = ({ title, description, titleColor = "#febe00" }) => {
            return <Box>
                <Typography
                    fontSize={12}
                    color={titleColor}
                    fontWeight={700}
                    pt={0.25}
                >{title}
                </Typography>
                <Typography
                    component="div"
                    fontSize={10}
                    color="antiquewhite"
                    fontWeight={400}
                    overflow="hidden"
                    mb={0.5}
                >
                    {description}
                </Typography>
            </Box>
        }

        return <Box
            onClick={() => onEventCardClick("Other Events")}
            sx={{
                background: "#120a64",
                backgroundImage: 'url("/images/home/events/event_bg.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
                p: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
            <Typography
                fontSize={10}
                color="#0090fb"
                fontWeight={800}
            >
                START OR JOIN SMALL PEER GROUP EVENTS LIKE THESE
            </Typography>
            <GroupEvent
                title="Zscaler Admin Meetup"
                description="Meet and network with other Zscaler admins around the world"
            />
            <GroupEvent
                title="OSCP Cert Prep Group UK"
                description="Meet and compare notes with others working OSCP certifications in UK"
            />
            <GroupEvent
                title="Cyber Social"
                description="No agenda! Just meet small group of like minded people to network"
            />
            <GroupEvent
                title="UBER Breach Lessons Learnt"
                description="Discuss the lessons learnt from this high breach with your peers"
            />
            <GroupEvent
                title="Start your own LIVE peer discussions now..."
                description="solve a problem, share an experience and build your cybersecurity network"
                titleColor='red'
            />
        </Box>

    }

    return (
        <Box>
            <Box className='section_1'
                sx={{
                    // backgroundColor: 'background.main',
                    position: 'sticky',
                    px: { xs: 2, md: 3 },
                    width: '-webkit-fill-available',
                    pb: { xs: 2 },
                    // border: 1,
                }}>
                <Box className='text_content'
                    sx={{
                        // px: { xs: 1, },
                        mt: { xs: 2, md: 7 },
                        mb: { xs: 2, md: 5 },
                        ml: { xs: 0, sm: 5, md: 18, xl: 30 },
                        maxWidth: { xs: 400, lg: 500 },
                        // border: 1,
                    }}>
                    <Box className='header_main'
                        sx={{
                            my: { xs: 2, md: 5 },
                            // maxWidth: {lg: 400 },
                            // border: 1,
                        }}
                    >
                        <Typography variant='h2' color='primary'>
                            <div dangerouslySetInnerHTML={{ __html: homeContent.mainHeader }} />
                        </Typography>
                    </Box>
                    <Box className='header_subtext'
                        sx={{
                            my: { xs: 2, md: 5 },
                            maxWidth: { xs: 580, md: 580, lg: 630 },
                            // border: 1,
                        }}
                    >
                        <Typography variant="h5" component="p" textAlign='left' fontWeight={400}>
                            {homeContent.mainDescription}
                        </Typography>
                    </Box>
                    {
                        !myProfile ?
                            <Box sx={{
                                background: { xs: '#ffffffad', md: 'unset' },
                                py: { xs: 1, md: 'unset' },
                                borderRadius: '4px'
                            }} >
                                <Box className='T_and_C'
                                    sx={{
                                        //my: { xs: 2, md: 5 },
                                        maxWidth: { xs: 580, md: 580 }
                                    }}
                                >
                                    <TermsAndConditionsNotice prefixText={'By reqesting invitation'} />
                                </Box>
                                <Box className='sign_in_button'
                                    sx={{
                                        zIndex: 1,
                                        mt: { xs: 2 },
                                        textAlign: 'left',
                                        // border: 1,
                                    }}
                                >
                                    <SignUpButton gaLabel='homepage_firstButton' text='Request Invitation' showLIIcon={true} />
                                </Box>
                            </Box>
                            :
                            <Box className='palceholder'
                                sx={{
                                    height: { xs: 95 },
                                }}
                            />
                    }
                </Box>
                <Box className='image1'
                    sx={{
                        zIndex: -1,
                        width: 1,
                        maxWidth: { xs: 220, sm: 350, md: 450, lg: 650, xl: 750 },
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        // border: 1,
                    }}
                >
                    <img src='/images/home/hero_1.png' alt=''
                        style={{
                            width: '-webkit-fill-available',
                        }}>
                    </img>
                </Box>
            </Box>
            
            <Box className='section_2'
                sx={{
                    pb: { xs: 5, sm: 8 },
                    px: { xs: 2, md: 3 },
                    // border: 1,
                }}>
                <Box id="ourpartners" className="how_rt_works_title" px={{ xs: 0, sm: 5, md: 18, xl: 30 }} py={1} >
                    <Typography variant='subtitle1' component="p" textAlign='center'>
                        Collaborate with your peers from
                    </Typography>
                    <Partners/>
                </Box>
                <Box id="currentEvents" className="how_rt_works_title" px={{ xs: 0, sm: 5, md: 18, xl: 30 }} pb={5} >
                    <Typography variant='subtitle1' component="p" textAlign='center' mb={2}>
                        Sign up now to join events like these
                    </Typography>
                    <Grid container columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} spacing={1}>
                        <Grid item xs={1}>
                            <EventCard
                                hint="KEYNOTE"
                                title="CYBER INSURANCE - Dispelling Myths and Distilling Realities"
                                image="/images/home/events/P_01.png"
                                host="David Finz"
                                role="First Vice President, Cyber Solutions Alliant Insurance Services"
                                aboutHost="Author of the Book: The Cyber Insurance Imperative: What Small and Midsized Businesses Should Know About Managing the Financial Impact of Cyber Risk"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <EventCard
                                hint="KEYNOTE"
                                title="Offense is the best defense - Hackers Perspective"
                                image="/images/home/events/P_02.png"
                                host="Prateek Bhajanka"
                                role="Technology Strategist, SentinelOne and Former Gartner Analyst"
                                aboutHost="Prateek is an experienced cybersecurity thought leader and has been advising CXOs on their security strategies and priorities."
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <EventCard
                                hint="KEYNOTE"
                                title="Building an Effective Cybersecurity Culture"
                                image="/images/home/events/P_03.png"
                                host="Chirag Joshi"
                                role="M.S., CISA, CISM, CRISC, CDPSE
                                    Director ISACA Sydney. Experienced cyber security, risk management and compliance leader"
                                aboutHost="Bestselling author of books “7 Rules to Become Exceptional at Cyber Security” and “7 Rules to Influence Behaviour and Win at Cyber Security Awareness."
                            />

                        </Grid>
                        <Grid item xs={1} display={{ xs: 'none', lg: 'block' }}>
                            <EventCard
                                hint="KEYNOTE"
                                title="Challenges & Opportunities of the Modern CISO"
                                image="/images/home/events/P_04.png"
                                host="Abbas Kudrati"
                                role="Lead Chief Cybersecurity Advisor APC, Microsoft
                                    Award winning CISO, Keynote speaker, executive advisor to Deakin University, LaTrobe University, HITRUST ASIA and EC Council ASIA."
                                aboutHost="Bestselling author of books “Threat Hunting in the Cloud”, “Zero Trust Journey Across the Digital Estate”, and “Digitization Risks in Post Pandemic World”"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <EventCardStatic />
                        </Grid>
                    </Grid>

                </Box>

                <Box className="how_rt_works_title"
                    sx={{
                        // border: 1,
                    }}
                >
                    <Typography variant="h3" textAlign='center'>
                        {homeContent.howRTWorks.title}
                    </Typography>
                </Box>
                <Box className="how_rt_works_subtitle"
                    sx={{
                        pt: { xs: 1, lg: 0.5 }
                        // border: 1,
                    }}
                >
                    <Typography variant='subtitle1' component="p" textAlign='center'>
                        {homeContent.howRTWorks.subTitle}
                    </Typography>
                </Box>
                <Box className="info_cards"
                    sx={{
                        textAlign: 'center',
                        px: { xs: 0, sm: 5, md: 18, xl: 30 },
                        pt: { xs: 3, lg: 4 }
                        // border: 1,
                        // borderColor: 'red'
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        columns={18}
                    // border={1}
                    // borderColor='blue'
                    >
                        {
                            homeContent.infoCards.map((inforCard) => (
                                <Grid item
                                    xs={18}
                                    sm={5}
                                    key={inforCard.sortOrder}
                                // border={1}
                                // borderColor='green'
                                >
                                    <InfoCards card={inforCard}></InfoCards>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>
            <Box className='section_4'
                sx={{
                    backgroundColor: 'background.main',
                    // border: 1,
                }}>
                <CardMedia
                    component="img"
                    image="/images/home/banner_mid_page.png"
                    alt="join roundtables"
                />
            </Box>
            <Box className='section_5'
                sx={{
                    px: { xs: 2, md: 3 },
                    py: { xs: 2, md: 6 },
                    // border: 1,
                }}
            >
                <Box className="to_whom"
                    sx={{
                        px: { xs: 0, sm: 5, md: 18, xl: 30 },
                        textAlign: 'center',
                        // border: 1,
                    }}
                >
                    <Box className="to_whom_title"
                        sx={{
                            // border: 1,
                        }}
                    >
                        <Typography variant="h3" textAlign='center'>
                            {homeContent.toWhomTitle}
                        </Typography>
                    </Box>
                    <Box className="to_whom_subtitle"
                        sx={{
                            pt: { xs: 1 }
                            // border: 1,
                        }}
                    >
                        <Typography variant='subtitle1' component="p" textAlign='center'>
                            {homeContent.toWhomSubtitle}
                        </Typography>
                    </Box>
                    <Box className="to_whom_points"
                        sx={{
                            pt: { xs: 2, md: 5 },
                            px: { xs: 0, sm: 5, md: 7, lg: 12, xl: 30 },
                            // border: 1,
                        }}
                    >
                        {
                            homeContent.toWhomPoints.map((point) => (
                                <Grid container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    key={point.sortOrder}
                                    sx={{
                                        pb: 2,
                                        // border: 1,
                                    }}
                                >
                                    <Grid className='point_icon' item xs={1}
                                        sx={{
                                            pt: 1,
                                            textAlign: 'right',
                                            // border: 1,
                                        }}
                                    >
                                        <CheckCircleIcon color='primary' />
                                    </Grid>
                                    <Grid className='text' item xs={11}
                                        sx={{
                                            pl: 1,
                                            // border: 1,
                                        }}>
                                        <Typography variant='h4' fontWeight={300}>
                                            {point.text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
            {!myProfile && <Box className='section_6'
                sx={{
                    width: '-webkit-fill-available',
                    backgroundColor: 'background.main',
                    position: 'sticky',
                    px: { xs: 3 },
                    pt: { xs: 3, sm: 5, md: 6 },
                    // pb: { xs: 4, sm: 6, md: 7 },
                    // border: 1,
                }}
            >
                <Grid container direction='column' className='join_the_network'
                    sx={{
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: "center",
                        // border: 1,
                    }}
                >
                    {/* <Typography variant="h3" color="primaryX.main" textAlign='center'>
                        {homeContent.joinUs}
                    </Typography> */}

                    <Box className='sign_in_button_2'
                        sx={{
                            // width: '-webkit-fill-available',
                            // maxWidth: { xs: 400, sm: 300 },
                            // mt: { xs: 2, md: 5 },
                            // border: 1,
                        }}
                    >
                        <SignUpButton gaLabel='homepage_secondButton' text='Request Invitation' showLIIcon={true} />
                    </Box>
                    <Box className='T_and_C'
                        sx={{
                            mt: { xs: 2 },
                            maxWidth: { xs: 580, md: 580 },
                            // border: 1,
                            textAlign: "center"
                        }}
                    >
                        <TermsAndConditionsNotice prefixText={'By reqesting invitation'} />
                    </Box>
                </Grid>
            </Box>
            }
            <Box className='section_7'
                sx={{
                    width: 1,
                    maxWidth: 1,
                    backgroundColor: 'background.main',
                    // border: 1,
                }}
            >
                <Grid className='Banner2'
                    sx={{
                        maxWidth: 1,
                        // border: 1,
                        alignItems: 'stretch',
                        justifyContent: 'flex-end'
                    }}
                >
                    <img src='/images/home/banner_bottom.png' alt=''
                        style={{
                            width: '-webkit-fill-available',
                            // border: 'solid',
                        }}
                    ></img>
                </Grid>
            </Box>
        </Box >
    );
};

export default Home;