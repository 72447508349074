let infoDialog = {
    'title': 'Few tips for a great meeting experience',
    'tips': [
        {
            'sortOrder': 1,
            'text': 'Give permission for your browser to access mic and camera for the meeting.',
            // 'icon': 'info_card_icon_1.svg',
        },
        {
            'sortOrder': 2,
            'text': 'Do not join the roundtable more than once to avoid feedback and echo.',
            // 'icon': 'info_card_icon_2.svg',
        },
        {
            'sortOrder': 3,
            'text': 'Please use a Chromium broweser (Chrome, Edge) for a better experience.',
            // 'icon': 'info_card_icon_3.svg',
        },
        {
            'sortOrder': 4,
            'text': 'Please close other meeting application such as Teams and Zoom to avoid audio issues.',
            // 'icon': 'info_card_icon_3.svg',
        }
    ],
    'endNote': 'Have a productive session!',
    'okButtonText': 'OK',
    'cancelButtonText': 'Cancel',
};

export { infoDialog };