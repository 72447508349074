import { Box } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import { sp1, sp3, spx9 } from "../../configs/spacings";
import Me from "./Me";
import ProfileVerifyEmailPage from "./ProfileVerifyEmailPage";

export default function Profile() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'backgroundSecondary.main', minHeight: 'inherit' }}>
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        pb: sp3,
                        pt: sp1,
                        px: spx9,
                        backgroundColor: "backgroundSecondary.main"
                    }}>
                    <Routes>
                        <Route path="/me" element={<Me />} />
                        <Route path="/me/verifyemail" element={<ProfileVerifyEmailPage />} />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
}