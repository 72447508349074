import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import { useTheme } from '@emotion/react';
import { useNavigate } from "react-router-dom";
import { FormControlLabel, Switch, Tab, Tabs, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { getMasterData } from "../../services/masterDataService";
import profileService from "../../services/profileService";
import { updateWeeklyEmailPreference } from "../../services/userService";
import { profileContent } from "./content";
import { sp3, sp4 } from "../../configs/spacings";
import { CypeerLoading } from "../../components/CypeerLoading";
import ProfileBanner from "./ProfileBanner";
import TermsAndConditionsNotice from "../../components/TermsAndConditionsNote";
import Followers from "./Followers";

export default function Me() {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const editMode = window.location.hash === '#edit';

    const [tab, setTab] = useState(0);
    const [masterData, setMasterData] = useState(null);
    const [profile, setProfile] = useState(null);
    const [weeklyEmail, setWeeklyEmail] = useState(true);
    const [submitionLoading, setsubmitionLoading] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [formData, setformData] = useState({
        country: null,
        organization: '',
        role: null,
        industry: null,
        interests: [],
        typeInterestOn: false,
        maxInterests: false,
        maxCustomInterests: false,
    });
    const [editModeLoading, setEditModeLoading] = useState(true);

    useEffect(() => {

        ReactGA.pageview(window.location.pathname);

        profileService.getUserProfile().then(data => {
            // TODO - Correcting the miss-spelled property name "counrty" 
            // Remove this after DB fix           
            data.country = data.counrty;
            delete data.counrty;
            setProfile(data);

            if (data.userPreferences?.weeklyAlerts === false) {
                setWeeklyEmail(false);
            }

            if (editMode || data.isNewUser) {
                navigate('#edit', { replace: true });
            }
        });
    }, []);

    useEffect(() => {
        if (editMode) {
            setEditModeLoading(true);
            enterEditMode();
        };
    }, [window.location.hash]);

    useEffect(() => {
        checkInterestsLimit();
    }, [formData.interests]);

    useEffect(() => {
        if (masterData && profile) {
            setformData({
                ...formData,
                country: profile.country ?? null,
                organization: profile.organization ?? '',
                role: profile.role ?? null,
                industry: profile.industry ?? null,
                interests: mapSelectedInterests(),
            });
            setEditModeLoading(false);
        };
    }, [masterData, profile]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleCountrySelect = function (event, newValue) {
        setformData({ ...formData, country: newValue });
    };

    const handleOrganizationChange = function (event) {
        setformData({ ...formData, organization: event.target.value });
    };

    const handleRoleSelect = function (event, newValue) {
        setformData({ ...formData, role: newValue });
    };

    const handleIndustrySelect = function (event, newValue) {
        setformData({ ...formData, industry: newValue });
    };

    const toggleSelectInterest = function (interest) {
        setFormValid(true);
        let intsCopy = [...formData.interests];
        let selectedInt = intsCopy[intsCopy.findIndex(i => i.id === interest.id)];
        selectedInt.isSelected = !selectedInt.isSelected;
        setformData({ ...formData, interests: intsCopy });
    };

    const toggleTypeInterestOn = function () {
        setformData({ ...formData, typeInterestOn: !formData.typeInterestOn });
    };

    const handleAddNewInterest = function (event) {
        if (event.target.value && event.target.value !== '') {
            setFormValid(true);
            let intsCopy = [...formData.interests];
            let newInterest = {
                id: intsCopy.length + 1,
                value: event.target.value,
                isSelected: true,
                verified: false,
                isNew: true,
            }
            intsCopy.push(newInterest);
            checkInterestsLimit();
            setformData({ ...formData, interests: intsCopy, typeInterestOn: false });

        } else {
            toggleTypeInterestOn();
        }
    };

    const handleSubmit = function (event) {
        event.preventDefault();

        if (formData.interests.filter(i => i.isSelected).length === 0) {
            setFormValid(false);
            return;
        }

        setsubmitionLoading(true);

        var updatedProfile = {
            "country": formData.country ? formData.country.id : undefined,
            "organization": formData.organization,
            "role": formData.role ? formData.role.id : undefined,
            "industry": formData.industry ? formData.industry.id : undefined,
            "interests": formData.interests.filter(i => i.isSelected && !i.isNew).map(n => n.id),
            "customInterests": formData.interests.filter(i => i.isSelected && i.isNew).map(n => n.value),
        }

        profileService.updateUserProfile(updatedProfile)
            .then(data => {
                if (data === 200) {
                    //Update the profile in app state
                    profileService.getMyProfile();

                    profileService.getUserProfile().then(newProfile => {
                        // TODO - Correcting the miss-spelled property name "counrty" 
                        // Remove this after DB fix           
                        newProfile.country = newProfile.counrty;
                        delete newProfile.counrty;

                        setProfile(newProfile);
                        setsubmitionLoading(false);
                        exitEditMode();
                    });
                } else {
                    setsubmitionLoading(false);
                    //TODO : Malika - provide meaningfull alert with little more details
                    /* Example:
                    displayAlertDialog({
                        title: "Too Many Requests",
                        message: "You have made too many requests for this page. Please try refreshing again after a minute"
                    })
                     */
                    alert("Something went wrong!" + data);
                }
            });
    };

    const handleFormKeyDown = function (event) {
        if (event.key === 'Enter' && event.shiftKey === false) {
            // If the Enter key was pressed for newInterest input field, handle it
            if (event.target.id === 'newInterest') {
                handleAddNewInterest(event);
            }
            // Prevent Form from submitting
            event.preventDefault();
        }
    };

    const createInterestsChips = function () {
        return formData.interests.map((interest, i) => {
            return (
                <Box key={i} sx={{
                    height: 48,
                    pr: { xs: 2 },
                    py: { xs: 1 },
                    // border: 1,
                }}>
                    <Chip
                        key={interest.id.toString()}
                        icon={interest.isSelected ? <RemoveOutlinedIcon fontSize="small" /> : <AddOutlinedIcon fontSize="small" sx={{ "&&": { color: "#000000" } }} />}
                        label={<Typography variant="subtitle1" color={!interest.isSelected ? "#000000" : ""}>{interest.value}</Typography>}
                        onClick={() => toggleSelectInterest(interest)}
                        color={interest.isSelected ? "success" : "neutral"}
                        variant={interest.isSelected ? "contained" : "outlined"}
                        disabled={!interest.isSelected && (formData.maxInterests || (!interest.verified && formData.maxCustomInterests))}
                        sx={{
                            px: { md: 1, },
                            py: { xs: 1, },
                            borderRadius: 25,
                        }}
                    />
                </Box>
            );
        });
    };

    const checkInterestsLimit = function () {
        let selectedInterests = formData.interests.filter(i => i.isSelected).length >= 5;
        let selectedCustomInterests = formData.interests.filter(i => i.isSelected && !i.verified).length >= 5;

        setformData({ ...formData, maxInterests: selectedInterests, maxCustomInterests: selectedCustomInterests });
    }

    const createStaticInterestsChips = function () {
        return profile.interests.map((interest, i) => {
            return (
                <Box key={i}
                    sx={{
                        height: 48,
                        pr: { xs: 2 },
                        py: { xs: 1 },
                        // border: 1,
                    }}>
                    <Chip
                        key={interest.id.toString()}
                        label={<Typography variant="subtitle1" fontWeight={500} color='textHighEmphasis.main'>{interest.value}</Typography>}
                        variant="outlined"
                        sx={{
                            borderRadius: 25,
                        }}
                    />
                </Box>
            );
        });
    };

    const createStaticDisplayBlock = function (className, label, value) {
        return (
            <Grid item className={className} xs={12} sm={6} xl={3}
                sx={{
                    pt: { xs: 2 },
                    pr: { sm: 2 },
                }}
            >
                <FormLabel>
                    <Typography variant="subtitle2">{label}: </Typography>
                    <Typography variant="subtitle1" fontWeight={600} color='textHighEmphasis.main'>{value}</Typography>
                </FormLabel>
            </Grid>
        );
    }

    function enterEditMode() {
        getMasterData().then(data => {
            setMasterData(data);
        });
    };

    function exitEditMode() {
        setformData({
            country: null,
            organization: '',
            role: null,
            industry: null,
            interests: [],
            typeInterestOn: false,
            maxInterests: false,
            maxCustomInterests: false,
        });
        setFormValid(true);
        setsubmitionLoading(false);

        navigate('/profile/me', { replace: true });
    }

    function mapSelectedInterests() {
        const myInterests = profile?.interests ?? []
        const masterInterests = masterData?.interests ?? [];

        let verifiedSelects = masterInterests.filter((mi) => {
            return myInterests.some((pi) => {
                return mi.id === pi.id;
            });
        }).map(vi => vi.id);

        let unverifiedSelects = myInterests.filter((pi) => {
            return !verifiedSelects.includes(pi.id);
        });

        let mappedInterests = masterInterests.map((mi) => {
            if (verifiedSelects.includes(mi.id)) {
                mi.isSelected = true;
            }
            return mi;
        });

        unverifiedSelects.forEach(ui => {
            mappedInterests.push({
                ...ui,
                isSelected: true,
                isVerified: false
            });
        });

        return mappedInterests;
    }

    const handleWeeklyEmailChange = (event) => {
        setWeeklyEmail(event.target.checked);

        updateWeeklyEmailPreference(event.target.checked);
    };

    return (
        <>

            <Paper variant='outlined' className="card"
                sx={{
                    borderRadius: '0.8rem',
                    px: sp4,
                    py: sp3,
                    mb: sp4
                }}
            >
                {!editMode && profile &&
                    <Grid className='edit_button' container
                        direction='row'
                        justifyContent='flex-end'
                    >
                        <IconButton sx={{ padding: 0.5 }} onClick={() => navigate('#edit', { replace: true })}>
                            <ModeEditIcon fontSize={smallScreen ? "small" : "medium"} />
                        </IconButton>
                    </Grid>
                }

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="stretch"
                    justifycontent="center"
                // border={1}
                >
                    {
                        profile ?
                            <>
                                <ProfileBanner profile={profile} noHrLine={true} />
                                {!editMode &&
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'primary.main' }}>
                                            <Tabs value={tab} onChange={handleTabChange}
                                                aria-label="basic tabs example"
                                                textColor="inherit"
                                            >
                                                <Tab label="About" sx={{ pb: 0 }} />
                                                <Tab label="Followers" sx={{ pb: 0 }} />
                                                <Tab label="Following" sx={{ pb: 0 }} />
                                            </Tabs>
                                        </Box>
                                    </Box>
                                }
                            </>
                            :
                            <></>
                    }

                    <Grid item sx={{ pt: sp3 }}>
                        {

                            editMode ?
                                !editModeLoading ?
                                    <form className='edit_profile' onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
                                        <Grid className='section_1_mandatory_info' container
                                            sx={{
                                                mb: { xs: 2, md: 5 }
                                            }}
                                        >
                                            <Grid item className='organization' xs={12} sm={6} xl={3}
                                                sx={{
                                                    pt: { xs: 2 },
                                                    pr: { sm: 2 },
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <TextField id="organizationInput" label={profileContent.orgNameLabel} type="text" value={formData.organization} onChange={handleOrganizationChange} required />
                                                </FormControl>
                                            </Grid>
                                            <Grid item className='industry' xs={12} sm={6} xl={3}
                                                sx={{
                                                    pt: { xs: 2 },
                                                    pr: { sm: 2 },
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        openOnFocus
                                                        blurOnSelect
                                                        id="industrySelect"
                                                        value={formData.industry}
                                                        onChange={handleIndustrySelect}
                                                        getOptionLabel={(option) => option.value}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        options={masterData.industries}
                                                        renderInput={(params) => <TextField {...params} label={profileContent.industryLabel} required />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item className='role' xs={12} sm={6} xl={3}
                                                sx={{
                                                    pt: { xs: 2 },
                                                    pr: { sm: 2 },
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        openOnFocus
                                                        blurOnSelect
                                                        id="roleSelect"
                                                        value={formData.role}
                                                        onChange={handleRoleSelect}
                                                        getOptionLabel={(option) => option.value}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        options={masterData.roles}
                                                        renderInput={(params) => <TextField {...params} label={profileContent.roleLabel} required />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item className='country' xs={12} sm={6} xl={3}
                                                sx={{
                                                    pt: { xs: 2 },
                                                    pr: { sm: 2 },
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        openOnFocus
                                                        blurOnSelect
                                                        id="countrySelect"
                                                        value={formData.country}
                                                        onChange={handleCountrySelect}
                                                        getOptionLabel={(option) => option.value}
                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                        options={masterData.countries}
                                                        renderInput={(params) => <TextField {...params} label={profileContent.countryLabel} required />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid className='section_2_interests' container>
                                            <Grid item className='title' xs={12}
                                                sx={{
                                                    my: { xs: 1 }
                                                }}
                                            >
                                                <FormLabel>{profileContent.interestsTitle}
                                                    <Typography variant="subtitle2">
                                                        {profileContent.interestsSubtitle}
                                                    </Typography>
                                                </FormLabel>
                                            </Grid>
                                            <Grid container className='interests' direction='row' alignItems='flex-start' item xs={12}
                                                sx={{
                                                    my: { xs: 1, md: 3 },
                                                    // border: 1,
                                                }}
                                            >
                                                {formData.interests ? createInterestsChips() : <></>}

                                                <Box className='addNewButton'
                                                    sx={{
                                                        height: 48,
                                                        pr: { xs: 2 },
                                                        py: { xs: 1 },
                                                        // border: 1,
                                                    }}>
                                                    {
                                                        formData.typeInterestOn ?
                                                            <FormControl fullWidth>
                                                                <OutlinedInput
                                                                    id="newInterest"
                                                                    type="search"
                                                                    autoFocus
                                                                    onBlur={handleAddNewInterest}
                                                                    sx={{
                                                                        borderRadius: 25,
                                                                        "& legend": {
                                                                            width: 0,
                                                                        }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                            :
                                                            <Button variant="contained" color="primary"
                                                                onClick={toggleTypeInterestOn}
                                                                disabled={formData.maxInterests || formData.maxCustomInterests}
                                                                sx={{
                                                                    px: 0,
                                                                    py: { xs: 1, },
                                                                    borderRadius: 25,
                                                                }}
                                                            >
                                                                <AddOutlinedIcon />
                                                            </Button>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid className='section_4_actions' container
                                            sx={{
                                                mt: { xs: 3, md: 6 },
                                                // border: 1,
                                            }}
                                        >
                                            <Grid className='t_and_c' item xs={12}
                                                sx={{
                                                    display: profile.isNewUser ? 'block' : 'none',
                                                    textAlign: { xs: 'center', sm: 'right' },
                                                    py: 1,
                                                }}
                                            >
                                                <TermsAndConditionsNotice prefixText={'By submitting'} />
                                            </Grid>
                                            <Grid className='buttons' item xs={12}
                                                container
                                                justifyContent='flex-end'
                                                alignItems='center'
                                            // border={1}
                                            >
                                                <Grid item container
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    width={{ sm: 'fit-content' }}
                                                >
                                                    <Grid item container justifyContent='flex-end'>
                                                        <Grid item xs={6}>
                                                            <Button className="button_cancel" variant="outlined" size="small"
                                                                onClick={exitEditMode}
                                                                disabled={submitionLoading}
                                                                sx={{
                                                                    width: '-webkit-fill-available',
                                                                    px: { sm: 6 },
                                                                    // py: { xs: 1 },
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button className="button_submit" type="submit" variant="contained" size="small"
                                                                disabled={submitionLoading}
                                                                sx={{
                                                                    width: '-webkit-fill-available',
                                                                    ml: { xs: 1 },
                                                                    px: { sm: 6 },
                                                                    // py: { xs: 1 },
                                                                }}
                                                            >
                                                                Update
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        submitionLoading &&
                                                        (
                                                            <Box className="loading_animation" sx={{
                                                                position: 'absolute',
                                                                zIndex: 2,
                                                            }}>
                                                                <CircularProgress size={32} color="success" />
                                                            </Box>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid className='validation_error' item xs={12}
                                                sx={{
                                                    my: { xs: 1, md: 2 },
                                                    // border: 1,
                                                }}
                                            >
                                                {
                                                    !formValid &&
                                                    (
                                                        <Alert severity="warning">
                                                            {profileContent.emptyInterestsError}
                                                        </Alert>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </form>
                                    :
                                    <CypeerLoading />
                                : profile ?
                                    <>
                                        {tab === 0 &&
                                            <Grid className="static_view" container>
                                                <Grid className='main_info' container
                                                    sx={{
                                                        mb: { xs: 2, md: 5 }
                                                    }}
                                                >
                                                    {createStaticDisplayBlock('organization', 'Your Company', profile.organization)}
                                                    {createStaticDisplayBlock('industry', profileContent.industryLabel, profile.industry ? profile.industry.value : '')}
                                                    {createStaticDisplayBlock('role', profileContent.roleLabel, profile.role ? profile.role.value : '')}
                                                    {createStaticDisplayBlock('country', 'Country', profile.country ? profile.country.value : '')}

                                                </Grid>
                                                <Grid className='interests' container>
                                                    <FormLabel>
                                                        <Typography variant="subtitle2">Your interests: </Typography>
                                                    </FormLabel>
                                                    <Grid container className='static_interests_chips'
                                                        direction='row'
                                                        alignItems='flex-start'
                                                    >
                                                        {profile.interests ? createStaticInterestsChips() : <></>}
                                                    </Grid>
                                                </Grid>
                                                <Grid className='weekly_email' container
                                                    sx={{
                                                        my: { xs: 2, md: 5 },
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={<Switch size="small" checked={weeklyEmail} onChange={handleWeeklyEmailChange} />}
                                                        label="Weekly email" />
                                                </Grid>

                                            </Grid>
                                        }
                                        {tab === 1 &&
                                            <Followers type={'followers'} />
                                        }
                                        {tab === 2 &&
                                            <Followers type={'following'} />
                                        }
                                    </>
                                    : <CypeerLoading />
                        }
                    </Grid>
                </Grid>
            </Paper >
        </>
    );
}
