import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { sendEmailVerificationEmail } from '../../services/userService';
import { verifyEmail } from "./content";
import { displayAlertDialog } from '../../components/AlertDialog';

export default function VerifyEmail({ profile }) {

    const navigate = useNavigate();

    const [resendSuccess, setResendSuccess] = useState(false);
    const [resending, setResending] = useState(false);

    async function sendEmail() {
        try {
            setResending(true)
            const res = await sendEmailVerificationEmail();
            window.localStorage.setItem("lastEmaliVerificationSent", moment().toISOString())
            setResending(false)
            setResendSuccess(true);
            return res
        } catch (e) {
            setResending(false);
            console.error(e)
            if (e.httpStatus === 400) {
                displayAlertDialog({
                    title: "Too many requests!",
                    message: "You have sent too many verification requests within a shorter time. Try again after few minutes."
                })
            } else {
                displayAlertDialog({
                    title: "Something went wrong!",
                    message: "Something went wrong while sending verification email. Please try again 'Resend'."
                })
            }
            return 0;
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + '/verifyEmail');

        if (profile === undefined || profile.emailVerified) {
            navigate('/', { replace: true });
        } else {
            const lastSent = window.localStorage.getItem("lastEmaliVerificationSent")
            if (!lastSent || moment().diff(moment(lastSent), 'm') > 15) {
                sendEmail();
            }
        }
    }, []);

    const handleResend = async () => {
        const lastSent = window.localStorage.getItem("lastEmaliVerificationSent")
        if (!lastSent || moment().diff(moment(lastSent), 'm') >= 1) {
            await sendEmail();
        } else {
            displayAlertDialog({
                title: "Too many requests",
                message: "We have already sent a verification mail within last minute. If you still cant see it in your inbox/spam, try again 'Resend' after a minute."
            })
        }
    };

    const handleSnackbarClose = () => {
        setResendSuccess(false);
    };

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            sx={{
                minHeight: { xs: 400, lg: 300, xl: 300 }
            }}
        >
            <Grid item className="tile"
                sx={{
                    // pt: { xs: 1, md: 2, lg: 3 },
                    // border: 1,
                    width: '-webkit-fill-available',
                }}
            >
                <Typography variant="h4" textAlign='center'>
                    {verifyEmail.title}
                </Typography>
                <Typography variant="h5" textAlign='center' paddingTop={2}>
                    {verifyEmail.subtitle}
                </Typography>
                <Typography variant="h5" textAlign='center' fontWeight={500} noWrap >
                    {profile.email}
                </Typography>
            </Grid>

            <Grid item className="info"
                sx={{
                    pt: { xs: 2 },
                    // border: 1,
                }}
            >
                <Typography variant="h5" textAlign='center'>
                    {verifyEmail.info_1}
                    <br />
                    {verifyEmail.info_2}
                </Typography>

            </Grid>

            <Grid item className="resend"
                sx={{
                    pt: { xs: 1 },
                    // border: 1,
                }}
            >
                <Typography variant="h5" paddingTop={1} textAlign='center'>
                    {verifyEmail.resendText}
                    &nbsp;
                    <LoadingButton onClick={handleResend}
                        loading={resending}
                        loadingIndicator="Sending..."
                        variant="text"
                    >
                        {verifyEmail.resendButton}
                    </LoadingButton>
                </Typography>

            </Grid>

            <Snackbar className="success_message"
                open={resendSuccess}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }} variant="filled">
                    {verifyEmail.resendSuccessMessage}
                </Alert>
            </Snackbar>
        </Grid>

    )
}