
// window size Hook
import { useEffect, useState } from "react";

// Ref: https://usehooks.com/useWindowSize/
const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        breakpoint: undefined
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            let w = window.innerWidth
            let bp = w > 1536
                ? 'xl'
                : w > 1200
                    ? 'lg'
                    : w > 900
                        ? 'md'
                        : w > 600
                            ? 'sm'
                            : 'xs'
            setWindowSize({
                width: w,
                height: window.innerHeight,
                breakpoint: bp
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default useWindowSize