import React, { useState } from 'react'
import { Box, Button, IconButton, Input, InputAdornment, Typography, useMediaQuery } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { sp1 } from '../../../configs/spacings';
import { useDispatch, useSelector } from 'react-redux';
import { updateHomeFilter } from '../../../states/roundTableState';
import { useTheme } from '@emotion/react';
import ReactGA from 'react-ga';

var globalSearchTxt;
export const RTHeaderBar = () => {
    const [isSearch, setIsSerach] = useState(false)
    // const [filter, setFilter] = useState(false)
    const filter = useSelector(state => state.roundTableState.homePageState.filter)
    const searchQuery = useSelector(state => state.roundTableState.homePageState.searchQuery)
    const myRoundtablesCount = useSelector(state => state.roundTableState.homePageState.myRoundtablesCount)
    const [searchTxt, setSearchTxt] = useState(searchQuery)
    const [autoComplete, setAutoComplete] = useState("on")
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch()

    const setFilterMode = (mode, search) => {
        const payload = {
            filter: mode,
        }
        if (search) {
            payload.searchQuery = search
        }
        dispatch(updateHomeFilter(payload))
        //setFilter(mode)
    }

    const delayedSearchUpdate = (srcQry) => {
        setTimeout(() => {
            //console.log("TTTTTTTTTx " + srcQry + " " + globalSearchTxt)
            if (srcQry === globalSearchTxt) {
                setAutoComplete("off")
                setFilterMode('search', srcQry)
                ReactGA.event({
                    category: 'General',
                    action: 'Searach_RoundTable',
                    label: "roundtable_list_page",
                    query: srcQry
                });
            }
        }, 1000)
    }

    const onSearchQuery = (e) => {
        let txt = e.target.value
        globalSearchTxt = txt
        setSearchTxt(txt)
        // setAutoComplete("on")
        if (txt.length > 1) {
            // if (filter !== 'search') {
            //     console.log(txt)
            //     setFilterMode('search', txt)
            // } else {
            //     delayedSearchUpdate(txt)
            // }
            delayedSearchUpdate(txt)
        }
    }

    //Text Common attributes
    const tca = { component: 'span', variant: "subtitle2", align: "left", fontWeight: "500" }
    // const bca = { 
    //     variant: filter === "explore" ? "contained" : "outlined", 
    //                 //variant="text"
    //     color: filter === "explore" ? "primary" : "primaryX"
    // }

    const SearchBox = ({ sx }) => {
        return <Input sx={sx}
            id="input-with-icon-adornment"
            variant="outlined"
            placeholder="Search for topic eg: Windows"
            startAdornment={
                <InputAdornment position="start">
                    <SearchRoundedIcon />
                </InputAdornment>
            }
            value={searchTxt}
            onChange={onSearchQuery}
            onFocus={onSearchQuery}
            autoComplete={autoComplete}
        />
    }
    return (
        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', py: sp1 }}>
            {!isSearch && <> <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Button
                    variant={filter === "explore" ? "contained" : "outlined"}
                    color={filter === "explore" ? "primary" : "primaryX"}
                    onClick={() => { 
                        setFilterMode('explore') 
                        ReactGA.event({
                            category: 'Button_Clicks',
                            action: 'Explore_Roundtables',
                            label: "roundtable_list_page"
                        });
                    }}
                >
                    <Typography {...tca} >
                        Explore
                    </Typography>
                </Button>

                {!smallScreen && false && <Button
                    variant={filter === "featured" ? "contained" : "outlined"}
                    color={filter === "featured" ? "primary" : "primaryX"}
                    onClick={() => { 
                        setFilterMode('featured') 
                        ReactGA.event({
                            category: 'Button_Clicks',
                            action: 'Featured_Roundtables',
                            label: "roundtable_list_page"
                        });
                    }}
                >
                    <Typography {...tca} >
                        Featured
                    </Typography>
                </Button>
}

                {/* <Typography {...tca} >
                    |
                </Typography> */}

                <Button
                    //variant="text"
                    variant={filter === "my" ? "contained" : "outlined"}
                    color={filter === "my" ? "primary" : "primaryX"}
                    onClick={() => { 
                        setFilterMode('my') 
                        ReactGA.event({
                            category: 'Button_Clicks',
                            action: 'My_Roundtables',
                            label: "roundtable_list_page"
                        });
                    }}
                >
                    <Typography {...tca}>
                        My {myRoundtablesCount ? `(${myRoundtablesCount})` : ''}
                    </Typography>
                </Button>

                {/* <Typography {...tca} >
                    |
                </Typography> */}

                <Button
                    //variant="text"
                    variant={filter === "new" ? "contained" : "outlined"}
                    color={filter === "new" ? "primary" : "primaryX"}
                    onClick={() => { 
                        ReactGA.event({
                            category: 'List_Item_Clicks',
                            action: 'Start_New_RoundTable',
                            label: "roundtable_list_page"
                        });
                        setFilterMode('new') }}
                >
                    <Typography {...tca}>
                        {smallScreen ? 'New' : 'Start a new Roundtable'}
                    </Typography>
                </Button>
            </Box>
                {SearchBox({ sx: { display: { xs: 'none', sm: 'none', md: 'flex' }, minWidth: '16em' } })}
                <IconButton
                    variant={filter === "search" ? "contained" : "outlined"}
                    color={filter === "search" ? "primary" : "primaryX"}
                    aria-label="Search Roundtable" component="span"
                    onClick={() => { setIsSerach(true) }}
                    sx={{ display: { md: 'none' } }}
                >
                    <SearchRoundedIcon />
                </IconButton>
            </>
            }

            {isSearch && <>
                {SearchBox({ sx: { minWidth: '18em' } })}
                <IconButton variant="contained"
                    color="primaryX"
                    aria-label="Close Search" component="span"
                    onClick={() => { setIsSerach(false) }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </>}

        </Box>
    )
}
