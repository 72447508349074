import { Box, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useWindowSize from '../../../components/hooks/useWindowSize'
import { sp3 } from '../../../configs/spacings'
import RoundTableListItem from './RoundTableListItem'

const SecondaryList = ({ title, listRoundTables, maxAvatarCount, loadingRTs }) => {
    const filter = useSelector(state => state.roundTableState.homePageState.filter)
    const filterRefresh = useSelector(state => state.roundTableState.homePageState.filterRefresh)
    const homePageState = useSelector(state => state.roundTableState.homePageState)
    const windowSize = useWindowSize()

    const [pastRtsState, setPastRtsState] = useState(undefined)

    useEffect(() => {
        const state = homePageState[filter].secondaryFilters?.pastFeatured
        console.log("secFstate", state)
        if (state) {
            setPastRtsState(state)
            //setAllLoaded(state.isAllLoaded)
        } else if (homePageState[filter].isAllLoaded && !loadingRTs) {
            console.log("All loaded")
            listRoundTables()
        }
    }, [filterRefresh])

    if (pastRtsState && pastRtsState.roundTables.length) {
        return <>
            <Box pb={sp3}>
                <Divider textAlign='left'>
                    <Typography variant="subtitle1" >{title}</Typography>
                </Divider>
            </Box>
            {pastRtsState.roundTables.map((table) => (
                <RoundTableListItem roundTableProp={table} key={table.id} windowSize={windowSize} maxAvatarCount={maxAvatarCount} />
            ))
            }
        </>
    } else {
        return <></>
    }

}

export default SecondaryList
