import configs from "../configs/configs";
import httpSupportService from "./httpSupprotService";
import { updateProfile } from "../states/appState";
import reduxStore from "../states/reduxStore";

export let LCL_STR_PROFILE = 'LOGINPROFILE'

export class ProfileService {

    cachedProfiles = {}

    async getProfile(userId, isForced) {
        if (!isForced) {
            let cf = this.getCachedProfile(userId)
            if (cf) return cf;
        }
        let getCfg = await httpSupportService.getDefaultGetConfigs()
        let getUrl = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profile/${userId}`
        let response = await httpSupportService.doRequest(getUrl, getCfg)

        let profile = (await response.json()).result
        this.putProfileInCache(profile);
        return profile
    }

    // Create a new user in the DB if the user does not exist
    async getUserProfile() {

        const options = await httpSupportService.getDefaultPostConfigs();

        return fetch(configs.APIM.BASE_URL + configs.APIM.USER_BASE_PATH + "/profile", options)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return 'No data';
                }
            })
            .then(data => {
                return data.result;
            })
            .catch(console.log);
    }

    //TODO - reove the create profile part from the error handling section here
    async getMyProfile() {
        let getCfg = await httpSupportService.getDefaultGetConfigs()
        let getUrl = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profile/me`
        let response
        try {
            response = await httpSupportService.doRequest(getUrl, getCfg)
        } catch (err) {
            if (err.name === "httpError" && err.httpStatus === 400) {
                try {
                    let postCfg = await httpSupportService.getDefaultPostConfigs({})
                    let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profile`
                    response = await httpSupportService.doRequest(url, postCfg)
                    if (response.status === 200) {
                        response = await httpSupportService.doRequest(getUrl, getCfg)
                    }
                } catch (error) {
                    response = err.response
                }
            } else {
                response = err.response
            }
        }

        if (response.status === 200) {
            let newProfile = (await response.json()).result;
            this.putProfileInCache(newProfile);
            reduxStore.dispatch(updateProfile(newProfile));
            window.localStorage.setItem(this.LCL_STR_PROFILE, JSON.stringify(newProfile));
            return newProfile;
        } else {
            //TODO: handle this well
            reduxStore.dispatch(updateProfile(null));
            throw response
        }
    }

    putProfileInCache(profile) {
        if (profile.id) {
            this.cachedProfiles[profile.id] = profile;
        } else {
            throw new Error("Invalid profile - profile.id not found")
        }
    }

    getCachedProfile(userId) {
        return this.cachedProfiles[userId];
    }

    clearCachedProfile(userId) {
        delete this.cachedProfiles[userId];
    }

    async getProfilesByArray(ids) {
        if (ids.length > 19) {
            throw new Error("Max support ids count at a time is 19 but found :" + ids.length)
        }
        let postCfg = await httpSupportService.getDefaultPostConfigs({ ids })
        let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profiles`
        let profiles = []
        try {
            let response = await httpSupportService.doRequest(url, postCfg)
            profiles = (await response.json()).results;
            profiles.forEach(profile => {
                this.putProfileInCache(profile);
            });
        } catch (error) {
            if (error.name === "httpError" && error.httpStatus === 400 && ids.length > 1) {
                for (let idx = 0; idx < ids.length; idx++) {
                    const id = ids[idx];
                    try {
                        let p = await this.getProfile(id, true)
                        profiles.push(p)
                    } catch (error) {
                        console.error("Get profile failed for id -" + id)
                    }

                }
            } else {
                throw error
            }
        }
        return profiles
    }

    async updateUserProfile(userProile) {

        var playload = {
            "organization": userProile.organization,
            "roleId": userProile.role,
            "industryId": userProile.industry,
            "interestIds": userProile.interests,
            "customInterests": userProile.customInterests,
            "counrtyId": userProile.country,
        }

        let postCfg = await httpSupportService.getDefaultPostConfigs(playload);
        let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profile`

        let response = await httpSupportService.doRequest(url, postCfg);
        return response.status;
    }

    async getFollowersProfiles(profileId) {
        let getConf = await httpSupportService.getDefaultGetConfigs();
        let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profile/${profileId}/followers`

        var response = await httpSupportService.doRequest(url, getConf);
        return (await response.json()).results;
    }

    async getFollowingProfiles(profileId) {
        let getConf = await httpSupportService.getDefaultGetConfigs();
        let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profile/${profileId}/following`

        var response = await httpSupportService.doRequest(url, getConf);
        return (await response.json()).results;
    }

    async followProfile(profileId, isFollowed) {
        let putCfg = await httpSupportService.getDefaultPutConfigs({ active: isFollowed });
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/profile/${profileId}/follow`, putCfg);
        let updatedProfile = (await response.json()).result;
        this.putProfileInCache(updatedProfile);
        return updatedProfile
    }
}

export default new ProfileService()