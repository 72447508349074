import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeProvider, responsiveFontSizes } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CypeerRoundTableTheme } from "../../Themes";
import RTBackdropPage from "../../components/RTBackdropPage";
import RTPaper from "../../components/RTPaper";
import LogoFull from "../../components/CypeerLogo";
import publicService from "../../services/publicService";
import { verifiedEmail } from "./content";

export default function VerifiedEmail() {

    const mainTheme = responsiveFontSizes(CypeerRoundTableTheme);
    const { ref, id } = useParams();
    const navigate = useNavigate();

    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [inProgress, setInProgress] = useState(true);

    useEffect(() => {
        publicService.verifyEmail(ref, id)
            .then((response) => {
                setVerificationSuccess(true);
                setInProgress(false);
            }).catch((err) => {
                alert("Something went wrong!");
                console.error(err);
                setVerificationSuccess(false);
                setInProgress(false);
            });
    }, []);

    const handleButtonClick = () => {
        navigate('/', { replace: true });
    }

    return (
        <ThemeProvider theme={mainTheme}>
            <Grid className="verified_email"
                sx={{
                    height: 'auto',
                    minHeight: '100%',
                    // border: 1,
                }}
            >
                <div style={{ minHeight: "100vh" }}>
                    <RTBackdropPage styles={{ alignItems: "center" }}>
                        <RTPaper styles={{ minHeight: '85vh' }}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifycontent="center"
                                minHeight='inherit'
                            // border={1}
                            >
                                <Grid item
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justifycontent="center"
                                // border={1}
                                >
                                    <Grid item className="Logo"
                                        sx={{
                                            height: { xs: 40, sm: 45, md: 50 },
                                            // border: 1,
                                        }}
                                    >
                                        <LogoFull />
                                    </Grid>

                                    {inProgress ?
                                        <>
                                            <Grid item className='Verifying'
                                                sx={{
                                                    mt: 5,
                                                    // border: 1
                                                }}
                                            >
                                                <Typography variant="h" textAlign='center'>
                                                    Verifying...
                                                </Typography>
                                            </Grid>
                                            <Grid item className='Verifying'
                                                sx={{
                                                    mt: 1,
                                                    // border: 1
                                                }}
                                            >
                                                <CircularProgress size={30} />
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item className="title"
                                                sx={{
                                                    mt: 5,
                                                    // border: 1,
                                                }}
                                            >
                                                <Typography variant="h4" textAlign='center'>
                                                    {verificationSuccess ? verifiedEmail.successTitle : verifiedEmail.failTitle}
                                                </Typography>
                                            </Grid>
                                            <Grid item className="subtitle"
                                                sx={{
                                                    mt: 1,
                                                    // border: 1,
                                                }}
                                            >
                                                <Typography variant="h5" textAlign='center'>
                                                    {verificationSuccess ? verifiedEmail.successSubtitle : verifiedEmail.failSubtitle}
                                                </Typography>
                                            </Grid>
                                            <Grid item className="button"
                                                sx={{
                                                    mt: 3,
                                                    // border: 1,
                                                }}
                                            >
                                                <Button variant="contained"
                                                    onClick={() => handleButtonClick()}
                                                >
                                                    {verificationSuccess ? verifiedEmail.loggedInButton : verifiedEmail.loggedOutButton}
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </RTPaper>
                    </RTBackdropPage>
                </div>
            </Grid>
        </ThemeProvider>
    );

}