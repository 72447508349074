let configs = {
    env: "dev",
    APIM: {
        BASE_URL: window.APIM_BASE_URL,
        DATA_BASE_PATH: '/data/v1',
        PUBLIC_BASE_PATH: '/public/v1',
        ROUND_TABLE_BASE_PATH: '/roundtable/v1',
        FILE_BASE_PATH: '/file/v1',
        USER_BASE_PATH: '/user/v1',
        SUBSCRIPTION_KEY: window.APIM_SUBSCRIPTION_KEY
    },
    MSAL: {
        CONFIG: {
            auth: {
                clientId: window.AUTH_CLIENT_ID,
                authority: "https://" + window.AUTH_B2C_INSTANCE + "/" + window.AUTH_AZURE_INSTANCE + "/B2C_1_LinkedIn",
                knownAuthorities: [window.AUTH_B2C_INSTANCE],
                redirectUri: window.redirectUri,
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
            }
        },
        LOGIN_REQUEST: {
            scopes: ["openid", "offline_access", "profile", window.AUTH_CLIENT_ID,]
        }
    },
    ROUND_TABLE: {
        MAX_DURATION: 40,
        //MAX_PARTICIPANTS: 6,
        ALERT_TIMING: {
            HALF_WAY: false,
            FIRST_WARNING: 10,
            SECOND_WARNING: 5,
            END_ALERT: -90,
            ALERT_AUTO_HIDE_DURATION: 20000,
            CHECK_TIME_INTERVAL: 20
        },
        START_DATE_INTERVAL_DAYS: 0,
        START_DATE_WARNING_DAYS: 2
    },
    DATE_TIME:{
        CALENDAR_HUMANIZATION: {
            lastDay: '[Yesterday at] LT',
            sameDay: '[Today at] LT',
            nextDay: '[Tomorrow at] LT',
            lastWeek: '[last] dddd [at] LT',
            nextWeek: '[next] dddd [at] LT',
            sameElse: 'LL [at] LT'
        },
    },
}

export const APIM = configs.APIM
export const MSAL = configs.MSAL
export default configs