import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { Alert, useMediaQuery } from '@mui/material';
import ReactGA from 'react-ga';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import GroupsIcon from '@mui/icons-material/Groups';
import getMatchingAvatarForProfile from '../services/avatarService';
import LogoFull, { LogoIcon } from './CypeerLogo';
import { spx9 } from '../configs/spacings';
import MenuIcon from './MenuIcon';
import { LogOutDialog } from './LogOutDialog';

const menuItems = ['My Profile', 'Sign Out'];

function CypeerAppBar() {
  const myProfile = useSelector(state => state.appState.myProfile)
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [logoutDialogState, setAddCallDialogState] = useState({ open: false });
  const navigate = useNavigate();
  const { inProgress } = useMsal();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const gaLabel = "App_bar"

  const handleOpenUserMenu = (event) => {
    ReactGA.event({
      category: 'Menu_Clicks',
      action: 'Profile_Menu',
      label: gaLabel
    });
    setAnchorElUser(event.currentTarget);
  };

  function handleLogin() {
    ReactGA.event({
      category: 'Button_Clicks',
      action: 'Login',
      label: gaLabel
    });
    navigate('/login', { replace: true, state: { directLogin: true } });
  }

  function handleInviteClick() {
    if (window.location.pathname !== "/invite") {
      ReactGA.event({
        category: 'Tab_Clicks',
        action: 'Invite',
        label: gaLabel
      });
      navigate('/invite');
    }
  }

  function handleLogoClick() {
    if (window.location.pathname !== "/home") {
      ReactGA.event({
        category: 'General',
        action: 'Logo_click',
        label: gaLabel
      });
      navigate('/home');
    }
  }

  function handleFeedbackClick() {
    ReactGA.event({
      category: 'Tab_Clicks',
      action: 'Feedback',
      label: gaLabel
    });
    window.open('https://feedback.cypeer.com/', '_blank');
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  function handleGoToProfile() {
    ReactGA.event({
      category: 'Banner_clicks',
      action: 'Commplete_Profile',
      label: gaLabel
    });
    navigate('/profile/me#edit');
  }

  function handleValidateEmail() {
    ReactGA.event({
      category: 'Banner_clicks',
      action: 'Validate_Email',
      label: gaLabel
    });
    navigate('/profile/me/verifyemail');
  }

  const handleChooseUserMenu = (action) => {
    setAnchorElUser(null);
    switch (action) {
      case "Sign Out":
        setAddCallDialogState(
          {
            open: true,
            onClose: () => {
              setAddCallDialogState({ open: false })
            }
          }

        )
        break;
      case "My Profile":
        navigate('/profile/me');
        break;
      default:
        console.warn(`Unhandled options menu action ${action}`);
    }
    ReactGA.event({
      category: 'Menu_Item_Clicks',
      action: action,
      label: gaLabel
    });
  };

  const Banner = () => {
    const BannerAlert = ({ heading, clickHereToConent, onClick }) => {
      return <Alert severity="error" onClick={onClick} sx={{ cursor: 'pointer', mt: 1 }} >
        <Typography variant="h4" sx={{ lineHeight: '0.8em' }}>
          {heading}
        </Typography>
        <Typography variant="subtitle1" sx={{ pt: 1 }}>
          Please <span style={{ textDecoration: 'underline' }}>click here</span> to {clickHereToConent}
        </Typography>
      </Alert>
    }

    if (myProfile) {
      if (!myProfile.emailVerified) {
        return <BannerAlert
          heading={"Your email address is not verified!"}
          clickHereToConent="validate your email address."
          onClick={handleValidateEmail}
        />
      } else if (myProfile.isNewUser) {
        return <BannerAlert
          heading={"Your profile isn't complete!"}
          clickHereToConent="complete your profile and gain full access."
          onClick={handleGoToProfile}
        />
      }
    }
    return <></>

  }

  return (
    <>
      <AppBar className='app_bar'
        id='cyp_header'
        color='backgroundLight'
        position="sticky"
        elevation={0}
        sx={{
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          backdropFilter: 'blur(20px)',
        }}
      >
        <Toolbar style={{ paddingLeft: spx9, paddingRight: spx9 }}
          sx={{
            minHeight: { xs: 50, },
            px: spx9,
            // border: 1,
          }}
        >
          <Grid item className='logo'
            sx={{ cursor: 'pointer' }}
            onClick={handleLogoClick}
          >
            {!smallScreen &&
              <Grid item className='logo_full' sx={{ height: '4rem' }}>
                <LogoFull />
              </Grid>
            }
            {smallScreen &&
              <Grid item className='logo_icon' sx={{ height: '4rem', width: '4rem' }}>
                <LogoIcon />
              </Grid>
            }
          </Grid>
          {myProfile ?
            <Grid container className="menu_icons"
              direction='row'
              justifyContent="flex-end"
              alignItems="stretch"
            // sx={{ border: 1 }}
            >
              <Grid item className='menu_roundtable' sx={{ px: 1 }} onClick={() => {
                ReactGA.event({
                  category: 'Tab_Clicks',
                  action: 'Roundtables',
                  label: gaLabel
                });
                navigate('/roundtables')
              }

              }>
                <MenuIcon text="Roundtable" tooltip="Join a meeting">
                  <GroupsIcon fontSize='large' />
                </MenuIcon>
              </Grid>
              <Grid item className='menu_invite' sx={{ px: 1 }} onClick={handleInviteClick}>
                <MenuIcon text="Invite" tooltip="Spread the word">
                  <PersonAddAlt1Icon fontSize='large' />
                </MenuIcon>
              </Grid>
              <Grid item className='menu_feedback' sx={{ px: 1 }} onClick={handleFeedbackClick}>
                <MenuIcon text="Feedback" tooltip="Help us improve">
                  <TipsAndUpdatesIcon fontSize='large' />
                </MenuIcon>
              </Grid>
              <Grid item className='avatar_icon' sx={{ pl: 1 }}>
                <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  height="100%">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, border: 1 }}>
                    {getMatchingAvatarForProfile(myProfile, 'smaller')}
                  </IconButton>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {menuItems.map((item) => (
                      <MenuItem key={item} onClick={() => handleChooseUserMenu(item)} sx={{ minHeight: 'auto' }}>
                        <Typography variant="subtitle1" textAlign="center">{item}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            :
            inProgress === InteractionStatus.None ?
              <Grid className="sign_in"
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  // border: 1,
                }}
              >
                {
                  !smallScreen &&
                  <Grid item>
                    <Typography variant="h5" color="primary" textAlign='right' fontWeight={400}>
                      Already signed up?
                    </Typography>
                  </Grid>
                }
                <Grid item sx={{ pl: 1 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<LinkedInIcon />}
                    onClick={handleLogin}
                    sx={{ borderRadius: 5 }}
                  >
                    <Typography variant="h5" fontWeight={500}>
                      Sign in with Linkedin
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              :
              <></>
          }
        </Toolbar>

        {logoutDialogState.open && <LogOutDialog open={logoutDialogState.open}
          onClose={logoutDialogState.onClose}
          myProfile={myProfile}
        />}

        <Banner />
      </AppBar >
    </>
  );
};

export default CypeerAppBar;