import configs from "../configs/configs";
import httpSupportService from "./httpSupprotService";

export async function getMasterData() {

    const options = await httpSupportService.getDefaultGetConfigs();

    return fetch(configs.APIM.BASE_URL + configs.APIM.DATA_BASE_PATH + "/master", options)
        .then(res => res.json())
        .then((res) => {
            var interests = res.results.find(r => r.type === 'Interest')
                .data.map(i => {
                    return {
                        id: i.id,
                        value: i.value,
                        isSelected: false,
                        verified: true,
                    };
                });

            var roles = res.results.find(r => r.type === 'Role')
                .data.map(i => {
                    return {
                        id: i.id,
                        value: i.value
                    };
                });

            var industries = res.results.find(r => r.type === 'Industry')
                .data.map(i => {
                    return {
                        id: i.id,
                        value: i.value
                    };
                });

            var countries = res.results.find(r => r.type === 'Country')
                .data.map(i => {
                    return {
                        id: i.id,
                        value: i.value
                    };
                });

            return {
                roles,
                industries,
                countries,
                interests
            }
        })
        .catch(console.log);
}
