import { configureStore } from '@reduxjs/toolkit'
import appStateSlice from './appState';
import roundTableState from './roundTableState';


export default configureStore({
    reducer:{
        roundTableState: roundTableState,
        appState: appStateSlice
    },
});