import { Box, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick/lib/slider'
// slick css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Partners = () => {

    const theme = useTheme();
    
    // Breakpoints for reference from Themes.js
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const slidesCountToShow = useMediaQuery(theme.breakpoints.up('lg')) ? 7
        : isMd ? 6 : isSm ? 4 : 3

    const items = () => {
        const itms = []
        const logos = ['AffinBank.png', 'Allianz.png', 'BDO.png', 'Microsoft.png', 
            'breachlock.png', 'Caresuper.png', 'Deloitte.png', 'Deutschebank.png', 
            'dialog.png','DXC.png', 'firstcapital.png', 'EY.png',  
            'HBL.png', 'JPMorgan.png', 'Nissan.png', 'Scybers.png', 
            'StandardChartered.png', 'LSEG.png',  'UIpath.png', 
            'Warwick.png', 'Virtusa.png', 'Wipro.png', 'zoom.png'
            // '.png', '.png', '.png', '.png',
           // primary  Microsoft,Deloitte, JP Morgan & Chase, EY, London Stock Exchange, Virtusa
        ]
        for (let index = 0; index < logos.length; index++) {
            const logoId = logos[index]
            const item = <Box key={logoId}>
                <Box display='flex' justifyContent='center' alignItems='center' my={1} mx={0.5} minHeight={60} >
                    <img src={`/images/home/partners/logos/${logoId}`} alt='Our Partners' style={{ maxWidth: '100%' }} />
                </Box>
            </Box>
            itms.push(item)
        }
        return itms
    }

    return <Slider {...{
        dots: false,
        infinite: true,
        slidesToShow: slidesCountToShow,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2504,
        autoplaySpeed: 3,
        cssEase: "linear"
    }}>
        {items()}
    </Slider>
}
