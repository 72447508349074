import { ErrorOutline } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProfileBanner({ profile, noHrLine }) {
    
    const navigate = useNavigate();

    function handleVerifyEmail(){
        navigate('verifyemail');
    }

    return (
        <Grid container columns={12}
        // border={1}
        >
            <Grid className='image_column' item xs={3} sm={1} xl={1}
                visibility={profile.profilePicture ? 'true' : 'hidden'}
                sx={{
                    // border: 1,
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                }}
            >
                <Grid className='combined_image' container columns={12} direction='row'
                    sx={{
                        // border: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <Grid className='profile_image' item
                        sx={{
                            // border: 1,
                            width: 1,
                        }}
                    >
                        <img src={profile.profilePicture} alt=''
                            style={{
                                maxWidth: '-webkit-fill-available',
                                borderRadius: '50%',
                                // border: 'solid',
                            }}
                        />
                    </Grid>
                    <Grid className='linkedin_icon' item xs={4}
                        sx={{
                            width: 1,
                            position: 'relative',
                            top: { xs: -25, sm: -20, md: -20, lg: -30, xl: -30 },
                            // border: 1,
                        }}
                    >
                        <img src='/icons/linkedin_icon_round.png' alt=''
                            style={{
                                maxWidth: '-webkit-fill-available',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className='details_column' container direction='column' item xs={9} sm={10} xl={11}
                sx={{
                    // border: 1,
                    justifyContent: 'center',
                    pl: { xs: 2, md: 4 }
                }}
            >
                <Box className='profile_name'
                    sx={{
                        // border: 1,
                        maxWidth: '-webkit-fill-available',
                    }}
                >
                    <Typography variant='h4' color='primary'>
                        {profile.firstName} {profile.lastName}
                    </Typography>
                </Box>
                <Box className='profile_email'
                    sx={{
                        overflow: 'hidden',
                        // border: 1,
                        maxWidth: '-webkit-fill-available',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Typography variant='subtitle2' lineHeight='initial'>
                        {profile.email}
                    </Typography>
                </Box>
                {profile.emailVerified &&
                    <Box className='email_verified'>
                        <Chip color="success"
                            label={<Typography variant="subtitle2">Verified</Typography>}
                            sx={{ height: 1, }}
                        />
                    </Box>
                }
                {!profile.emailVerified &&
                    <Box className='email_unverified'>
                        <Chip color="error"
                            icon={<ErrorOutline></ErrorOutline>}
                            label={<Typography variant="subtitle2">Verify Email</Typography>}
                            sx={{ height: 1, }}
                            onClick={handleVerifyEmail}
                        />
                    </Box>
                }
                <Box className='padding'
                    sx={{
                        height: { xs: 25, md: 26, lg: 30, xl: 40 },
                        maxWidth: '-webkit-fill-available',
                        // border: 1,
                    }}
                ></Box>
            </Grid>
            {
                !noHrLine &&
                <Grid className='hr_line' item xs={12}
                    sx={{
                        borderTop: 2,
                        borderColor: 'primary.main',
                        mb: { xs: 1 },
                    }}
                >
                </Grid>
            }
        </Grid>
    );
};