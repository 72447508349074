import { createTheme } from "@mui/material";

let defaultFontFamily = "-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Lucida Grande,Helvetica,Arial,sans-serif;"

// Breakpoints for reference
// xs: 0px — 599px: Mobile devices
// sm: 600px — 899px: iPads, Tablets
// md: 900px — 1199px: Small screens, laptops
// lg: 1200px — 1534px: Desktops, large screens
// xl: 1535px and more —  Extra large screens, TV

let defaultTheme = createTheme({});
export const CypeerRoundTableTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: defaultFontFamily,
                    fontSize: '12px',
                    borderRadius: 50,
                },
                iconSizeLarge: {
                    "& > *:first-of-type": {
                        fontSize: 30
                    }
                },
                iconSizeSmall: {
                    "& > *:first-of-type": {
                        fontSize: 16
                    }
                },
                startIcon: {
                    marginRight: 0.5,
                }
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "& > *": {
                        fontSize: 'inherit'
                    }
                },
                sizeSmall: {
                    fontSize: '2rem'
                },
                sizeMedium: {
                    fontSize: '2.5rem'
                },
                sizeLarge: {
                    fontSize: '3rem'
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontSize: '12px'
                }
            }
        },
        MuiTypography: {
            // Default font size is 16px for screen size 'lg'
            fontFamily: defaultFontFamily,
            fontSize: 16,
            styleOverrides: {
                root: {
                    fontFamily: defaultFontFamily,
                },
                h2: {
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: '4rem',
                    },
                    [defaultTheme.breakpoints.down('sm')]: {
                        fontSize: '3.5rem',
                    },
                    fontStyle: 'normal',
                    fontWeight: 300,
                    textAlign: 'left',
                    lineHeight: '1.2em',
                },
                h3: {
                    [defaultTheme.breakpoints.up('lg')]: {
                        fontSize: '3.438rem',
                    },
                    [defaultTheme.breakpoints.down('xs')]: {
                        fontSize: '1.875rem',
                    },
                    fontStyle: 'normal',
                    fontWeight: 300,
                    textAlign: 'left',
                    lineHeight: '1.3em',
                },
                h4: {
                    fontStyle: 'normal',
                    fontSize: '20px',
                    fontWeight: 600,
                    textAlign: 'left',
                    lineHeight: '1.3em'
                },
                h5: {
                    fontFamily: defaultFontFamily,
                    fontWeight: 300,
                    fontStyle: 'normal',
                    fontSize: '18px',
                    textAlign: 'left',
                },
                h6: {
                    fontWeight: 300,
                    fontStyle: 'normal',
                    textAlign: 'left',
                    fontSize: '16px',
                },
                subtitle1: {
                    fontSize: '14px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '1.188em',
                },
                subtitle2: {
                    fontSize: '12px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '1.188em',
                },
                body1: {
                    fontSize: '12px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '1.188em',
                },
                body2: {
                    fontSize: '12px',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    lineHeight: '1.188em',
                },
                bodyNormal: {
                    fontSize: '1.143rem',
                    [defaultTheme.breakpoints.down('sm')]: {
                        fontSize: '0.857rem',
                    },
                    [defaultTheme.breakpoints.down('md')]: {
                        fontSize: '1rem',
                    },
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 1.3,
                },
                subText1: {
                    fontSize: '1rem',
                },
                subTextResponsive: {
                    fontSize: '1rem',
                    [defaultTheme.breakpoints.down('sm')]: {
                        fontSize: '0.9rem',
                    },
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFontFamily,
                    fontSize: 14,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFontFamily,
                    '& label': {
                        fontSize: 14,
                    },
                },
                input: {
                    fontFamily: defaultFontFamily,
                    fontSize: 14,
                },
                listbox: {
                    fontFamily: defaultFontFamily,
                    fontSize: 14,
                },
                noOptions: {
                    fontFamily: defaultFontFamily,
                    fontSize: 14,
                },
                tag: {
                    fontFamily: defaultFontFamily,
                    fontSize: 14,
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 500,
                    fontFamily: defaultFontFamily,
                },
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontFamily: defaultFontFamily,
                    fontSize: 14,
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontFamily: defaultFontFamily,
                    fontSize: '1.2rem',
                    fontWeight: 400,
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#0094FF',
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: defaultFontFamily,
                    fontSize: '1.2rem'
                },
            }
        }
    },
    palette: {
        primary: {
            main: '#0094FF',
            contrastText: '#FFFFFF',
        },
        primaryX: {
            main: '#141D3A',
            dark: '#333333',
        },
        neutral: {
            main: '#C1C7D0',
            contrastText: '#333333',
        },
        success: {
            main: '#148752',
            contrastText: '#FFFFFF',
        },
        info: {
            main: '#EBBC72',
            contrastText: '#FFFFFF',
        },
        background:
        {
            main: '#F8FCFF',
        },
        backgroundSecondary:
        {
            main: '#f3f2ef',
        },
        backgroundLight:
        {
            main: '#FFFFFF',
        },
        highlight:
        {
            main: '#feffa6',
        },
        inputText: {
            main: '#464646',
        },
        textLowEmphasis:
        {
            main: '#00000099',
        },
        textHighEmphasis:
        {
            main: '#000000E6',
        }
    }
});