import { lazy, Suspense, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import roundTableService from '../../services/roundTablesService';
// import VideoPlayer from './VideoPlayer';
// import LiveStream from './LiveStream';
import reduxStore from '../../states/reduxStore';
import { updateSelectedRoundTable } from '../../states/roundTableState';
import { RoundTableChildDefault } from './roundtable/RoundTableChildDefault';
// import RoundTableChildJoin from './roundtable/RoundTableChildJoin';
import { CypeerLoading } from '../../components/CypeerLoading';
import { sp3, spx9 } from '../../configs/spacings';
import { reloadIfZoomCallElementsExists } from './commonComponents';

const RoundTableChildJoin = lazy(() => import('./roundtable/RoundTableChildJoin'))

const RoundTablePage = () => {
  //const [roundTable, setRoundTable] = useState({});
  //const [participants, setParticipants] = useState({});
  const [loadingRt, setLoadingRT] = useState(true);
  const [isRTLoadFailed, setRTLoadFailed] = useState(false);
  //const [meetingProgress, setMeetingProgress] = useState({});
  const { meetingId } = useParams();
  const { action } = useParams();
  const roundTable = useSelector(state => state.roundTableState.selectedRoundTable)
  const myProfile = useSelector(state => state.appState.myProfile)

  const loadRoundTable = () => {
    setRTLoadFailed(false)
    setLoadingRT(true)
    roundTableService.getRoundTable(meetingId, myProfile.id).then(fetchRTProgress).catch(e => {
      setLoadingRT(false)
      setRTLoadFailed(true)
      console.error(e)
    })
  }

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (myProfile && !myProfile.isTempProfile) {
      //full profile needed for fetch RT progress.
      let selTbl = roundTable;
      
      if (!selTbl || selTbl.id != meetingId) { // this != is important donot change it to !==
        console.log("Already selected table missmatched with passed meeting id in url..")
        loadRoundTable()
      } else {
        fetchRTProgress(selTbl);
      }
    }
  }, [myProfile]);

  const fetchRTProgress = (newRoundTable) => {
    // This allows rountable state of newly passed roundtable
    let rt = newRoundTable ? newRoundTable : roundTable;
    let newCS = roundTableService.getCurrentState(rt, myProfile);
    let cs = newCS ? newCS : rt.currentState;

    //append old attachements if newRT does not have it
    let attachments = newRoundTable?.attachments ?? roundTable?.attachments

    // const shouldLoadPrgress = cs.isReadyToStart || cs.isLive ||
    //   (cs.isEnded && !cs.isRecodingsReady) || (cs.isClosed && !rt.progress)

    const shouldLoadPrgress = !newRoundTable && cs.isReadyToStart || cs.isLive
      || (cs.isClosed && !cs.isEnded && !cs.isNotHappened)
    // commented this case as recortidng is not in this relese || (cs.isEnded && !cs.isRecodingsReady) 
    if (shouldLoadPrgress) {
      roundTableService.getRoundTable(rt.id).then(
        newRt => {
          if (newRoundTable || newRt.meetingProgress.version !== rt.meetingProgress.version || true) { //TODO: remove this true 
            console.log("Progress updated for new roundtable");
            newCS = roundTableService.getCurrentState(newRt, myProfile);
            reduxStore.dispatch(updateSelectedRoundTable({ ...newRt, currentState: newCS, attachments }));
          }

          if (loadingRt) setLoadingRT(false);
        }
      );
    } else {
      // console.log("No progress udpate");
      if (newRoundTable || newCS) {
        reduxStore.dispatch(updateSelectedRoundTable({ ...rt, currentState: cs, attachments }));
      }
      if (loadingRt) setLoadingRT(false);
    }
  }
  
  // verify RoutAction 
  if (!action) {
    // reload viewpage if zoom call elements exists
    reloadIfZoomCallElementsExists()
  } else if (action === "join") {
    return <Suspense fallback={<CypeerLoading label="Joining meeting. This may take little time in slower networks..." />} >
      <RoundTableChildJoin onFetchRTProgress={fetchRTProgress} />
    </Suspense>
  }

  // Before calling this function, a roundTable is initialized with latest
  const getChildView = () => {

    if (action) {
      if (action === "recording") {
        return <Box
          display={"flex"}
          alignItems={'center'}
          justifyContent={"center"}

        >
          hey randy uncomment VideoPlayer tag
          {/* {roundTable.progress ? <VideoPlayer selectedTable={roundTable} /> : <div>Loading..</div>} */}
        </Box>
      } else if (action === "live") {
        return <Box
          display={"flex"}
          alignItems={'center'}
          justifyContent={"center"}

        >

          hey randy uncomment LiveStream tag
          {/* {roundTable.progress ? <LiveStream stateUtils={stateUtils} /> : <div>Loading..</div>} */}
        </Box>
      }
    }


    //default child view
    if (roundTable.currentState) {
      return <RoundTableChildDefault onFetchRTProgress={fetchRTProgress} />
    }

  }

  // default view background.main
  return <div>
    <Paper elevation={0} sx={{
      pb: sp3,
      px: spx9,
      backgroundColor: "inherit"
    }
    }>
      {loadingRt && <CypeerLoading label="Loading..." />}
      {roundTable && getChildView()}
      {isRTLoadFailed  && !loadingRt && <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={loadRoundTable}>
            Retry
          </Button>
        }>Something went wrong while requesting roundtable from the server.</Alert>
      }
    </Paper>
  </div>;
};

RoundTablePage.propTypes = {};

export default RoundTablePage;
