import { List, ListItem, ListItemIcon, Typography, Grid, DialogContent } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { infoDialog } from './content'

export default function ConnectInfoPopup() {
    return (
        <>
            <Grid className='info-dialog-title' sx={{ px: 3, py: 2 }}>
                <Typography variant='h4'>
                    {infoDialog.title}
                </Typography>
            </Grid>
            <DialogContent className='info-dialog-content' dividers={true}>
                <List>
                    {
                        infoDialog.tips.map((tip) => {
                            return (
                                <ListItem key={tip.sortOrder}>
                                    <ListItemIcon sx={{ minWidth: '30px' }}>
                                        <CheckCircleIcon color='primary' />
                                    </ListItemIcon>
                                    <Typography variant='h5'>{tip.text}</Typography>
                                </ListItem>
                            );
                        })
                    }
                </List>
                <Typography variant='h5'>{infoDialog.endNote}</Typography>
            </DialogContent>
        </>
    );
}