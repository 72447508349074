import configs from "../configs/configs"
import httpSupportService from "./httpSupprotService";

class FileService {
    downloadFileFromUploadsContainer(path, fileName, progress) {

        let container = "uploads"
        const abortController = new AbortController();
        const cancel = () => abortController.abort();
        const promise = this.downloadFileWithProgress(`${configs.APIM.BASE_URL}${configs.APIM.FILE_BASE_PATH}/file/${container}/${path}/${fileName}`, fileName, abortController, progress)
        
        return { cancel, promise }

    }

    async downloadFile(fullpath, Outputfilename, abortController) {
        try {
            let getCfg = await httpSupportService.getDefaultGetConfigs()
            getCfg.signal = abortController.signal
            let response = await httpSupportService.doRequest(fullpath, getCfg)
            let file = await response.blob()
            // alert("your file has downloaded!");
            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = Outputfilename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.log("error", e)
            // alert(e.message)
            throw e
        }
    }

    async downloadFileWithProgress(fullpath, Outputfilename, abortController, progress) {
        try {
            let getCfg = await httpSupportService.getDefaultGetConfigs()
            getCfg.signal = abortController.signal
            let response = await httpSupportService.doRequest(fullpath, getCfg)
            const reader = response.body.getReader();
            // Step 2: get total length
            const contentLength = +response.headers.get('Content-Length');
            progress(contentLength, 0)

            // Step 3: read the data
            let receivedLength = 0; // received that many bytes at the moment
            let chunks = []; // array of received binary chunks (comprises the body)
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                chunks.push(value);
                receivedLength += value.length;

                //console.log(`Received ${receivedLength} of ${contentLength}`)
                progress(contentLength, receivedLength)
            }

            // Step 4: concatenate chunks into blob
            let file = new Blob(chunks)
            // // alert("your file has downloaded!");
            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = Outputfilename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.log("error", e)
            throw e
        }
    }
}
export default new FileService()