import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

export default function MenuIcon(props) {
    return (
        <Tooltip title={props.tooltip}>
            <Grid container className='menu_icon'
                direction="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
                sx={{
                    cursor: 'pointer',
                    color: 'textLowEmphasis.main',
                    ":hover": { color: 'textHighEmphasis.main' }
                }}
            >
                <Grid item className='icon'
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    {props.children}
                </Grid>
                <Grid item className='icon_text'>
                    <Typography variant='subtitle2'>
                        {props.text}
                    </Typography>
                </Grid>
            </Grid>
        </Tooltip>
    );
};