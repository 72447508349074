import configs from "../configs/configs";
import authService from "./authService";

class httpSupportService {

    async getDefaultHeaders() {
        let token = "Bearer " + (await authService.getToken());
        return {
            "Authorization": token,
            "Ocp-Apim-Subscription-Key": configs.APIM.SUBSCRIPTION_KEY,
            "Content-type": 'application/json'
        }
    }
    
    async getDefaultHeadersWithoutAuth() {
        return {
            "Ocp-Apim-Subscription-Key": configs.APIM.SUBSCRIPTION_KEY,
            "Content-type": 'application/json'
        }
    }

    async getDefaultGetConfigs() {
        return {
            method: 'GET',
            headers: (await this.getDefaultHeaders())
        }
    }

    getPublicGetConfigs() {
        return {
            method: 'GET',
            headers: {
                "Ocp-Apim-Subscription-Key": configs.APIM.SUBSCRIPTION_KEY,
                "Content-type": 'application/json'
            }
        }
    }

    async getDefaultPostConfigs(body) {
        return {
            method: 'POST',
            headers: (await this.getDefaultHeaders()),
            body: JSON.stringify(body)
        }
    }

    async getDefaultPutConfigs(body) {
        return {
            method: 'PUT',
            headers: (await this.getDefaultHeaders()),
            body: JSON.stringify(body)
        }
    }

    getPublicPutConfigs(body) {
        return {
            method: 'PUT',
            headers: {
                "Ocp-Apim-Subscription-Key": configs.APIM.SUBSCRIPTION_KEY,
                "Content-type": 'application/json'
            },
            body: JSON.stringify(body)
        }
    }

    getQueryStringForObj(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return '?' + keyValuePairs.join('&');
    }

    async doRequest(url, configs) {
        url = encodeURI(url)
        let response = await fetch(url, configs)
        if (response.ok) {
            return response
        } else {
            let res = "Http Request Error with code - " + (response.status)
            console.error(res, response)
            const err = new Error(res)
            err.name = "httpError"
            err.httpStatus = response.status
            err.response = response
            throw err
        }
    }
}

export default new httpSupportService()
