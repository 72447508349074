import { Button, CircularProgress, Grid, IconButton, Menu, Paper, Rating, Tabs, Tab, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'

// import LiveTvIcon from '@mui/icons-material/LiveTv';
// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import { ShareDialog } from '../../../components/ShareDialog'
import Interval from '../../../components/Interval'
import RoundTableService from '../../../services/roundTablesService'
import { AddToCalendarDialog } from '../../../components/AddToCalendarDialog'
import useWindowSize from '../../../components/hooks/useWindowSize'
import { sp1, sp3, sp4 } from '../../../configs/spacings'
import { HostDetails, RoudnTableStatus, TopFlag, shareRoundtable, joinMeToRoundTable, updateParticipants as updateParticipantsGlobal, MainActionAlreadyJoined, MainActionReadyToConnect } from '../commonComponents'
import reduxStore from '../../../states/reduxStore'
import { removeFromMyRoundTables } from '../../../states/roundTableState'
import { RateRoundTableView } from './RateRoundTableView'
import ProfileCardSmall from '../../../components/ProfileCardSmall'
import roundTablesService from '../../../services/roundTablesService'
import { displayAlertDialog } from '../../../components/AlertDialog'
import RoundtableAttachmentsView from './RoundtableAttachmentsView';

export const RoundTableChildDefault = ({ onFetchRTProgress }) => {
    
    const gaLabel = "roundtable_details"
    const roundTable = useSelector(state => state.roundTableState.selectedRoundTable)
    const myProfile = useSelector(state => state.appState.myProfile)
    const [shareDialogState, setShareDialogState] = useState({ open: false });
    const [addCalDialogState, setAddCallDialogState] = useState({ open: false });
    const [participantsState, setParticipants] = useState([]);
    const [joinLoadingState, setJoinLoading] = useState(false)
    const [isLikedState, setisLiked] = useState({ isLiked: false, isLoading: false })
    const [rtAutoConnect, setRtAutoConnect] = useState(false)
    const [tab, setTab] = useState(0);

    const navigate = useNavigate()
    const windowSize = useWindowSize()
    const [searchParams, setSearchParams] = useSearchParams()

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const updateParticipants = (rawParticipants) => {
        updateParticipantsGlobal(rawParticipants, roundTable, setParticipants)
    }

    const restructureParticipantListBasedOnRTCurrentState = (roundTable) => {
        let cs = roundTable.currentState
        if (cs.isReadyToStart) {
            let activeparticipants = roundTable.meetingProgress.attendance;
            // console.log("Roundtable attendacne count: " + activeparticipants.length)

            if (cs.isLive && !cs.isClosed) { // this means meeting is live
                activeparticipants = activeparticipants.filter(p => p.statusText === "Active")
            }
            const activeMap = {}
            const activeList = []
            const inactiveList = []
            const notConnectedList = []

            activeparticipants.forEach(participant => {
                activeMap[participant.userId] = participant.statusText
            });
            let rtParticipants = roundTable.participants
            rtParticipants.forEach(participant => {
                let activeP = activeMap[participant.id]
                if (activeP) {
                    participant = { ...participant, liveStatus: activeP }
                    if (activeP === "Active") {
                        activeList.push(participant)
                    } else {
                        inactiveList.push(participant)
                    }
                } else {
                    participant = { ...participant, liveStatus: "offline" }
                    notConnectedList.push(participant)
                }
            });

            updateParticipants(activeList.concat(inactiveList).concat(notConnectedList))

        } else if (cs.isEnded) {
            let attended = roundTable.meetingProgress.attendance;

            const attendedMap = {}
            const attendedList = []

            attended.forEach(participant => {
                attendedMap[participant.userId] = participant.statusText
            });
            let rtParticipants = roundTable.participants
            rtParticipants.forEach(participant => {
                let a = attendedMap[participant.id]
                if (a) {
                    attendedList.push(participant)
                }
            });

            updateParticipants(attendedList)
        } else {
            updateParticipants(roundTable.participants)
        }

    }

    const handleQueryParams = () => {
        let shareId = searchParams.get("share")
        let join = searchParams.get("join")
        if (join) {
            if (!roundTable.currentState.isClosed) {
                joinMeToRoundTable(roundTable, setJoinLoading, onFetchRTProgress, setRtAutoConnect)
            } else {
                displayAlertDialog({
                    title: "Cannot Join This Roundtable",
                    message: `You cannot join this roundtable now as it already finished. Please explore to find more roundtables.`
                })

            }
        }
        if (shareId && shareId !== "null") {
            shareId = JSON.parse(shareId)
            roundTablesService.getRoundtableShare(shareId)
        }
        if (join || shareId) setSearchParams({}, { replace: true })
    }

    useEffect(() => {
        if (roundTable && roundTable.currentState) {
            handleQueryParams()
            setisLiked({ isLiked: roundTable.userActions.liked, loading: false })
            restructureParticipantListBasedOnRTCurrentState(roundTable)
        }
    }, [roundTable]);

    const showProfileDialog = (profile) => {
        // setProfileDialogState(
        //     {
        //         profile: profile,
        //         open: true,
        //         onClose: () => {
        //             setProfileDialogState({ open: false })
        //         }
        //     }
        // );
    }

    const showAddToCalendar = () => {
        const description = `Cypeer Event - ${roundTable.tableTopic}.` +
            `\nUse this link to connect: ${window.location.href}\n`
        setAddCallDialogState(
            {
                open: true,
                onClose: () => {
                    setAddCallDialogState({ open: false })
                },
                event: {
                    title: 'Cypeer Event: ' + roundTable.tableTopic,
                    description: description,
                    start: roundTable.tableDateTime,
                    duration: [roundTable.duration, "minute"],
                    url: window.location.href
                }
            }

        )
    }


    const cancelJoinFromRoundTable = () => {
        setJoinLoading(true)
        RoundTableService.joinRoundTable(roundTable.id, false)
            .then((updatedRT) => {
                onFetchRTProgress(updatedRT)
                reduxStore.dispatch(removeFromMyRoundTables(updatedRT))
                RoundTableService.refreshMyRoundtableCount()
            })
            .catch((e) => {
                console.error(e)
                displayAlertDialog({
                    title: "Something went wrong",
                    message: "Something went wrong. Please refresh the page and try again"
                })
            }).finally(() => {
                setJoinLoading(false)
            })

        ReactGA.event({
            category: 'Button_Clicks',
            action: 'Leave_roundtable',
            label: gaLabel
        });
    }

    const toggleLike = () => {
        if (!isLikedState.isLoading) {
            let newState = !isLikedState.isLiked
            setisLiked({ ...isLikedState, isLiked: newState, isLoading: true })
            RoundTableService.updateLike(roundTable.id, newState).then(onFetchRTProgress).catch((e) => {
                console.error(e)
                setisLiked({ ...isLikedState, isLiked: !newState, isLoading: false })
                displayAlertDialog({
                    title: "Something went wrong",
                    message: "Something went wrong. Pleaase refresh the page and try again"
                })
            })
            ReactGA.event({
                category: 'Button_Clicks',
                action: 'Like_Dislike_roundtable',
                label: gaLabel,
                isLiked: newState
            });
        }
    }

    // UIs

    const CookedTopic = () => {
        let w = windowSize.width * 1000
        // w = w > 1200 ? 1200 : w //as rountable parent view not scale than 1200 TODO: take it from config
        let maxLength = Math.floor(w / 6.8)
        if (roundTable.tableTopic.length < maxLength) {
            return <Typography component="div" variant="h4" align="left">
                {roundTable.tableTopic}
            </Typography>
        } else {
            const DetailedTopic = ({ partialContent, fullContent }) => {
                const [anchorEl, setAnchorEl] = useState(null);
                const open = Boolean(anchorEl);

                const handleClick = (event) => {
                    setAnchorEl(event.currentTarget);
                };
                const handleClose = () => {
                    setAnchorEl(null);
                };

                return (
                    <div>
                        <Typography
                            component="div" variant="h4" align="left" id="demo-positioned-button"
                            sx={{ cursor: 'pointer' }}
                            aria-controls={open ? 'roundtable-title-with-pop' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>
                            {partialContent}
                        </Typography>
                        <Menu
                            id="roundtable-title-with-pop"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <div style={{ padding: '8px', maxWidth: '1000px' }}>{fullContent}</div>
                        </Menu>
                    </div>
                );
            }
            let t = roundTable.tableTopic.substring(0, maxLength)
            let li = t.lastIndexOf(" ")
            let partialContent = `${t.substring(0, li)}...`



            return <DetailedTopic partialContent={partialContent} fullContent={roundTable.tableTopic} />
        }
    }

    const Description = () => {
        if (roundTable.description) {
            return <Typography
                component="div"
                variant="subtitle1"
                color={"text.primary"}
                align="left"
                sx={{ pt: 1, pl: '1px', lineHeight: '1.3em' }}
            >
                {roundTable.description}
            </Typography>
        }
    }

    const MainAction = () => {
        let cs = roundTable.currentState;
        let amIin = cs.amIin;

        // if (cs.isYetToStart) {
        //     let fmtDate = roundTable.tableDateTime
        //     if (amIin) {
        //         return <MainActionAlreadyJoined roundTable={roundTable} />
        //         // return <Typography variant="subtitle1" fontWeight='600' display={"flex"} alignItems={"center"} color={"text.secondary"}>
        //         //     <QueryBuilderIcon fontSize='inherit' sx={{ mr: 1 }} /> <Moment fromNow >{fmtDate}</Moment>
        //         // </Typography>
        //     } else if (roundTable.participants.length < roundTable.maxParticipants) {
        //         let avaialbe = roundTable.maxParticipants - participantsState.length;
        //         return <>
        //             <Typography variant="subtitle2" mr={2} fontWeight="600">
        //                 {`${avaialbe} slot${avaialbe > 1 ? 's ' : ' '} left`}
        //             </Typography>
        //             <LoadingButton
        //                 onClick={(e) => { 
        //                     ReactGA.event({
        //                         category: 'Button_Clicks',
        //                         action: 'Join_Roundtable',
        //                         label: gaLabel
        //                     });
        //                     joinMeToRoundTable(roundTable, setJoinLoading, onFetchRTProgress) }}
        //                 loading={joinLoadingState}
        //                 loadingIndicator="Joining..."
        //                 variant="contained"
        //                 sx={{ minWidth: '8em' }}
        //             >
        //                 Join
        //             </LoadingButton>
        //             {/* <Button variant="outlined" startIcon={<GroupsIcon />} onClick={joinMeToRoundTable} > Join </Button> */}
        //         </>
        //     } else {
        //         return <>
        //             <Typography variant="subtitle2" fontWeight="600">
        //                 Session full
        //             </Typography>
        //         </>
        //     }
        // } else if (cs.isReadyToStart) {
        //     if (amIin && cs.isApproved) {
        //         return <Button color="success" variant="contained" onClick={handleInfoDialogOpen} > Connect </Button>
        //         // onClick={() => navigate('join')}
        //     } else {
        //         if (cs.isLive) {
        //             // return <Button variant="outlined" startIcon={<LiveTvIcon />} onClick={() => navigate('live')} disabled> Watch Live </Button>
        //         }
        //     }
        // }
        // else if (cs.isEnded) {
        //     // as recording feature not implemented with current phase
        //     // return <>
        //     //     {(cs.isRecodingsReady)
        //     //         ? <Button variant="outlined"
        //     //             startIcon={<PlayCircleOutlineIcon />}
        //     //             onClick={() => navigate('recording')}
        //     //             sx={{ width: '100%' }} > Watch Now </Button>
        //     //         : <div>Awiat for recording..</div>
        //     //     }
        //     // </>
        // }
        // //TODO handle this well
        // return <div></div>

        if (cs.isReadyToStart && amIin && cs.isApproved) {
                return <MainActionReadyToConnect roundTable={roundTable} gaLabel={gaLabel} autoConnect={rtAutoConnect}/>
        }
        else if (!cs.isClosed) {
            let fmtDate = roundTable.tableDateTime
            if (amIin) {
                return <MainActionAlreadyJoined roundTable={roundTable} />
            } else if (roundTable.participants.length < roundTable.maxParticipants) {
                let avaialbe = roundTable.maxParticipants - participantsState.length;
                return <>
                    <Typography variant="subtitle2" mr={2} fontWeight="600">
                        {`${avaialbe} slot${avaialbe > 1 ? 's ' : ' '} left`}
                    </Typography>
                    <LoadingButton
                        onClick={(e) => { 
                            ReactGA.event({
                                category: 'Button_Clicks',
                                action: 'Join_Roundtable',
                                label: gaLabel
                            });
                            
                            joinMeToRoundTable(roundTable, setJoinLoading, onFetchRTProgress, setRtAutoConnect) }}
                        loading={joinLoadingState}
                        loadingIndicator="Joining..."
                        variant="contained"
                        sx={{ minWidth: '8em' }}
                    >
                        Join
                    </LoadingButton>
                    {/* <Button variant="outlined" startIcon={<GroupsIcon />} onClick={joinMeToRoundTable} > Join </Button> */}
                </>
            } else {
                return <>
                    <Typography variant="subtitle2" fontWeight="600">
                        Session full
                    </Typography>
                </>
            }
        }
        return <div></div>
    }

    const RatingView = () => {
        let cs = roundTable.currentState;
        if (cs.isEnded || (cs.isClosed && cs.isLive)) {
            return <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column' },
                alignItems: { xs: 'flex-start' }
            }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <Typography variant="body2" color={"text.secondary"}>{roundTable.ratingAverage} ({roundTable.rateCount} ratings)</Typography>
                    <Rating name="no-value" value={roundTable.ratingAverage} readOnly />
                </Box>
            </Box>
        }
    }

    const ConfigureInterval = () => {
        let cs = roundTable.currentState;
        if (cs.isReadyToStart || (cs.isLive && cs.liveDuration <= roundTable.duration)) { //TODO As recording feature not going in this phase: || (cs.isEnded && !cs.isRecodingsReady))
            return <Interval intervalInSecs={20} action={() => { onFetchRTProgress() }} />
        } else if (cs.isYetToStart && cs.secondsToStart < 86400) { //24hrs
            console.log("set Interval to " + cs.secondsToStart + 2)
            const startBuffer = 5  //TODO: take this from configs
            const stTime = moment(roundTable.tableDateTime).subtract(startBuffer,'minutes')
            return <Interval intervalInSecs={10} action={() => { onFetchRTProgress() }} startTime={stTime} />
        }
    }

    const shouldDisplayUpdateRating = () => {
        let cs = roundTable.currentState;
        if (cs.amIin && (cs.isEnded || cs.isClosed || cs.isLive)) {
            let adts = roundTable.meetingProgress.attendance
            for (let index = 0; index < adts.length; index++) {
                const atd = adts[index];
                if (myProfile.id === atd.userId) {
                    return true
                }
            }
        } else {
            return false
        }
    }

    const CurrentTab = () => {
        if (tab === 0) { //Participants
            return <Box sx={{ display: 'flex' }} p={sp1}>
                <Box sx={{ width: '100%' }}>

                    {/* <Typography component="div" variant="h4" align="left" fontWeight='500' >
                                Participants */}
                    {/* {(roundTable.currentState.isEnded || roundTable.currentState.liveDuration > maxDuration) ? 'Attended' : roundTable.currentState.isLive ? 'Attending Right Now' : 'Attendees'} */}
                    {/* </Typography> */}
                    <Grid container spacing={{ xs: 2, md: 4 }} sx={{ mt: 1, mb: 1 }}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                    >
                        {participantsState.map((profile) => (
                            <Grid item xs={12} sm={6} md={4} key={profile.id} >
                                <ProfileCardSmall profile={profile} />
                                {/* <Card
                                            elevation={0}
                                            sx={{ display: 'flex', py: 1, height: '100%' }}
                                            onClick={() => { showProfileDialog(profile) }}
                                        >
                                            {getMatchingAvatarForProfile(profile, "large", null, profile.liveStatus)}
                                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                <Typography variant="subtitle1" lineHeight="1" fontWeight="600">
                                                    {profile.displayName}
                                                </Typography>
                                                {(profile.role && profile.role !== "Other") && <Typography variant="body1" color="text.secondary" component="div" fontWeight="500">
                                                    {profile.role}
                                                </Typography>}
                                                {profile.organization && <Typography variant="subtitle2" color="text.secondary" component="div" fontWeight="500">
                                                    {profile.organization}
                                                </Typography>}
                                            </Box>
                                            
                                        </Card> */}
                            </Grid>
                        ))}
                    </Grid>
                    {(!participantsState.length)
                        ? <Box
                            height={65}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            color={"lightgray"}
                        >No Participant Found</Box>
                        : <></>}

                </Box >
            </Box>
        } else { // Attachments
            return <RoundtableAttachmentsView roundTable={roundTable}/>
        }
    }


    //default child view
    return (
        <div>
            <Box py={1} id="ExploreButtonSpace">
                <Button variant="outlined"
                    // startIcon={<ArrowBackIosNewRoundedIcon fontSize='inherit'/>}
                    onClick={() => {
                        navigate('/roundtables')
                        ReactGA.event({
                            category: 'Button_Clicks',
                            action: 'Back_To_Explore',
                            label: gaLabel
                        });
                    }}>
                    <ArrowBackIosNewRoundedIcon fontSize='medium' />
                    <Typography component='span' variant='subtitle2' fontWeight={500}>
                        Explore
                    </Typography>
                </Button>
            </Box>
            <Paper id="rtDetailsContainer" variant='outlined' sx={{
                // borderRadius: sp9,
                // px: sp7,
                borderRadius: '0.8rem',
                mt: sp1,
                px: sp4,
                pb: sp4,
            }}>
                <Box id="rtDetails-primaryInfo">
                    {TopFlag(roundTable)}
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {CookedTopic()}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                            <IconButton
                                color="primaryX"
                                aria-label="share"
                                component="span"
                                size="small" sx={{ ml: sp1 }}
                                onClick={() => { 
                                    shareRoundtable(roundTable, setShareDialogState, onFetchRTProgress)  
                                    ReactGA.event({
                                        category: 'Button_Clicks',
                                        action: 'Share_Roundtable',
                                        label: gaLabel
                                    });
                                }}
                                disabled={shareDialogState.loading}
                            >
                                {shareDialogState.loading
                                    ? <CircularProgress size="1em" color='inherit' />
                                    : <ShareIcon fontSize='inherit' />}
                            </IconButton>

                            <IconButton
                                color={isLikedState.isLiked ? "primary" : "primaryX"}
                                aria-label="like"
                                component="span"
                                size="small"
                                sx={{ ml: sp1 }}
                                onClick={toggleLike}
                            >
                                {isLikedState.isLiked
                                    ? <FavoriteRoundedIcon />
                                    : <FavoriteBorderIcon />
                                }

                            </IconButton>
                        </Box>
                    </Box>
                    {Description()}
                    <Grid container spacing={2} sx={{ pt: 0, pb: sp3 }}
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                    >
                        <Grid item sm={"auto"}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, justifyContent: 'space-between' }}>
                                {HostDetails(roundTable, showProfileDialog)}
                            </Box>
                        </Grid>
                        <Grid item sm={"auto"}>
                            {RatingView()}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ pt: 0, flexWrap: 'wrap' }}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item sm={"auto"} sx={{ flexGrow: 1 }}>
                            {RoudnTableStatus(roundTable)}
                        </Grid>
                        <Grid item sm={"auto"} sx={{ flexGrow: 1 }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end', //{ xs: 'center', sm: 'flex-end' },
                                alignItems: 'center' // { xs: 'center', sm: 'center' }
                            }}
                            >
                                {MainAction()}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>


                {shouldDisplayUpdateRating() && <Paper id="rtDetails-ratingActionBox" variant='outlined'
                    sx={{
                        // borderRadius: sp5, px: sp7, py: sp4, mt: sp5  
                        borderRadius: '0.8rem',
                        px: sp4,
                        py: sp4,
                        mt: sp4
                    }}>
                    <RateRoundTableView
                        onClose={onFetchRTProgress}
                        myProfile={myProfile}
                        roundTable={roundTable}
                        attendees={participantsState} />
                </Paper>
                }

                <Paper id="rtDetails-secondryInfo" variant='outlined' sx={{
                    borderRadius: '0.8rem',
                    px: sp4,
                    pb: sp4,
                    mt: sp4
                }}>
                    <Tabs value={tab} onChange={handleTabChange}
                        aria-label="Roudtable details tabs"
                        textColor="inherit"
                    >
                        <Tab label="Participants" />
                        {roundTable.currentState.amIin && <Tab label="Attachments" />}
                    </Tabs>
                    <CurrentTab />
                </Paper>



                {roundTable.currentState.isYetToStart && roundTable.currentState.amIin && roundTable.host.id !== myProfile.id && <Paper variant='outlined'
                    sx={{
                        // borderRadius: sp5, px: sp7, py: sp4, mt: sp5  
                        borderRadius: '0.8rem',
                        px: sp4,
                        py: sp4,
                        mt: sp4
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* <Button variant="text"
                            color="primaryX"
                            startIcon={<EventNoteIcon />}
                            onClick={showAddToCalendar}
                            sx={{ lineHeight: 1, justifyContent: 'flex-start' }}>
                            Add To Calendar
                        </Button> */}
                        {/* {roundTable.host.id !== myProfile.id && <LoadingButton variant="text"
                            color="primaryX"
                            startIcon={<CancelOutlinedIcon />}
                            onClick={cancelJoinFromRoundTable}
                            loading={joinLoadingState}
                            loadingIndicator="Leaving..."
                            sx={{ lineHeight: 1, justifyContent: 'flex-end' }}>
                            Leave this Roundtable
                        </LoadingButton>
                            //     <LoadingButton
                            //     onClick={joinMeToRoundTable}
                            //     loading={joinLoadingState}
                            //     loadingIndicator="Joining..."
                            //     variant="outlined"
                            //     sx={{ minWidth: '8em' }}
                            // >
                            //     Join
                            // </LoadingButton>
                        } */}
                        <LoadingButton variant="text"
                            color="primaryX"
                            startIcon={<CancelOutlinedIcon />}
                            onClick={cancelJoinFromRoundTable}
                            loading={joinLoadingState}
                            loadingIndicator="Leaving..."
                            sx={{ lineHeight: 1, justifyContent: 'flex-end' }}>
                            Leave this Roundtable
                        </LoadingButton>

                    </Box>
                </Paper>
                }

                {ConfigureInterval()}

            </Paper>
            {shareDialogState.open && <ShareDialog open={shareDialogState.open}
                onClose={shareDialogState.onClose}
                shareContent={shareDialogState.shareContent}
            />}
            {addCalDialogState.open && <AddToCalendarDialog open={addCalDialogState.open}
                onClose={addCalDialogState.onClose}
                event={addCalDialogState.event}
            />}
        </div>
    );
}
