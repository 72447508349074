import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { displayYesNoDialog } from '../../../components/AlertDialog'
import { sp1, sp3 } from '../../../configs/spacings'
import fileService from '../../../services/fileService'
import roundTablesService from '../../../services/roundTablesService'
import reduxStore from '../../../states/reduxStore'
import { updateSelectedRoundTable } from '../../../states/roundTableState'

const RoundtableAttachmentsView = ({ roundTable }) => {

    const [isLoading, setLoading] = useState(false)
    
    useEffect(() => {
        if (!roundTable.attachments) loadAttachments()
    }, [])

    const loadAttachments = () => {
        setLoading(true)
        roundTablesService.getRoundTableAttachments(roundTable.id)
            .then(attachments => reduxStore.dispatch(updateSelectedRoundTable({ ...roundTable, attachments })))
            .catch(console.error)
            .finally(() => setLoading(false))
    }

    const Attachment = ({ fileInfo }) => {
        const [downloading, setDownloading] = useState(false)
        const [cancelDownlaodingStateFunction, setCancelDownlaodingStateFunction] = useState(() => { })
        const [progress, setProgress] = useState({ percentage: 0, totalSize: 0, downloadedSize: 0 })
        const oneMB = 1024 * 1024

        const getMatchingSizeString = (size) => {
            size /= 1024
            if (size < 1024)
                return Math.round(size) + "KB"
            size /= 1024
            if (size < 750) // show as MB if it is less that 750MB or else it will show as GB
                return Math.round(size) + "MB"
            size /= 10.24
            return Math.round(size) / 100 + "GB" // round GB to 2 decimal places
        }

        const onProgress = (total, current) => {
            let decimal = total < oneMB ? 1 :
                total < 10 * oneMB ? 10 : 100
            const percentage = Math.round(current * 100 * decimal / total) / decimal
            if (progress.percentage !== percentage) {
                progress.percentage = percentage
                progress.totalSize = total
                progress.downloadedSize = current
                setProgress({ ...progress })
                // console.log(`${getMatchingSizeString(current)} ${progress.percentage}`)
            }
        }

        const downloadFile = () => {
            const handler = fileService.downloadFileFromUploadsContainer(`roundtables/${roundTable.id}`, fileInfo.name, onProgress)
            setDownloading(true)
            progress.percentage = 0
            setProgress({ percentage: 0, downloadedSize: 0, totalSize: fileInfo.size})
            setCancelDownlaodingStateFunction(() => handler.cancel)
            handler.promise.finally(() => setDownloading(false))
        }

        const cancelDownloading = () => {
            // it will not ask cancel download confirmation alert if dld size is less the 5 mb
            if (progress.downloadedSize < 5 * oneMB) {
                cancelDownlaodingStateFunction()
            } else {
                displayYesNoDialog({
                    title: "Cancel Download",
                    message: `Are sure you want to cancel downlad '${fileInfo.name}'`,
                    yesButtonText: "Continue Download",
                    noButtonText: "Cancel",
                    yesButtonAction: undefined,
                    noButtonAction: () => cancelDownlaodingStateFunction(),
                })
            }
        }

        return <Box py={sp1} >
            {!downloading
                ? <Box><a onClick={() => downloadFile()}>{fileInfo.name}</a><Typography variant="subtitle2" lineHeight="1" color="text.secondary" component="span">
                        {` ${getMatchingSizeString(fileInfo.size)}`}
                    </Typography>
                </Box>
                : <Box> {fileInfo.name} <Typography variant="subtitle2" lineHeight="1" color="text.secondary" component="span">
                        {` downloading (${progress.percentage}%) - ${getMatchingSizeString(progress.downloadedSize)} of ${getMatchingSizeString(progress.totalSize)}`}
                    </Typography> <a onClick={cancelDownloading}>cancel</a>
                </Box>
            }

        </Box>
    }

    const AttachmentsList = () => {
        const attachmentsList = roundTable.attachments
        if (attachmentsList && attachmentsList.length > 0) {
            return <Box>
                {attachmentsList.map(atm => (
                    <Attachment fileInfo={atm} key={atm.name} />
                ))}
            </Box>
        } else {
            return `No attachments`
        }
    }

    return (
        <Box p={sp1}>
            {isLoading
                ? "Loading"
                : <AttachmentsList />
            }
        </Box>

    )
}

export default RoundtableAttachmentsView
