let shareInvite = {
    'title': 'Invite to Cypeer',
    'subtitle': 'Who would be a great addition to Cypeer? Invite your colleagues and get recognized in their profile.',
    'socialMediaButtonsTitle': 'Share your invitation link in:',
    'shareContent': {
        'subject': 'Join me at Cypeer!',
        'title': 'I\'m using Cypeer to discuss cybersecurity topics live.\nYou should Join us on Cypeer.\nHere is the link!',
        'defaultMessage': 'Join me at Cypeer',
    }
};

let nominations = {
    'title': 'is inviting you to join them at Cypeer.',
    'subtitle': 'Discover and connect with your cybersecurity peers in a whole new way.'
};

let verifyEmail = {
    'title': 'Verify your email address',
    'subtitle': 'We have sent an email with a unique link to ',
    'info_1': 'If you don\'t see it in your inbox, try checking the spam/junk folder.',
    'info_2': 'Move the mail to your inbox and click on the Verify Email link to activate your account.',
    'resendText': 'Still don\'t see it?',
    'resendButton': 'Resend',
    'resendSuccessMessage': 'Verification email sent',
};

let verifiedEmail = {
    'successTitle': 'Your email is successfully verified!',
    'successSubtitle': 'Explore cypeer to connect with your peers.',
    'failTitle': 'We failed to verify your email address',
    'failSubtitle': 'Please log in again and resend a new verification email from your profile. \n If you have multiple verification emails received, please use the newest',
    'loggedInButton': 'Explore',
    'loggedOutButton': 'Log in',
};

export { shareInvite, nominations, verifyEmail, verifiedEmail };