import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import FormLabel from '@mui/material/FormLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TodayIcon from '@mui/icons-material/Today';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Moment from 'react-moment';
import moment from 'moment';
import configs from '../../configs/configs';
import { updateHomeFilter, updateSelectedRoundTable, updateStartRTForm } from '../../states/roundTableState';
import { sp3, sp4 } from '../../configs/spacings';
import RoundTablesService from "../../services/roundTablesService";
import { displayAlertDialog } from '../../components/AlertDialog'

function StartRoundTable() {

    const dispatch = useDispatch()
    let navigate = useNavigate();

    const stateFormData = useSelector(state => state.roundTableState.startRTState.formData);

    const [masterData, setMasterData] = useState({
        interests: [],
        types: []
    });

    const [formData, setformData] = useState({
        topic: '',
        type: null,
        keywords: [],
        dateTime: null
    });

    const [timeWarning, setTimeWarning] = useState({ show: false, type: 'warning', message: '' });
    const [timeError, setTimeError] = useState(false);
    const [keywordError, setKeywordError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isMasterDataError, setIsMasterDataError] = useState(false);

    const minDateTime = new Date(new Date().toDateString());
    minDateTime.setDate(minDateTime.getDate() + configs.ROUND_TABLE.START_DATE_INTERVAL_DAYS);

    useEffect(() => {
        window.scrollTo(0, 0);

        ReactGA.pageview(window.location.pathname + '/startRoundtable');

        if (stateFormData) {
            setformData({
                topic: stateFormData.topic,
                type: stateFormData.type,
                keywords: stateFormData.keywords,
                dateTime: stateFormData.dateTime ? new Date(stateFormData.dateTime) : null
            });
        }

        RoundTablesService.getStartRTMaterData().then(data => {
            setIsMasterDataError(false);
            setMasterData(data);
        }).catch(e => {
            setIsMasterDataError(true);
            console.error("Loading Roundtable MasterdataFailed", e);
            displayAlertDialog({
                title: "Something went wrong",
                message: "Sorry! Something went wrong while loading pre defined list contents. Please try again shortly"
            })
        });
    }, []);

    useEffect(() => {
        let serializableFormData = { ...formData };
        serializableFormData.dateTime = formData.dateTime?.toString();
        dispatch(updateStartRTForm(serializableFormData));
    }, [formData]);


    const handleTopicChange = function (event) {
        setformData({ ...formData, topic: event.target.value });
    };

    const handleTypeSelect = function (event, newValue) {
        setformData({ ...formData, type: newValue });
    };

    const handleDateTimeChange = function (newValue) {
        newValue.setSeconds(0);
        var now = moment();
        var today = moment().startOf('day');
        var nv = moment(newValue);
        var days = nv.diff(today, 'days');

        if (nv.isBefore(now)) {
            setTimeError(true);
            setTimeWarning({ show: true, type: 'error', message: 'You cannot select a time in the past' });
        } else if (days < configs.ROUND_TABLE.START_DATE_WARNING_DAYS) {
            setTimeError(false);
            setTimeWarning({ show: true, type: 'warning', message: 'This date may be too soon to find others who can join' });
        } else {
            setTimeError(false);
            setTimeWarning({ show: false, type: 'warning', message: '' });
        }

        setformData({ ...formData, dateTime: newValue });
    };

    const handleKeywordChange = function (event, newValue) {
        setKeywordError(false);
        if (event.target.value && event.target.value !== 0) {
            let keywordsCopy = [...formData.keywords];
            keywordsCopy.push({ id: -1, value: event.target.value })
            setformData({ ...formData, keywords: keywordsCopy });
        } else {
            setformData({ ...formData, keywords: newValue });
        }
    }

    const handleClear = () => {
        setformData({
            topic: '',
            type: null,
            keywords: [],
            dateTime: null
        });
        setTimeWarning({ show: false, type: 'warning', message: '' });
        setTimeError(false);
        setKeywordError(false);
        setIsSubmitting(false);
    };

    const handleSubmit = function (event) {
        event.preventDefault();

        if (formData.dateTime === null || formData.dateTime === undefined) {
            setTimeError(true);
            return;
        }

        var now = moment();
        var selectedTime = moment(formData.dateTime);
        if (selectedTime.isBefore(now)) {
            setTimeError(true);
            setTimeWarning({ show: true, type: 'error', message: 'You cannot select a time in the past' });
            return;
        }

        if (formData.keywords.length === 0) {
            setKeywordError(true);
            return;
        }

        let payload = { ...formData };
        payload.newKeywords = payload.keywords.filter(k => k.id === -1).map(n => n.value);
        payload.keywords = payload.keywords.filter(k => k.id !== -1);
        setIsSubmitting(true);
        RoundTablesService.addRoundTable(payload)
            .then(newRT => {
                RoundTablesService.refreshMyRoundtableCount();
                dispatch(updateStartRTForm(null));
                dispatch(updateSelectedRoundTable(newRT));
                dispatch(updateHomeFilter({ filter: 'my' })); // to navigate to my list
                dispatch(updateHomeFilter({ filter: 'my' }));// to refresh the my list
                navigate(`/roundtables/${newRT.id}`);
            })
            .catch(error => {
                //TODO: handle special cases based on error details based on serverside validation error messages
                console.error("Error on start roundtable submit: ", error)
                displayAlertDialog({
                    title: "Something went wrong",
                    message: "Oops! Something went wrong while submitting new roundtable to server. Please try again shortly"
                })
                setIsSubmitting(false);
            })
            //.finally(() => setIsSubmitting(false));
    };

    const handleFormKeyDown = function (event) {
        if (event.key === 'Enter' && event.shiftKey === false) {
            event.preventDefault();
        }
    };

    return (
        <Paper variant='outlined' className="start_roundtable"
            sx={{
                borderRadius: '0.8rem',
                px: sp4,
                py: sp3,
                mb: sp4
            }}
        >
            <form className='new_roundtable_form' onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
                <Grid container>
                    <Grid item className='title' xs={12}
                        sx={{
                            mb: { xs: 1, md: 2 }
                        }}
                    >
                        <Typography variant="h4">
                            Start a new discussion
                        </Typography>
                        <Typography variant="subtitle2" sx={{ mt: 1 }}>
                            Roundtable is a {configs.ROUND_TABLE.MAX_DURATION} mins live video meeting with your peers who share the same interest.
                        </Typography>
                    </Grid>

                    <Grid item className='topic' xs={12}
                        sx={{
                            pt: { xs: 3 },

                        }}
                    >
                        <FormControl fullWidth>
                            <FormLabel required>
                                Explain the topic of your discussion. A good description will help you attract the right peers for the conversation (max 200 characters).
                            </FormLabel>
                            <TextField id="topic-input"
                                multiline
                                rows={2}
                                size='small'
                                required
                                type="text"
                                placeholder="What is the topic of your roundtable?"
                                sx={{
                                    "& legend": {
                                        width: 0,
                                    }
                                }}
                                value={formData.topic}
                                onChange={handleTopicChange}
                                inputProps={{ maxLength: 200 }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className='dateTime' xs={12}
                        sx={{
                            pt: { xs: 4 },
                            // pr: { md: 2 },
                        }}
                    >
                        <FormLabel required>
                            Pick a date for your discussion which is not too close and not too far in the future.
                        </FormLabel>
                        <Grid container direction='row'>
                            <Grid item className='dateTime_picker' xs={12} md={4}
                                sx={{
                                    pr: { md: 2 },
                                }}
                            >
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDateTimePicker
                                            openOnFocus
                                            value={formData.dateTime}
                                            onChange={(newValue) => {
                                                handleDateTimeChange(newValue);
                                            }}
                                            minDateTime={minDateTime}
                                            renderInput={(params) =>
                                                <TextField  {...params}
                                                    size='small'
                                                    placeholder="Pick a date and time"
                                                    sx={{
                                                        "& legend": {
                                                            width: 0,
                                                        }
                                                    }}
                                                >
                                                </TextField>
                                            }
                                            error={timeError}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item className='dateTime_display' xs={12} md={8}
                                container
                                direction="row"
                                justifyContent="center"
                            >
                                <Grid item className='time_humanized' xs={12} md={5}>
                                    {
                                        formData.dateTime &&
                                        <Alert color="primary" icon={<QueryBuilderIcon fontSize='inherit' />}
                                            sx={{
                                                py: 0,
                                                background: 'none',
                                                cursor: 'auto'
                                            }}
                                        >
                                            <Moment local calendar={configs.DATE_TIME.CALENDAR_HUMANIZATION}>{formData.dateTime}</Moment>
                                        </Alert>
                                    }
                                </Grid>
                                <Grid item className='time_warning' xs={12} md={7}>
                                    {
                                        (
                                            formData.dateTime && timeWarning.show
                                        )
                                        &&
                                        <Alert severity={timeWarning.type} size='small'
                                            sx={{
                                                py: 0,
                                                cursor: 'auto',
                                            }}
                                        >
                                            {timeWarning.message}
                                        </Alert>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        isMasterDataError &&
                        <Alert severity='error' size='small'
                            sx={{
                                mt: { xs: 4 },
                                width: '100%'
                            }}
                        >
                            Sorry! Something went wrong while loading pre-defined list contents. Please try again shortly
                        </Alert>
                    }
                    <Grid item className='type' xs={12} md={4}
                        sx={{
                            pt: { xs: 4 },
                            pr: { md: 2 },
                        }}
                    >
                        <FormControl fullWidth>
                            <FormLabel required>Pick a type to show the intent of the discussion</FormLabel>
                            <Autocomplete
                                disablePortal
                                openOnFocus
                                blurOnSelect
                                id="typeSelect"
                                size='small'
                                value={formData.type}
                                onChange={handleTypeSelect}
                                getOptionLabel={(option) => option.value}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                options={masterData.types}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        placeholder='What kind of discussion is this?'
                                        sx={{
                                            "& legend": {
                                                width: 0,
                                            }
                                        }}
                                        required />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item className='keywords' xs={12} md={8}
                        sx={{
                            pt: { xs: 4 },
                            // pr: { md: 2 },
                        }}
                    >
                        <FormControl fullWidth>
                            <FormLabel required>Add keywords to help others find your roundtable easily</FormLabel>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                freeSolo
                                size='small'
                                limitTags={3}
                                id="keywords"
                                options={masterData.interests}
                                getOptionLabel={(option) => option.value}
                                noOptionsText="No Keywords"
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={formData.keywords}

                                onChange={handleKeywordChange}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        placeholder="Type keywords to tag your roundtable"
                                        sx={{
                                            "& legend": {
                                                width: 0,
                                            }
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    {
                        (!keywordError && !timeError) &&
                        <Alert severity='info' color='primary' icon={<TodayIcon fontSize='inherit' />} size='small'
                            sx={{
                                mt: { xs: 4 },
                                width: '100%'
                            }}
                        >
                            Once you create the roundtable, don't forget to add to your calendar from the next screen.
                        </Alert>
                    }
                    {
                        keywordError &&
                        <Alert severity='error' icon={<TodayIcon fontSize='inherit' />} size='small'
                            sx={{
                                mt: { xs: 4 },
                                width: '100%'
                            }}
                        >
                            Please select at least one keyword.
                        </Alert>
                    }
                    {
                        (timeError && !formData.dateTime) &&
                        <Alert severity='error' icon={<TodayIcon fontSize='inherit' />} size='small'
                            sx={{
                                mt: { xs: 4 },
                                width: '100%'
                            }}
                        >
                            Please select a time.
                        </Alert>
                    }
                    <Grid item className='actions' xs={12} container
                        sx={{
                            mt: { xs: 3 },
                            direction: "row",
                            justifyContent: "flex-end",
                            // border: 1,
                        }}
                    >
                        <Grid item className='clear' xs={6} sm={3} md={2}
                            sx={{
                                pr: { xs: 1 },
                                // border: 1,
                            }}>
                            <Button variant="outlined" onClick={handleClear} disabled={isSubmitting}
                                sx={{
                                    width: '-webkit-fill-available',
                                    px: { md: 6 },
                                    // py: { xs: 1, md: 2 },
                                }}
                            >
                                Clear
                            </Button>
                        </Grid>
                        <Grid item className='submit' xs={6} sm={3} md={2}
                            sx={{
                                pl: { xs: 1 },
                                // border: 1,
                            }}>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingIndicator="Creating..."
                                type="submit"
                                variant="contained"
                                disabled={timeError || keywordError || isMasterDataError}
                                sx={{
                                    width: '-webkit-fill-available',
                                    px: { md: 6 },
                                    // py: { xs: 1, md: 2 },
                                }}
                            > Create </LoadingButton>
                            {/* <Button type="submit" variant="contained" disabled={timeError || keywordError }
                                sx={{
                                    width: '-webkit-fill-available',
                                    px: { md: 6 },
                                    // py: { xs: 1, md: 2 },
                                }}
                            >
                                Create Old
                            </Button> */}
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default StartRoundTable;