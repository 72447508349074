import configs from "../configs/configs";
import httpSupportService from "./httpSupprotService";

export async function sendInvites(invitees) {

    var playload = {
        "invitees": invitees,
    }

    const options = await httpSupportService.getDefaultPostConfigs(playload);

    return fetch(configs.APIM.BASE_URL + configs.APIM.USER_BASE_PATH + "/invite", options)
        .then(response => response.status)
        .catch(console.log);
}

export async function updateNomination(nominatedBy) {
    let postCfg = await httpSupportService.getDefaultPostConfigs();
    let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/nominate/${nominatedBy}`

    return httpSupportService.doRequest(url, postCfg);
}

export async function sendEmailVerificationEmail() {
    let postCfg = await httpSupportService.getDefaultPostConfigs();
    let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/emailverification`

    return httpSupportService.doRequest(url, postCfg);
}

export async function updateWeeklyEmailPreference(preference) {
    let playload = {
        "weeklyAlerts": preference
    };
    let putCfg = await httpSupportService.getDefaultPutConfigs(playload);
    let url = `${configs.APIM.BASE_URL}${configs.APIM.USER_BASE_PATH}/preferences`

    return httpSupportService.doRequest(url, putCfg);
}