import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../login/Login";
import RoundTablePage from "./RoundTablePage";
import RoundTablesHome from "./RoundTablesHomePage";

function RoundTablesRoot() {

    const myProfile = useSelector(state => state.appState.myProfile);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'backgroundSecondary.main', minHeight: 'inherit' }}>
            <Box sx={{ width: '100%' }}>
                <Card className="banner_image"
                    sx={{
                        width: '100%',
                        borderRadius: 0,
                    }}>
                    <CardMedia
                        component="img"
                        image="/images/roundtables/top_banner.png"
                        alt="roundtables"
                    />
                </Card>
                {/* {myProfile ? myProfile.approved ?
                    myProfile.isNewUser ?
                        <Navigate to='/profile/me#edit' />
                        : */}
                        <Routes>
                            <Route path="/" element={<RoundTablesHome />} />
                            <Route path="/:meetingId" element={<RoundTablePage />} />
                            <Route path="/:meetingId/:action" element={<RoundTablePage />} />
                        </Routes>
                    {/* :
                    <Navigate to="/invite" replace={true} />
                    : 
                    <div>Loading.. Please refresh the page if content is not loaded in few seconds</div>
                    } */}

            </Box>
        </Box>
    );
};

export default RoundTablesRoot;