import { Box, Rating, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga';
import { displayAlertDialog } from '../../../components/AlertDialog';
import roundTableService from '../../../services/roundTablesService';

export const RateRoundTableView = ({ roundTable, attendees, myProfile, onClose, open }) => {
    let currntRating = 0;

    currntRating = roundTable.userActions.rating;
    const [rating, setRating] = useState(currntRating);
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
    }, []);

    const submitRating = (value) => {
        if (value) {
            setRating(value)
            const previousRating = currntRating
            if (value !== currntRating) {
                setIsSubmitting(true)
                roundTableService.rateRoundTable(roundTable.id, value)
                    .then(onClose)
                    .catch((e) => {
                        console.error(e)
                        displayAlertDialog({
                            title: "Something went wrong",
                            message: "Something went wrong while rate the roundtable. Pleaase refresh the page and try again"
                        })
                        setRating(previousRating)
                    }).finally(() => {
                        setIsSubmitting(false)
                    });
                return;
            }

            ReactGA.event({
                category: 'Button_Clicks',
                action: 'Submit_Roundtable_Rating',
                value: value,
                label: "roundtable_details"
            });
        }

    }

    // const getLabel = () => {
    //     switch (rating) {
    //         case 5:
    //             return 'Very Good';
    //         case 4:
    //             return 'Good';
    //         case 3:
    //             return 'Ok';
    //         case 2:
    //             return 'Bad';
    //         case 1:
    //             return 'Very Bad';
    //         default:
    //             return 'Not rated';
    //     }
    // }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }} >
            <Typography variant="subtitle1" align="left" fontWeight={600} >How was your Roundtable experience?</Typography>
            <Box pt={0.5}><Rating name="no-value"
                value={rating}
                onChange={(event, newValue) => {
                    //setRating(newValue);
                    submitRating(newValue)
                }}
                readOnly={isSubmitting}
                sx={{ fontSize: '2rem' }}
            /></Box>
            {isSubmitting && <Typography variant="subtitle2" align="left" color={"text.secondary"}>Submitting</Typography>}


        </Box>
    )
}

