import { Alert, Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CypeerLoading } from '../../../components/CypeerLoading';
import useScrollPosition from '../../../components/hooks/useScrollPosition';
import useWindowSize from '../../../components/hooks/useWindowSize';
import roundTablesService from '../../../services/roundTablesService';
import reduxStore from '../../../states/reduxStore';
import { updateAllReoundTables, updateHomeFilter, updateHomeScroll } from '../../../states/roundTableState';
import { reloadIfZoomCallElementsExists } from '../commonComponents';
import RoundTableListItem from './RoundTableListItem';
import { StartRTCard } from './StartRTCard';
import SecondaryList from './SecondaryList';

const RoundTableList = () => {
  const myProfile = useSelector(state => state.appState.myProfile)
  const filter = useSelector(state => state.roundTableState.homePageState.filter)
  const filterRefresh = useSelector(state => state.roundTableState.homePageState.filterRefresh)
  const searchQuery = useSelector(state => state.roundTableState.homePageState.searchQuery)
  const homePageState = useSelector(state => state.roundTableState.homePageState)
  const homePageScrollState = useSelector(state => state.roundTableState.homePageScrollState)
  //const roundTables = useSelector(state => state.roundTableState.allRoundTables)
  const [roundTables, setRoundTables] = useState([]);
  const [loadingRTs, setLoadingRTs] = useState(false);
  const [isAllLoaded, setAllLoaded] = useState(false);
  const [isRTLoadFailed, setRTLoadFailed] = useState(false);
  const [maxAvatarCount, setMaxAvatarCount] = useState(2);

  const windowSize = useWindowSize()
  const scrollP = useScrollPosition()
  const dispatch = useDispatch()


  const filterValidRoundtablesForExploreTab = (rts) => {
    let notcompletedRTs = []
    const filtered = rts.filter(rt => {
      const cs = roundTablesService.getCurrentState(rt, myProfile)
      if (cs.isClosed) {
        if (!cs.isNotHappened) {
          const now = moment();
          notcompletedRTs.push(rt)
        } else {
          //here means not happened
          const endTime = moment(rt.tableDateTime).add(rt.duration, 'm')
          const now = moment();
          if (now.diff(endTime, 'hours') > 1) {
            notcompletedRTs.push(rt)
          }
        }
        return false
      } else {
        return true
      }
    })
    if (notcompletedRTs.length) {
      const str = `Following Roundtables are not completed properly at [${moment().toISOString()}] : `
      console.warn(str, notcompletedRTs)
      ReactGA.exception(str + JSON.stringify(notcompletedRTs))
    }
    return filtered
  }

  const listRoundTables = async () => {
    if (loadingRTs) {
      console.warn("Calling 'listRoundTables()' while loadingRTs is true")
      return
    }
    // console.log("filterr: " + filter)
    const filterState = homePageState[filter]
    const pageSize = 10 //TODO: take this from config
    // const pageNumber = filterState.page
    let pageNumber = (filterState.page < 1) ? 1 : filterState.page//TODO: remove this hack after api bug is fixed and uncomment above

    let config
    let secondaryFilter
    if (filter === 'explore') {
      if (!filterState.isAllLoaded) {
        config = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          filter: 'Active',
          orderBy: 'Scheduled'
        }
        if(pageNumber < 2)roundTablesService.refreshMyRoundtableCount()
      } else {
        secondaryFilter = 'pastFeatured'
        const pastFeaturedState = filterState.secondaryFilters?.pastFeatured;
        pageNumber = pastFeaturedState?.page || 1
        config = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          filter: 'Past_Featured',
          orderBy: 'Scheduled_Desc'
        }

      }
    } else if (filter === 'featured') {
      // if (!filterState.isAllLoaded) {
        config = {
          pageSize: pageSize,
          pageNumber: pageNumber,
          filter: 'Past_Featured',
          orderBy: 'Scheduled_Desc'
        }
        if(pageNumber < 2)roundTablesService.refreshMyRoundtableCount()
      // } else {
      //   secondaryFilter = 'pastFeatured'
      //   const pastFeaturedState = filterState.secondaryFilters?.pastFeatured;
      //   pageNumber = pastFeaturedState?.page || 1
      //   config = {
      //     pageSize: pageSize,
      //     pageNumber: pageNumber,
      //     filter: 'Past_Featured',
      //     orderBy: 'Scheduled_Desc'
      //   }

      // }
    } else if (filter === 'my') {
      config = {
        pageSize: pageSize,
        pageNumber: pageNumber,
        filter: 'Participating',
        orderBy: 'Scheduled_Desc'
      }
      if(pageNumber < 2)roundTablesService.refreshMyRoundtableCount()
    } else if (filter === 'search') {
      config = {
        pageSize: pageSize,
        pageNumber: pageNumber,
        filter: 'All',
        orderBy: 'Created_Desc',
        keyword: searchQuery
      }
    }

    if (config) {
      try {
        setLoadingRTs(true)
        if (isAllLoaded) setAllLoaded(false)
        if (isRTLoadFailed) setRTLoadFailed(false)
        // console.log("Filter configs", config)
        let receivedRTs = await roundTablesService.getAllRoundTables(config)
        const isLastPage = receivedRTs.length < pageSize
        let allRts
        if (!secondaryFilter) {
          if (filter === "explore") {
            receivedRTs = filterValidRoundtablesForExploreTab(receivedRTs);
          }
          allRts = filterState.roundTables.concat(receivedRTs)
        } else {
          const currentList = filterState.secondaryFilters ? filterState.secondaryFilters[secondaryFilter]?.roundTables || [] : []
          allRts = currentList.concat(receivedRTs)
          console.log("Done loading", allRts)
        }
        const payload = {
          filter: filter,
          secondaryFilter: secondaryFilter,
          page: pageNumber + 1,
          roundTables: allRts,
          isAllLoaded: isLastPage
        }
        /*
         The order of below 2 lines are important to hanlde use effect properly in explore tab,
         when data have invalid roundtable to filter out. 
         */
        setLoadingRTs(false)
        reduxStore.dispatch(updateAllReoundTables(payload))
        roundTablesService.requestUpdateNextRtToLive(receivedRTs)
      } catch (e) {
        console.error(e)
        setLoadingRTs(false)
        setRTLoadFailed(true)
      }
    }
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  useEffect(() => {
    const newAC = Math.floor(windowSize.width / 100) - 2
    if (maxAvatarCount !== newAC) setMaxAvatarCount(newAC)
  }, [windowSize])

  //Lazy loading functionality
  useEffect(() => {
    // console.log("Scroll : " + windowSize.height + "," + scrollP + "," + document.body.scrollHeight + ",")
    let percentage = (scrollP + windowSize.height) * 100 / document.body.scrollHeight
    // console.log("Scroll : " + percentage + "%")
    if (percentage > 80 && !loadingRTs && !isAllLoaded && !isRTLoadFailed) {
      console.log("listRoundTables() scroll")
      listRoundTables()
    }
    reduxStore.dispatch(updateHomeScroll({ filter: filter, scroll: scrollP }))
  }, [scrollP, windowSize, loadingRTs, isRTLoadFailed])

  useEffect(() => {
    const hpState = homePageState[filter]
    const rts = hpState.roundTables
    let isAllLoadedLocal = hpState.isAllLoaded
    if (isAllLoadedLocal && filter === "explore") {
        if (!hpState.secondaryFilters?.pastFeatured?.isAllLoaded) isAllLoadedLocal = false
    }
    setRoundTables(rts)
    setAllLoaded(isAllLoadedLocal)
    
    // Below check length 2 to get atlest 2 rts if available to fill up the screen
    if (rts.length < 2 && !hpState.isAllLoaded) { 
        console.log("listRoundTables() filter refresh")
        listRoundTables()
        window.scroll(0, 0)
    } else {
      setTimeout(() => {
        console.log("restoring Scroll " + filter + ", " + homePageScrollState[filter])
        window.scroll(0, homePageScrollState[filter])
      }, 50);
    }
  }, [ filter, filterRefresh]);


  const AllLoadedView = () => {
    if (roundTables.length === 0) {
      if (filter === "search") {
        return <div>No search result found.</div>
      } else if (filter === 'my' || filter === 'explore') {
        return <Box display='flex' alignItems='center' flexDirection='column' gap={1}>
          <Box><img src='/images/roundtables/no_roundtables.png' alt='Zero My Roundtables' /></Box>
          <Typography>Roundtable is a 40 mins live video meeting with your peers who share the same interest.</Typography>
          <Box display='flex' gap={1}>
            <Button
              variant="outlined"
              onClick={() => dispatch(updateHomeFilter({ filter: 'new' }))}
            > Start a Roundtable </Button>
            {filter === 'my' &&
              <Button
                variant="outlined"
                onClick={() => dispatch(updateHomeFilter({ filter: 'explore' }))}
              > Explore and Join Roundtable </Button>
            }
          </Box>
        </Box>
      }
    }
    return <div></div>
  }


  let searchTxt = filter === "search" ? searchQuery : undefined
  reloadIfZoomCallElementsExists()
  return <div>
    {filter === "explore" && <StartRTCard />}
    {roundTables && roundTables.map((table) => (
      <RoundTableListItem roundTableProp={table} key={table.id} windowSize={windowSize} searchTxt={searchTxt} maxAvatarCount={maxAvatarCount} />
    ))
    }
    {isAllLoaded && !loadingRTs && <AllLoadedView />}
    
    {filter === "explore" && homePageState[filter].isAllLoaded && <SecondaryList title="Featured past events" listRoundTables={listRoundTables} maxAvatarCount={maxAvatarCount} loadingRTs={loadingRTs}/> }
    
    {loadingRTs && <CypeerLoading label={"Loading..."} my={2} />}
    {isRTLoadFailed && !isAllLoaded && !loadingRTs && <Alert
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={listRoundTables}>
          Retry
        </Button>
      }>Something went wrong while requesting Roundtables from the server.</Alert>}
  </div>;
};

export default RoundTableList;
