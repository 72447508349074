import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ReactGA from 'react-ga';
import { Box } from '@mui/material';

export default function TermsAndConditionsNotice({ prefixText, styles = {}, gaLabel }) {
    const onLinkClicked = (linkName) => {
        ReactGA.event({
            category: 'Link_Clicks',
            action: linkName,
            label: gaLabel
        });

    }
    return (
        <Box>
            <Typography variant="subText1" component="p" sx={styles}>
                {prefixText}, you agree to our &nbsp;
                <Link href="/terms-and-conditions"
                    underline="hover"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onLinkClicked("Terms_and_conditions")}>
                    terms and conditions
                </Link>
                , &nbsp;
                <Link href="/privacy-policy"
                    underline="hover"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onLinkClicked("Privacy_policy")}>
                    privacy policy
                </Link>
                &nbsp; and &nbsp;
                <Link href="/cookie-policy"
                    underline="hover"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onLinkClicked("Cookie_policy")}>
                    cookie policy
                </Link>
            </Typography>
            <Typography variant="subText1" component="p" sx={styles} py={1}>Cypeer will only have access to your name, email address and profile picture from LinkedIn.</Typography>
        </Box>
    );
};