import { AvatarGroup, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import getMatchingAvatarForProfile from '../../../services/avatarService'
import RoundTableService from '../../../services/roundTablesService'
import roundTablesService from '../../../services/roundTablesService'
import reduxStore from '../../../states/reduxStore'
import { updateRoundtableInLists, updateSelectedRoundTable } from '../../../states/roundTableState'
import { HostDetails, RoudnTableStatus, shareRoundtable, TopFlag, joinMeToRoundTable, updateParticipants as updateParticipantsGlobal, MainActionAlreadyJoined, MainActionReadyToConnect } from '../commonComponents'
import { sp1, sp3, sp4 } from '../../../configs/spacings'
import { useTheme } from '@emotion/react'
import { ShareDialog } from '../../../components/ShareDialog'
import { displayAlertDialog } from '../../../components/AlertDialog'

const RoundTableListItem = ({ roundTableProp, windowSize, searchTxt, maxAvatarCount }) => {
    const gaLabel = "roundtable_list_item"
    const [roundTable, setRoundTable] = useState(roundTableProp);
    const myProfile = useSelector(state => state.appState.myProfile)
    const liveReadyRtMap = useSelector(state => state.roundTableState.homePageState.readyRoundtablesToLive)
    const [shareDialogState, setShareDialogState] = useState({ open: false });
    const [participantsState, setParticipants] = useState([]);
    const [joinLoadingState, setJoinLoading] = useState(false)
    const [isLikedState, setisLiked] = useState({ isLiked: false, isLoading: false })
    const [rtAutoConnect, setRtAutoConnect] = useState(false)

    const navigate = useNavigate();
    const theme = useTheme();
    const onFetchRTProgress = (rt) => {
        setRoundTable(rt)
        reduxStore.dispatch(updateRoundtableInLists(rt))
    }

    const updateParticipants = (rawParticipants) => {
        updateParticipantsGlobal(rawParticipants, roundTable, setParticipants, maxAvatarCount)
    }

    useEffect(() => {
        const id = roundTableProp.id
        if (liveReadyRtMap[id]) {
            setRoundTable(liveReadyRtMap[id])
        }
    }, [liveReadyRtMap])

    useEffect(() => {
        if (roundTable && roundTable.participants) {
            let newCs = roundTablesService.getCurrentState(roundTable, myProfile)
            if (newCs) {
                setRoundTable({ ...roundTable, currentState: newCs })
                return
                // roundTable = {...roundTable, currentState: cs}
            }
            let cs = roundTable.currentState
            setisLiked({ isLiked: roundTable.userActions.liked, loading: false })

            if (roundTable.participants.length) {
                if (cs.isReadyToStart) {
                    let activeparticipants = roundTable.meetingProgress.attendance;
                    // console.log("Roundtable attendacne count: " + activeparticipants.length)

                    // if (cs.isLive && !cs.isClosed) { // this means meeting is live
                    //     activeparticipants = activeparticipants.filter(p => p.statusText === "Active")
                    // }
                    const activeMap = {}
                    const activeList = []
                    const inactiveList = []
                    //const notConnectedList = []

                    activeparticipants.forEach(participant => {
                        activeMap[participant.userId] = participant.statusText
                    });
                    let rtParticipants = roundTable.participants
                    rtParticipants.forEach(participant => {
                        let activeP = activeMap[participant.id]
                        if (activeP) {
                            participant = { ...participant, liveStatus: activeP }
                            if (activeP === "Active") {
                                activeList.push(participant)
                            } else {
                                inactiveList.push(participant)
                            }
                        }
                        // else {
                        //     participant = {...participant, liveStatus: "offline" }
                        //     notConnectedList.push(participant)
                        // }
                    });

                    updateParticipants(activeList.concat(inactiveList))//.concat(notConnectedList))

                } else if (cs.isEnded) {
                    let attended = roundTable.meetingProgress.attendance;

                    const attendedMap = {}
                    const attendedList = []

                    attended.forEach(participant => {
                        attendedMap[participant.userId] = participant.statusText
                    });
                    let rtParticipants = roundTable.participants
                    rtParticipants.forEach(participant => {
                        let a = attendedMap[participant.id]
                        if (a) {
                            attendedList.push(participant)
                        }
                    });

                    updateParticipants(attendedList)
                } else {
                    const ptps = roundTable.participants
                    const newOrder = []
                    for (let index = 1; index < ptps.length; index++) {
                        newOrder.push(ptps[index])
                    }
                    newOrder.push(ptps[0]) //host
                    updateParticipants(newOrder)
                }
            } else {
                //TO update host details ( of system hosted RTs)
                updateParticipants(roundTable.participants)
            }
        }
    }, [roundTable, myProfile]);

    const showProfileDialog = (profile) => {
        // setProfileDialogState(
        //     {
        //         profile: profile,
        //         open: true,
        //         onClose: () => {
        //             setProfileDialogState({ open: false })
        //         }
        //     }
        // );
    }


    const toggleLike = (e) => {
        e.stopPropagation();
        if (!isLikedState.isLoading) {
            let newState = !isLikedState.isLiked
            setisLiked({ ...isLikedState, isLiked: newState, isLoading: true })
            RoundTableService.updateLike(roundTable.id, newState).then(onFetchRTProgress).catch((e) => {
                console.error(e)
                setisLiked({ ...isLikedState, isLiked: !newState, isLoading: false })
                displayAlertDialog({
                    title: "Something went wrong",
                    message: "Oops! something went wrong on like button action. Pleaase refresh the page and try again"
                })
            })
            ReactGA.event({
                category: 'Button_Clicks',
                action: 'Like_Dislike_roundtable',
                label: gaLabel,
                isLiked: newState
            });
        }
    }

    const openTable = () => {
        reduxStore.dispatch(updateSelectedRoundTable(roundTable));
        //stateUtils.setGlobalState({ ...stateUtils.globalState, selectedTable: roundTable })
        ReactGA.event({
            category: 'List_Item_Clicks',
            action: 'Open_Roundtable_Details_Page',
            label: gaLabel
        });
        navigate(`${roundTable.id}`);
    }

    const getAvatars = (participants) => {
        let parr = participants;
        const l = parr.length;
        if (l > maxAvatarCount + 1) {
            parr = parr.slice(0, maxAvatarCount);
            parr.push({
                id: "+rest#",
                displayName: "+" + (l - maxAvatarCount)
            }
            )
        }
        return parr
    }

    // UIs
    const CookedTopic = () => {
        const ModifiedContentWithSearch = () => {
            let w = windowSize.width * 1000
            // w = w > 1200 ? 1200 : w //as rountable parent view not scale than 1200 TODO: take it from config
            let maxLength = Math.floor(w / 10)
            if (searchTxt) {
                let content, partialContent
                if (roundTable.tableTopic.length < maxLength) {
                    content = roundTable.tableTopic
                } else {
                    let t = roundTable.tableTopic.substring(0, maxLength)
                    let li = t.lastIndexOf(" ")
                    partialContent = `${t.substring(0, li)}`
                    content = partialContent
                }
                let lower = content.toLowerCase()
                let lastSearchIndex = 0
                let contents = []
                const stl = searchTxt.length
                const searchTxtLower = searchTxt.toLowerCase()
                for (let index = 0; index < lower.length; index++) {
                    let check = lower.indexOf(searchTxtLower, lastSearchIndex)
                    if (check >= 0) {
                        contents.push(<span>{content.substring(lastSearchIndex, check)}</span>)
                        contents.push(<span style={{ background: theme.palette.highlight.main }} >{content.substring(check, check + stl)}</span>) //TODO: styled span
                        lastSearchIndex = check + stl
                    } else {
                        contents.push(<span>{content.substring(lastSearchIndex, content.length)}</span>)
                        break
                    }
                }
                if (partialContent) {
                    if (contents.length > 1) {
                        contents.push(<span>...</span>)
                    } else {
                        contents.push(<span style={{ background: 'red' }} >...</span>) //TODO: styled span
                    }
                }
                return <> {contents.map((element) => (
                    element
                ))
                } </>

            } else {
                if (roundTable.tableTopic.length < maxLength) {
                    return roundTable.tableTopic
                } else {
                    let t = roundTable.tableTopic.substring(0, maxLength)
                    let li = t.lastIndexOf(" ")
                    let partialContent = `${t.substring(0, li)}...`
                    return partialContent
                }
            }
        }

        // let Content
        // if (roundTable.tableTopic.length < maxLength) {
        //     // return <Typography component="div" variant="h4" align="left" >
        //     //     {roundTable.tableTopic}
        //     // </Typography>
        //     Content = roundTable.tableTopic
        // } else {
        //     let t = roundTable.tableTopic.substring(0, maxLength)
        //     let li = t.lastIndexOf(" ")
        //     let partialContent = `${t.substring(0, li)}...`
        //     Content = partialContent
        //     // return <Typography component="div" variant="h4" align="left" id="demo-positioned-button">
        //     //     {partialContent}
        //     // </Typography>
        // }

        return <Typography component="div" variant="h4" align="left">
            {ModifiedContentWithSearch()}
        </Typography>
    }

    const Description = () => {
        if (roundTable.description) {
            return <Typography 
            component="div" 
            variant="subtitle1"
            color={"text.primary"}
            align="left"
            sx={{pt:1,pl:'1px',lineHeight:'1.3em'}}
            >
                {roundTable.description}
            </Typography>
        }
    }

    const MainAction = () => {
        let cs = roundTable.currentState;
        let amIin = cs.amIin;
        // Refer details page Main Action for old logic
        if (cs.isReadyToStart && amIin && cs.isApproved) {
                return <MainActionReadyToConnect roundTable={roundTable} gaLabel={gaLabel} autoConnect={rtAutoConnect}/>
        } else if (!cs.isClosed) {
            if (amIin) {
                return <MainActionAlreadyJoined roundTable={roundTable}/>
            } else if (roundTable.participants.length < roundTable.maxParticipants) {
                return <LoadingButton
                    onClick={e => { 
                        ReactGA.event({
                            category: 'Button_Clicks',
                            action: 'Join_Roundtable',
                            label: gaLabel
                        });
                        e.stopPropagation(); joinMeToRoundTable(roundTable, setJoinLoading, onFetchRTProgress, setRtAutoConnect) }}
                    loading={joinLoadingState}
                    loadingIndicator="Joining..."
                    variant="outlined"
                    sx={{ minWidth: '8em' }}
                >
                    Join
                </LoadingButton>
            } else {
                return <>
                    <Typography variant="subtitle2" fontWeight="600">
                        Session full
                    </Typography>
                </>
            }
        } else if (cs.isEnded) {
            return <>
                {/* as recording feature not implemented with current phase{(cs.isRecodingsReady)
                    ? <Button variant="outlined"
                        startIcon={<PlayCircleOutlineIcon />}
                        onClick={() => navigate('recording')}
                        sx={{ width: '100%' }} > Watch Now </Button>
                    : <div>Awiat for recording..</div>
                } */}
            </>
        }
        //TODO handle this well
        return <div></div>
    }

    const ParticipantDetails = () => {
        let cs = roundTable.currentState;
        const Comment = () => {
            let msg
            // if (cs.isEnded || (cs.isClosed && cs.isLive)) {
            //     msg = "Attended"
            // } else if (cs.isLive) {
            //     msg = "Attending"
            // } else 
            if (cs.isYetToStart) {
                if (roundTable.participants.length < roundTable.maxParticipants) {
                    let avaialbe = roundTable.maxParticipants - participantsState.length;
                    msg = `${avaialbe} slot${avaialbe > 1 ? "s " : ' '} left`
                }
                // else {
                //     msg = 'This session is full'
                // }
            }

            if (msg) {
                return <Typography variant="subtitle2" fontWeight="600" color={'primary.main'} pb={1}>
                    {msg}
                </Typography>
            }

        }

        return <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column-reverse', gap: 1.5, minWidth: '75px' }}>
            {Comment()}
            <AvatarGroup max={25} sx={{ ml: sp1 }}>
                {
                    getAvatars(participantsState).map((profile) => (
                        getMatchingAvatarForProfile(profile, "small", null, profile.liveStatus)
                    ))
                }

            </AvatarGroup>
        </Box>
    }


    //default child view
    return (
        <div>
            {
                roundTable && roundTable.currentState &&
                <div>
                    <Paper variant='outlined' sx={{
                        borderRadius: '0.8rem',
                        px: sp4,
                        pb: sp3,
                        mb: sp4,
                        cursor: 'pointer'
                    }}>
                        <Box onClick={openTable}>
                            {TopFlag(roundTable)}
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                {CookedTopic()}
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <IconButton
                                        color="primaryX"
                                        aria-label="share"
                                        component="span"
                                        size="small" sx={{ ml: sp1 }}
                                        disabled={shareDialogState.loading}
                                        onClick={(e) => { 
                                            ReactGA.event({
                                                category: 'Button_Clicks',
                                                action: 'Share_Roundtable',
                                                label: gaLabel
                                            });
                                            e.stopPropagation(); shareRoundtable(roundTable, setShareDialogState, onFetchRTProgress) }}
                                    >
                                        {shareDialogState.loading
                                            ? <CircularProgress size="1em" color='inherit' />
                                            : <ShareIcon />}
                                    </IconButton>
                                    <IconButton
                                        color={isLikedState.isLiked ? "primary" : "primaryX"}
                                        aria-label="like"
                                        component="span"
                                        size="small"
                                        sx={{ ml: sp1 }}
                                        onClick={toggleLike}
                                    >
                                        {isLikedState.isLiked
                                            ? <FavoriteRoundedIcon />
                                            : <FavoriteBorderIcon />
                                        }

                                    </IconButton>
                                </Box>
                            </Box>
                            {Description()}
                            <Grid container spacing={2} sx={{ pt: 0, pb: sp3, flexWrap: 'nowrap', alignItems: 'center' }}
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="space-between"
                            >
                                <Grid item sm={"auto"}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, justifyContent: 'space-between' }}>
                                        {HostDetails(roundTable, showProfileDialog)}
                                    </Box>
                                </Grid>
                                <Grid item sm={"auto"}>
                                    {/* {RatingView()} */}
                                    {ParticipantDetails()}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ pt: 0, pb: sp3, flexWrap: 'wrap' }}
                                direction="row" //{{xs : "column", sm: "row"}}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item sm={"auto"} sx={{ flexGrow: 1 }}>
                                    {RoudnTableStatus(roundTable)}
                                </Grid>
                                <Grid item sm={"auto"} sx={{ flexGrow: 1 }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end', //{ xs: 'center', sm: 'flex-end' },
                                        alignItems: 'center' // { xs: 'center', sm: 'center' }
                                    }}
                                    >
                                        {MainAction()}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </div>
            }
            {
                shareDialogState.open &&
                <ShareDialog open={shareDialogState.open}
                    onClose={shareDialogState.onClose}
                    shareContent={shareDialogState.shareContent}
                />
            }
        </div>
    );
}

export default RoundTableListItem;
