import { Box, Grid, Paper, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { sp3, sp4 } from "../../../configs/spacings"
import getMatchingAvatarForProfile from "../../../services/avatarService"
import { updateHomeFilter } from "../../../states/roundTableState"
import ReactGA from 'react-ga';

//icon set
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportIcon from '@mui/icons-material/Support';
import ForumIcon from '@mui/icons-material/Forum';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';


export const StartRTCard = () => {

    const myProfile = useSelector(state => state.appState.myProfile)

    const dispatch = useDispatch()

    const onClick = () => {
        const payload = {
            filter: 'new',
        }
        dispatch(updateHomeFilter(payload))
        ReactGA.event({
            category: 'Button_Clicks',
            action: 'Start_New_RoundTable',
            label: "roundtable_list_page"
        });
    }
    
    const RtTypeGridItem = ({icon, text}) => {
        return <Grid item xs={3} display="flex" justifyContent="center">
            <Box mt={1} display="flex" flexDirection="row" gap={1} alignItems="center">
                {icon}
                <Typography>{text}</Typography>
            </Box>
        </Grid>
    }

    return <Paper
        variant='outlined'
        sx={{
            borderRadius: '0.8rem',
            px: sp4,
            py: sp3,
            mb: sp4,
            cursor: 'pointer'
        }}
        onClick={onClick}
    >
        {/* <Box display="flex" gap={1}>
            {getMatchingAvatarForProfile(myProfile, "medium")}
            <Typography variant="subtitle1" sx={{
                width: '100%',
                cursor: 'pointer',
                borderRadius: 6,
                padding: 2,
                fontWeight: 600,
                borderStyle: 'solid',
                borderWidth: 'thin'
            }}>
                Start a discussion
            </Typography>
        </Box> */}
        <Grid container spacing={1}>
            <Grid item >
                {getMatchingAvatarForProfile(myProfile, "medium")}
            </Grid>
            <Grid item xs>
                <Typography variant="subtitle1" sx={{
                    width: '100%',
                    cursor: 'pointer',
                    borderRadius: 6,
                    padding: 2,
                    fontWeight: 600,
                    borderStyle: 'solid',
                    borderWidth: 'thin'
                }}>
                    Start a discussion
                </Typography>
            </Grid>
            <Grid item container spacing={1} xs={12}>
                <RtTypeGridItem icon={<ContactSupportIcon />} text="Ask Me Anything"/>
                <RtTypeGridItem icon={<SupportIcon/>} text="Help Me Resolve"/>
                <RtTypeGridItem icon={<ForumIcon/>} text="Open Discussion"/>
                <RtTypeGridItem icon={<RecordVoiceOverIcon/>} text="Share an experience"/>
            </Grid>
        </Grid>
    </Paper>
}