import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import Logo from './CypeerLogo';

export const CypeerLoading = ({ label, my }) => {
    return (
        <Box sx={{ my: my || 1.5, display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{mt:0.5, height:40}}>
                    <Logo />
                </Box>
                <LinearProgress color="primary" sx={{ height: '2px', width: '200px', my:1}} />
                <Typography sx={{pr:2}}>
                    {label}
                </Typography>
        </Box>
    );
}
