import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RTBackdropPage from '../../components/RTBackdropPage'
import RTPaper from '../../components/RTPaper'
import ReactGA from 'react-ga';
import publicService from "../../services/publicService";
import authService from "../../services/authService";
import TermsAndConditionsNotice from "../../components/TermsAndConditionsNote";
import { formContent } from "./content";

export default function SignUpForm() {

    const [countries, setCountries] = useState({data:[], loading:true});
    const [roles, setRoles] = useState({data:[], loading:true});
    const [industries, setIndustries] = useState({data:[], loading:true});

    const [submitionLoading, setSubmitionLoading] = useState(false);
    const [formData, setformData] = useState({
        country: null,
        organization: '',
        role: null,
        industry: null,
    });
    const navigate = useNavigate();
    const gaLabel = "SignUpPage"

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);

        getCountries();
        getRoles();
        getIndustries();

    }, [])

    function getCountries(){
        publicService.getMasterData("Country").then((data) => {
            if(data){
                setCountries({
                    data: data,
                    loading: false
                })
            }else{
                setCountries({...countries, loading: false});
            }
        });
    }

    function getRoles(){
        publicService.getMasterData("Role").then((data) => {
            if(data){
                setRoles({
                    data: data,
                    loading: false
                })
            }else{
                setRoles({...countries, loading: false});
            }
        });
    }

    function getIndustries(){
        publicService.getMasterData("Industry").then((data) => {
            if(data){
                setIndustries({
                    data: data,
                    loading: false
                })
            }else{
                setIndustries({...countries, loading: false});
            }
        });
    }

    const handleCountrySelect = function (event, newValue) {
        setformData({ ...formData, country: newValue });
    };

    const handleOrganizationChange = function (event) {
        setformData({ ...formData, organization: event.target.value });
    };

    const handleRoleSelect = function (event, newValue) {
        setformData({ ...formData, role: newValue });
    };

    const handleIndustrySelect = function (event, newValue) {
        setformData({ ...formData, industry: newValue });
    };
  
    const handleFormKeyDown = function (event) {
        if (event.key === 'Enter' && event.shiftKey === false) {
            event.preventDefault();
        }
    };

    const handleSubmit = function (event) {
        event.preventDefault();

        setSubmitionLoading(true);

        var signUpInfo = {
            "country": formData.country ? formData.country.id : undefined,
            "organization": formData.organization,
            "role": formData.role ? formData.role.id : undefined,
            "industry": formData.industry ? formData.industry.id : undefined,
        }

        localStorage.setItem('signUpInfo', JSON.stringify(signUpInfo));

        authService.signIn(gaLabel);
    };

    function cancel() {
        ReactGA.event({
            category: 'Button_Clicks',
            action: 'SignUp_Form_Cancel',
            label: gaLabel
        });

        navigate('/home', { replace: true });
    };

    return (
        <RTBackdropPage>
            <RTPaper>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="stretch"
                    justifycontent="center"
                // border={1}
                >
                    <form className='SignUpForm' onSubmit={handleSubmit} onKeyDown={handleFormKeyDown}>
                        <Grid className='section_1_mandatory_info' container
                            sx={{
                                mb: { xs: 2, }
                            }}
                        >
                            <Grid item className='title' xs={12} sx={{ my: { xs: 1 } }}>
                                <Typography variant="h4">
                                    {formContent.mandatoryInfoTitle}
                                </Typography>
                            </Grid>
                            <Grid item className='subtitle' xs={12} sx={{ my: { xs: 1 } }}>
                                <Typography variant="h5">
                                    {formContent.mandatoryInfoSubtitle}
                                </Typography>
                            </Grid>

                            <Grid item className='organization' xs={12} sm={6} xl={3}
                                sx={{
                                    pt: { xs: 2 },
                                    pr: { sm: 1 },
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField id="organizationInput" label={formContent.orgNameLabel} type="text" value={formData.organization} onChange={handleOrganizationChange} required />
                                </FormControl>
                            </Grid>
                            <Grid item className='industry' xs={12} sm={6} xl={3}
                                sx={{
                                    pt: { xs: 2 },
                                    pr: { xl: 1 },
                                }}
                            >
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        openOnFocus
                                        blurOnSelect
                                        id="industrySelect"
                                        value={formData.industry}
                                        onChange={handleIndustrySelect}
                                        noOptionsText={countries.loading ? 'Loading...': 'No Data'}
                                        getOptionLabel={(option) => option.value}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        options={industries.data}
                                        renderInput={(params) => <TextField {...params} label={formContent.industryLabel} required />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item className='role' xs={12} sm={6} xl={3}
                                sx={{
                                    pt: { xs: 2 },
                                    pr: { sm: 1 },
                                }}
                            >
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        openOnFocus
                                        blurOnSelect
                                        id="roleSelect"
                                        value={formData.role}
                                        onChange={handleRoleSelect}
                                        noOptionsText={countries.loading ? 'Loading...': 'No Data'}
                                        getOptionLabel={(option) => option.value}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        options={roles.data}
                                        renderInput={(params) => <TextField {...params} label={formContent.roleLabel} required />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item className='country' xs={12} sm={6} xl={3}
                                sx={{
                                    pt: { xs: 2 }
                                }}
                            >
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        openOnFocus
                                        blurOnSelect
                                        id="countrySelect"
                                        value={formData.country}
                                        onChange={handleCountrySelect}
                                        noOptionsText={countries.loading ? 'Loading...': 'No Data'}
                                        getOptionLabel={(option) => option.value}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        options={countries.data}
                                        renderInput={(params) => <TextField {...params} label={formContent.countryLabel} required />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid className='section_4_submit' container
                            sx={{
                                mt: { xs: 3, md: 6 },
                                // border: 1,
                            }}
                        >
                            <Grid className='t_and_c' item xs={12} textAlign={{ xs: 'center', sm: 'right' }} pb={1}>
                                <TermsAndConditionsNotice prefixText={'By signing up'} gaLabel={gaLabel}/>
                            </Grid>
                            <Grid className='buttons' item xs={12}
                                container
                                justifyContent='flex-end'
                                alignItems='center'
                            // border={1}
                            >
                                <Grid item container xs={12} sm={10} md={8} lg={6} xl={5}
                                    justifyContent='center'
                                    alignItems='center'
                                // width={{ sm: 'fit-content' }}
                                // border={1}
                                >
                                    <Grid item container xs={12}
                                        direction="row-reverse"
                                        justifyContent='flex-end'
                                    // border={1}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <Button className="button_submit" type="submit" variant="contained" size="small"
                                                disabled={submitionLoading}
                                                startIcon={<LinkedInIcon />}
                                                sx={{
                                                    width: '-webkit-fill-available',
                                                    minWidth: 'max-content',
                                                    ml: { sm: 1 },
                                                    px: { sm: 5 },
                                                    py: 1,
                                                }}
                                            >
                                                {formContent.submitButtonText}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} width={{ sm: 'fit-content' }}>
                                            <Button className="button_cancel" variant="outlined" size="small"
                                                onClick={cancel}
                                                disabled={submitionLoading}
                                                sx={{
                                                    width: '-webkit-fill-available',
                                                    mt: { xs: 1, sm: 0 },
                                                    px: { sm: 6 },
                                                    py: 1,
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {
                                        submitionLoading &&
                                        (
                                            <Box className="loading_animation" sx={{
                                                position: 'absolute',
                                                zIndex: 2,
                                            }}>
                                                <CircularProgress size={32} color="success" />
                                            </Box>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </RTPaper>
        </RTBackdropPage>
    );
}