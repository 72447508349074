import React, { useEffect, useState } from 'react';
import useWindowFocus from 'use-window-focus';
import moment from 'moment';

export default function Interval({ intervalInSecs, action, startTime }) {
  
  const [percentage, setPercentage] = useState(0);
  const [doAction, setDoAction] = useState(0);
  const [isIntervalMode, setIsIntervalMode] = useState(false); //This component have two modes Remaining mode and interval mode
  const [intervalObj, setInvervalObj] = useState({ obj: null, intervalTime: 5000})
  
  const windowFocused = useWindowFocus();
  const progressStepCount = 10

  function resetInterval()  {
    // resetting interval again and again is needed only at in 'Remaining' mode
    if (!isIntervalMode) { //If current Interval is in already intervalMode, nothing to do
      let remainingMode = false
      let calcInt = 5000 //defauilt interval
      if (startTime) {
        const now = moment();
        const remaining = -now.diff(startTime)
        if (remaining > 0) {
          remainingMode = true;
          calcInt = remaining / progressStepCount
          //console.log("Set inverval for 'Remaining' Mode with interval: ", calcInt)
        }
      }
      if (!remainingMode) {
        setIsIntervalMode(true)
        calcInt = intervalInSecs * 1000 / progressStepCount 
        //console.log("Set inverval for 'Interval' Mode with interval: ", calcInt)
      }

      if (intervalObj.obj) {
        console.debug("Cleared previous interval on reset")
        clearInterval(intervalObj.obj)
      }
      updateDisplay();
      const interval = setInterval(() => {
        updateDisplay();
      }, calcInt);
      //setInvervalObj(interval)
      intervalObj.intervalTime = calcInt
      intervalObj.obj = interval
    }
    // debug else
    // else {
    //   console.info("No need to reset interval as this is already in Interval Mode!")
    // }
  }

  useEffect(() => {
    return () => {
      //Stop current interval when view destroyed
      console.debug("Cleared previous interval on on usereffect destroy")
      clearInterval(intervalObj.obj)
    }
  }, []);

  useEffect(() => {
    if (windowFocused) {
      resetInterval()
    }
  }, [windowFocused])

  useEffect(() => {
    // console.log('#@# use effect do Action  calld!');
    if (doAction > 0) {
      console.info("Executing interval action..")
      action();
      resetInterval() //This will change the mode from Remaining mode to Interval Mode if needed.
    }
  }, [doAction]);

  let counter = 0;
  let newd = doAction
  const updateDisplay = () => { 
    /* This method will be hardly binded inside interval execution
       hence global variable changes after bind may not impacted some times.
       Ex: even 'counter = 0' globally local counter++ is not impacted from it.
    */
    let percentage = counter++ * 100 / progressStepCount
    // debug log console.log(`######window focused:  ${windowFocused}, counter: ${counter}, percentage: ${percentage}%`)
    setPercentage(percentage)
    if (percentage >= 100) {
      counter = 0;
      console.debug("Interval doAction repeact count :" + ++newd);
      setDoAction(newd); //Just updating state from here and required dynamic logic is doing by useeffect as this metho not delaing properly with outside dynamic data
    }
  }

  return <div
    style={{ position: 'fixed', left: 0, bottom: 0, height: 2, width: '100%' }}>

    <div style={{ width: percentage + '%', backgroundColor: '#85848382', height: 2, transition: '1s' }}></div>
  </div>;
}
