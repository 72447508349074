let formContent = {
    'mandatoryInfoTitle': 'Join Cypeer',
    'mandatoryInfoSubtitle': 'This information will help you find relevant connections and content.',
    'countryLabel': 'Location',
    'orgNameLabel': 'Your Company Name',
    'roleLabel': 'Your Role',
    'industryLabel': 'Industry',
    'interestsTitle': 'What are you interested in?',
    'interestsSubtitle': 'Choose 5 topics from the list or add your own. Your interests will help you find peers with similar interests to collaborate.',
    'nominationsTitle': 'Nominate up to three colleagues you would like to invite to Cypeer',
    'submitButtonText': 'Sign up with LinkedIn',
    'emptyInterestsError': ' Please select at least one topic of interest'
}

export { formContent };