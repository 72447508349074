import { EventType, PublicClientApplication } from '@azure/msal-browser';
import jwt from 'jwt-decode'
import moment from 'moment'
import { loginRequest, msalConfig } from '../configs/authConfig';
import profileService from './profileService';
import { updateNomination } from './userService';
import ReactGA from 'react-ga';
import reduxStore from '../states/reduxStore';
import { setIsValidAuthToken, updateProfile } from '../states/appState';

export const msalInstance = new PublicClientApplication(msalConfig);

class authService {
    #authToken;
    #exp;
    #accessTokenRequestAsync;

    async refreshToken(isManualRequest) {
        if (!this.#accessTokenRequestAsync) {
            this.#accessTokenRequestAsync = this.#refreshToken(isManualRequest)
        } else {
            console.log("Concurrent refresh token request: Binding to ongoing refresh token request.")
        }
        try {
            let response = await this.#accessTokenRequestAsync
            this.#accessTokenRequestAsync = null
            return response
        } finally {
            this.#accessTokenRequestAsync = null
        }
    }

    async #refreshToken(isManualRequest) {
        // alert("REFRESH TOKEN CALLED.")
        const redirectResponse = await msalInstance.handleRedirectPromise();
        if (redirectResponse !== null && !isManualRequest) {
            // console.log("Redirect Response handler ");
            // Acquire token silent success
            let accessToken = redirectResponse.accessToken;
            // Call your API with token
            try {
                await this.setToken(accessToken);
                return accessToken
            } catch (e) {
                console.warn("Set token of redirect response handler failed", e)
            }
        }
        let account = msalInstance.getAllAccounts()[0]
        if (account) {
            const accessTokenRequest = {
                ...loginRequest,
                account: account
            }
            // console.log("accessTokenRequest :", accessTokenRequest);
            try {
                // console.log('acquireTokenSilent')

                // mocked error for testing
                // let e = new Error("Custom Error")
                // e.name = "InteractionRequiredAuthError"
                // throw e

                let accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest)
                let accessToken = accessTokenResponse.accessToken;
                await this.setToken(accessToken);
                return accessToken
            } catch (error) {
                if (isManualRequest) {
                    await this.signIn("AquireTokenSilentError");
                    //TODO:  remove above line and uncomment below line if sso not worked after auth implementation beyond LinkedIn
                    // if (error.name === "InteractionRequiredAuthError") {
                    //     console.info('! InteractionRequiredAuthError: try acquireTokenRedirect')
                    //     await msalInstance.acquireTokenRedirect(accessTokenRequest)
                    // } else {
                    //     await this.signIn("AquireTokenSilentError");
                    // }
                } else {
                    reduxStore.dispatch(setIsValidAuthToken(this.#isNotExpired(this.#exp)))
                    console.error("Refresh Token Failed with Error: ", error)
                    throw new Error("Authentication requried")
                }
            }
        } else {
            console.warn("MSAL account not fuound. Hence redirect to login")
            await this.signIn("AccountNotFound");
        }
    }

    isLoggedIn() {
        //To minimize exposing token to every where not returing auth token as is here
        if (this.#isNotExpired(this.#exp)) {
            if (reduxStore.getState().appState.myProfile) {
                return true;
            }
        }
        return false;
    }

    async setToken(newToken) {
        const dec = jwt(newToken)
        const exp = moment(dec.exp * 1000)
        if (!this.#isNotExpired(exp)) {
            var e = new Error("The token is expired or expire soon (before 30 secs)")
            e.name = "authError"
            throw e
        }
        this.#exp = exp
        this.#authToken = newToken
        const tempProfile = {
            "displayName": dec.name,
            "isNewUser": false,
            "emailVerified": true,
            "isTempProfile": true,
        }
        reduxStore.dispatch(updateProfile(tempProfile));
        reduxStore.dispatch(setIsValidAuthToken(true))
        profileService.getMyProfile();        
        // window.localStorage.setItem('tkn', newToken);
        return true;
    }

    async tryAuthFromLocal() {
        let token = window.localStorage.getItem('tkn')
        if (token) {
            // console.log("TOKEN FOUND IN LOCAL")
            await this.authenticate(token)
        }
        throw new Error({ name: "authError", message: "Token not found in local" })
    }

    async getToken() {
        if (!this.#isNotExpired(this.#exp)) {
            return await this.refreshToken()
        }
        return this.#authToken
    }

    #isNotExpired(checkTime) {
        const now = moment().add(30, 'seconds')
        if (checkTime) {
            return now.isBefore(checkTime)
        }
        return false;
    }

    #updateToken(newToken) {
        this.#authToken = newToken
    }

    signOut(includeLinkedIn) {
        
        if (includeLinkedIn) {
            window.open("https://www.linkedin.com/m/logout", "_blank")
        }
        localStorage.setItem("logout", Date.now())
        msalInstance.logoutRedirect()
            .catch(e => {
                console.error(e);
            });
    }

    async signIn(GAlabel) {

        ReactGA.event({
            category: 'Button_Clicks',
            action: 'signIn',
            label: GAlabel,
        });


        await msalInstance.loginRedirect(loginRequest)
            .catch(e => {
                console.error(e);
                ReactGA.event({
                    category: 'Cancellation',
                    action: 'signIn',
                    label: GAlabel,
                });
            });
    }


    callbackId = msalInstance.addEventCallback(async (message) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
            let nomination = localStorage.getItem('nomination')
            if (nomination) {
                updateNomination(nomination).then(() => {
                    localStorage.removeItem('nomination');
                });
            }
        }
    });
}

//this is for logout page refresh handling.
window.addEventListener("storage", (event) => {
    if (event.key === "logout") {
        window.location.reload()
    }
});

export default new authService();
