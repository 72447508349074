import { accordionActionsClasses, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { updateAlertStatus } from "../states/appState"
import reduxStore from "../states/reduxStore"


let yesAction, noAction

export const AlertDialog = () => {
    const alertState = useSelector(state => state.appState.alertState)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(updateAlertStatus({open: false}))
    };

    
    const handleYesAction = () => {
        handleClose()
        if (yesAction) yesAction()
    };

    const handleNoAction = () => {
        handleClose()
        if (noAction) noAction()
    };

    return (
        <Dialog
            open={alertState.open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="subtitle1" component="span" lineHeight="1" fontWeight="600">
                    {alertState.title}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{minWidth: 240}}>
                <DialogContentText id="alert-dialog-description">
                    {alertState.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{px:2, pb: 2}}>
                {   alertState.isYesNo &&
                    <Button onClick={handleNoAction} variant="outlined">
                        {alertState.noButtonText}
                    </Button>
                }
                <Button onClick={handleYesAction} autoFocus variant="contained">
                    {alertState.yesButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export function displayAlertDialog(payload) {
    /* samplePayload:required 
        payload= {
            title: "<String,optional, default: ''>",
            message: "<String,optional, default: ''>",
            yesButtonText: "<String,optional, default: 'OK'>",
            yesButtonAction: "<Function,optional, default: undefined>",
        } 
    */
    let newState = {
        ...payload,
        open: true,
        isYesNo: false
    }
    yesAction = payload.yesButtonAction || undefined
    noAction = undefined
    reduxStore.dispatch(updateAlertStatus(newState))
}


export function displayYesNoDialog(payload) {
    /* samplePayload:required 
        payload= {
            title: "<String,optional, default: ''>",
            message: "<String,optional, default: ''>",
            yesButtonText: "<String,optional, default: 'OK'>",
            noButtonText: "<String,optional, default: 'Cancel'>",
            yesButtonAction: "<Function,optional, default: undefined>",
            noButtonAction: "<Function,optional, default: undefined>",
        } 
    */
    let newState = {
        ...payload,
        open: true,
        isYesNo: true
    }
    yesAction = payload.yesButtonAction || undefined
    noAction = payload.noButtonAction || undefined
    reduxStore.dispatch(updateAlertStatus(newState))
}