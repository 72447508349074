import { useState, useEffect } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import profileService from "../../services/profileService";
import ProfileCardSmall from "../../components/ProfileCardSmall";

export default function Followers({ type }) {

    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProfiles();
    }, []);

    async function getProfiles() {

        if (type === 'following') {
            setProfiles(await profileService.getFollowingProfiles('me'));
        } else {
            setProfiles(await profileService.getFollowersProfiles('me'));
        }
        setLoading(false);
    };

    return (
        <Grid container direction='column' className={`followers_${type}`}
            sx={{
                pt: { xs: 2 },
                minHeight: '60vh',
                // border: 1,
            }}>
            {
                loading ?
                    <CircularProgress />
                    :
                    profiles?.length === 0 ?
                        <Typography>
                            {type === 'following' ? "You are not following anyone yet..." : "You do not have anyone following you yet..."}
                        </Typography>
                        :
                        profiles.map((profile) => (
                            <Grid item key={profile.id}
                                sx={{
                                    // border: 1,
                                }}
                            >
                                <ProfileCardSmall profile={profile} />
                            </Grid>
                        ))
            }
        </Grid>
    );
}
