import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import reduxStore from './states/reduxStore';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Flash } from "./pages/flash/Flash";
import VerifiedEmail from './pages/invites/VerifiedEmail';
import { TermsAndConditions } from './pages/policies/termsAndConditions';
import PrivacyPolicy from './pages/policies/privacyPolicy';
import CookiePolicy from './pages/policies/cookiePolicy';

let zoomroot = document.getElementById("zmmtg-root")
if(zoomroot){
  zoomroot.style.display = 'none'
}

const trackingId = window.GA_TRACKING_ID;
ReactGA.initialize(trackingId);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <BrowserRouter>
        <Routes>
          {/* <Route exact path="/auth" element={<div></div>} /> */}
          <Route path="/*" element={<App />} />
          <Route exact path="/flash" element={<Flash />} />
          <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/cookie-policy" element={<CookiePolicy />} />
          <Route exact path="verify/:ref/:id" element={<VerifiedEmail />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
