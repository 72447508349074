import { Box, Button, Checkbox, Dialog, Grid, Rating, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import authService from '../services/authService';

export const LogOutDialog = ({ myProfile, onClose, open }) => {
    let currntRating = 0;

    const [linkedIn, setLinkedIn] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
    }, []);
    const onCancelDialog = () => {
        if (!isSubmitting) {
            onClose()
        }
    }
    const toggleLinkedIn = () => {
        setLinkedIn(!linkedIn) //TODO replace alert
    }

    const logOut = () => {
        setIsSubmitting(true)
        authService.signOut(linkedIn)
    }

    //common button attributes
    const cba = {
        sx: { width: '100%' },
    }
    //common grid item attributes
    const cgia = {
        xs: 6
    }

    return (
        open ?
            <Dialog onClose={onCancelDialog} open={open}>
                <Box sx={{ p: 2 }} >
                    <Typography variant="subtitle1" align="left" fontWeight={600} mb={2}>Sign out from Cypeer?</Typography>
                    <Grid container spacing={2} sx={{ maxWidth: '300px', alignItems: 'stretch' }}
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} >
                            <Box
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                onClick={toggleLinkedIn}
                            >
                                <Checkbox
                                    checked={linkedIn}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    size="large"
                                />
                                <Typography variant="subtitle1" align="left" fontWeight={600} >
                                    Sign out from LinkedIn as well 
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="subtitle2" align="left" color={linkedIn? "text.primary" : "error"}>
                                    {linkedIn
                                        ? 'Sign out from Cypeer and LinkedIn.'
                                        : 'We recommend you sign out from LinkedIn as well if you are on a public computer.'
                                    }

                                </Typography>
                            </Box>

                        </Grid>
                        <Grid item  {...cgia}>
                            <Button
                                sx={{ minWidth: '8em', width: '100%' }} variant="outlined" onClick={onCancelDialog} disabled={isSubmitting}>Cancel</Button>
                        </Grid>
                        <Grid item   {...cgia}>
                            <LoadingButton {...cba}
                                onClick={logOut}
                                loading={isSubmitting}
                                loadingIndicator="Wait.."
                                variant="contained"
                                sx={{ minWidth: '8em', width: '100%' }}
                            >
                                Sign Out
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog> :
            <></>
    )
}

