import { Box, Button, Dialog, Divider, Input, Typography } from '@mui/material'
import React, { useState } from 'react'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { sp4, sp3, sp1 } from '../configs/spacings'

export const ShareDialog = ({ shareContent, onClose, open }) => {
    //sample shareContent { dialogTitle, title, description, defaultMessage, shareUrl}
    const [linkCopied, setLinkCopied] = useState(false)
    // https://www.npmjs.com/package/react-share
    const metaData = shareContent.shareMeta
    const finalUrl = shareContent.shareUrl + "?share=" + encodeURI(JSON.stringify(metaData))
    return (
        open ?
            <Dialog onClose={onClose} open={open} >
                <Box sx={{ padding: sp4, minWidth: {xs:250, sm: 400}}}>
                    <Typography variant='h4'>{shareContent.dialogTitle}</Typography>
                    <Typography variant='subtitle1' py={sp3}>{shareContent.title}</Typography>
                    <Divider/>
                    <Typography variant='subtitle2' align='center' py={sp1}>Share in</Typography>
                    <Box sx={{ display: 'flex', gap: sp3, justifyContent: 'center', py: 1 }}>
                        <LinkedinShareButton
                            url={finalUrl}
                            title={shareContent.title}
                            source={finalUrl}
                            summary={shareContent.description}
                        ><LinkedinIcon size={32} round></LinkedinIcon ></LinkedinShareButton>
                        <TwitterShareButton
                            url={finalUrl}
                            title={shareContent.title}
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={finalUrl}
                            title={shareContent.title}
                            separator=":: "
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <FacebookShareButton
                            url={finalUrl}
                            quote={shareContent.title}
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <EmailShareButton
                            url={finalUrl}
                            subject={shareContent.title}
                            body={shareContent.description}
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', gap: sp1, alignItems: 'center', pt: sp3, flexDirection: 'row' }}>
                        <Box flexGrow={1}>
                        <Input disabled defaultValue={finalUrl} sx={{width:'100%'}}/>
                        {/* <Typography variant='subtitle1'> {finalUrl} </Typography> */}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 80 }}>
                        {
                            linkCopied 
                            ? <Typography variant='subtitle1'> Copied </Typography>
                            : <Button onClick={() => {
                                navigator.clipboard.writeText(finalUrl)
                                setLinkCopied(true)
                            }} >Copy Link</Button>
                        }
                        </Box>
                        
                        
                    </Box>
                </Box>
            </Dialog> :
            <></>
    )
}
