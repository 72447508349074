import { LoadingButton } from '@mui/lab'
import { Box, Card, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga';
import getMatchingAvatarForProfile from '../services/avatarService'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import profileService from '../services/profileService';
import { displayAlertDialog, displayYesNoDialog } from './AlertDialog';
import { useSelector } from 'react-redux';

function ProfileCardSmall({ profile }) {
    const [profileState, setProfile] = useState(profile)
    const myProfile = useSelector(state => state.appState.myProfile)
    const [followingProgress, setFollowingProgress] = useState(false)
    const [onUnfollow, setOnUnfollow] = useState(false)

    useEffect(() => {
        if (profile) {
            setProfile(profile)
        }
    }, [profile])

    const follow = async (isFollow) => {
        setFollowingProgress(true)
        try {
            let newProfile = await profileService.followProfile(profileState.id, isFollow)
            console.log(newProfile)
            setProfile(newProfile)
        } catch (error) {
            displayAlertDialog({
                title: `Oops Something went wrong`,
                message: `Unexpected error happened while ${isFollow ? 'following' : 'unfollowing'} ${profile.displayName}. Please refresh the page and try again.`
            })
        } finally {
            setFollowingProgress(false)
        }

        ReactGA.event({
            category: 'Button_Clicks',
            action: isFollow ? 'Follow_profile' : 'Unfollow_profile',
            label: "undefined"
        });
    }


    const unFollow = async () => {
        displayYesNoDialog({
            title: `Unfollow ${profile.displayName} ?`,
            message: "Their activity notification will no longer be sent to you",
            yesButtonText: "Unfollow",
            noButtonText: "Cancel",
            yesButtonAction: () => follow(false)
        })
    }

    return (
        <Card
            elevation={0}
            sx={{ display: 'flex', py: 1, height: '100%' }}
        // onClick={() => { showProfileDialog(profile) }}
        >
            {getMatchingAvatarForProfile(profileState, "large", null, profileState.liveStatus)}
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, flexGrow: 1 }}>
                <Typography variant="subtitle1" lineHeight="1" fontWeight="600">
                    {profileState.displayName}
                </Typography>
                {(profileState.role && profileState.role !== "Other") && <Typography variant="body1" color="text.secondary" component="div" fontWeight="500">
                    {profileState.role}
                </Typography>}
                {profileState.organization && <Typography variant="subtitle2" color="text.secondary" component="div" fontWeight="500">
                    {profileState.organization}
                </Typography>}
            </Box>
            {myProfile.id !== profileState.id && profileState.hasOwnProperty('isFollowed') && <Box sx={{ alignSelf: 'center', ml: 1 }}>
                {profileState.isFollowed
                    ? onUnfollow
                        ? <LoadingButton variant="outlined"
                            color="error"
                            size='small'
                            onMouseLeave={() => setOnUnfollow(false)}
                            onClick={unFollow}
                            loading={followingProgress}
                            loadingIndicator="wait..."
                            sx={{ minWidth: 80 }}
                        >
                            UnFollow
                        </LoadingButton>
                        : <LoadingButton variant="outlined"
                            color="primaryX"
                            size='small'
                            onMouseEnter={() => setOnUnfollow(true)}
                            onClick={unFollow}
                            loading={followingProgress}
                            loadingIndicator="wait..."
                            sx={{ minWidth: 80 }}
                        >
                            Following
                        </LoadingButton>
                    : <LoadingButton variant="contained"
                        size='small'
                        onClick={() => follow(true)}
                        loading={followingProgress}
                        loadingIndicator="wait..."
                        sx={{ minWidth: 80 }}
                    >
                        Follow
                    </LoadingButton>

                }
            </Box>
            }

        </Card>
    )
}

export default ProfileCardSmall