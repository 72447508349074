import httpSupportService from "./httpSupprotService";
import configs from "../configs/configs";
import { displayAlertDialog } from "../components/AlertDialog";

export class PublicService {

    async getPublicUserProfile(shareRef) {
        let getConfig = httpSupportService.getPublicGetConfigs();
        let url = `${configs.APIM.BASE_URL}${configs.APIM.PUBLIC_BASE_PATH}/profile/${shareRef}`;

        try {
            let response = await httpSupportService.doRequest(url, getConfig);
            return (await response.json()).result;
        } catch (error) {
            if (error.response.status === 429) {
                displayAlertDialog({
                    title: "Too Many Requests",
                    message: "You have made too many requests for this page. Please try refreshing again after a minute"
                })
            }
            console.error(error);
        }
    }

    async putShareRefHit(shareRef) {
        let putConfig = httpSupportService.getPublicPutConfigs();
        let url = `${configs.APIM.BASE_URL}${configs.APIM.PUBLIC_BASE_PATH}/profile/reference/${shareRef}`;

        try {
            httpSupportService.doRequest(url, putConfig);
        } catch (error) {
            if (error.response.status === 429) {
                displayAlertDialog({
                    title: "Too Many Requests",
                    message: "You have made too many requests for this page. Please try refreshing again after a minute"
                })
            }
            console.error(error);
        }
    }

    async verifyEmail(shareRef, id) {
        let putConfig = httpSupportService.getPublicPutConfigs();
        let url = `${configs.APIM.BASE_URL}${configs.APIM.PUBLIC_BASE_PATH}/profile/verify/${shareRef}/${id}`;

        try {
            let response = await httpSupportService.doRequest(url, putConfig);
            return (await response.json()).result;
        } catch (error) {
            var message = await error.response.text();
            if(message === 'ALREADY_VERIFIED'){
                return message;
            }else{
                throw error;
            }
        }
    }

    async getMasterData(dataType){
        let getConfig = httpSupportService.getPublicGetConfigs();
        let url = `${configs.APIM.BASE_URL}${configs.APIM.PUBLIC_BASE_PATH}//master/${dataType}`;

        try {
            let response = await httpSupportService.doRequest(url, getConfig);
            return (await response.json()).results;
        } catch (error) {
            if (error.response.status === 429) {
                displayAlertDialog({
                    title: "Too Many Requests",
                    message: "You have made too many requests for this page. Please try refreshing again after a minute"
                })
            }else{
                displayAlertDialog({
                    title: "Error!",
                    message: error.response.statusText
                })
            }
            console.error(error);
        }
    }
}

export default new PublicService();