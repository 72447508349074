import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { responsiveFontSizes, ThemeProvider } from "@mui/material";
import Grid from '@mui/material/Grid';
import { CypeerRoundTableTheme } from "./Themes";
import { msalInstance } from "./services/authService";
import AppBar from './components/CypeerAppBar';
import Footer from "./components/CypeerFooter";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Invites from "./pages/invites/Invites";
import RoundTables from "./pages/roundtables/RoundTablesRoot";
import { useSelector } from "react-redux";
import Profile from "./pages/profile/Profile";
import Nomination from "./pages/invites/Nomination";
import { AlertDialog } from "./components/AlertDialog";
import RoundTableShareLinkView from "./pages/roundtables/shareJoin/RoundTableShareLinkView";
import SignUpForm from "./pages/signup/SignUpForm";
import SignUpProcess from "./pages/signup/SignUpProcess";

const HFWrapper = ({ children }) => {
  const showHF = useSelector(state => state.appState.displayHeaderFooter);
  if (showHF) {
    return (
      <>
        <AppBar />
        {children}
        <Footer />
      </>
    )
  } else {
    return children
  }

}


function App() {

  window.scrollTo(0, 0);

  let mainTheme = responsiveFontSizes(CypeerRoundTableTheme);
  const isValidAuth = useSelector(state => state.appState.isValidAuthToken);
  const myProfile = useSelector(state => state.appState.myProfile)

  const location = useLocation();

  const UnAuthRouts =
    <Routes>
      <Route path="/" element={<HFWrapper><Home /></HFWrapper>} />
      <Route path="/home" element={<HFWrapper><Home /></HFWrapper>} />
      <Route path="/signup" element={<HFWrapper><SignUpForm /></HFWrapper>} />
      <Route path="/invite/nomination/:ref" element={<HFWrapper><Nomination /></HFWrapper>} />
      <Route path="/*" element={<Login redirectPath={location.pathname} />} />
      <Route exact path="/roundtables/:roundtableId" element={<RoundTableShareLinkView isAuthenticated={false} />} />
    </Routes>

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={mainTheme}>
        <Grid className="cypeer_app"
          sx={{
            height: 'auto',
            minHeight: '100%',
          }}
        >
          <div style={{ minHeight: "100vh" }}>
            {/* {showHF && <AppBar />} */}
            <AuthenticatedTemplate>
              {isValidAuth
                ?
                myProfile
                  ?
                  <Routes>
                    <Route path="/" element={<Navigate to="/roundtables" replace={true} />} />
                    <Route path="/roundtables/*" element={<HFWrapper><RoundTables /></HFWrapper>} />
                    <Route path="/invite/*" element={<HFWrapper><Invites /></HFWrapper>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<HFWrapper><SignUpProcess /></HFWrapper>} />
                    {/* <Route path="/login" element={<Navigate to="/" replace={true} />} /> */}
                    <Route path="/home" element={<HFWrapper><Home /></HFWrapper>} />
                    <Route path="/profile/*" element={<HFWrapper><Profile /></HFWrapper>} />
                    <Route exact path="share/roundtables/:roundtableId" element={<RoundTableShareLinkView isAuthenticated={true} />} />
                  </Routes>
                  : 
                  <div>Sorry! Something went wrong while loading your profile. please try again shortly by refreshing the page.</div>
                :
                // UnAuthRouts
                <Login redirectPath={location.pathname} />
              }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              {UnAuthRouts}
            </UnauthenticatedTemplate>
          </div>
          {/* {showHF && <Footer />} */}
          <AlertDialog />
        </Grid>
      </ThemeProvider>
    </MsalProvider>
  );
}

export default App;
