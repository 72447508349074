import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  homePageState: {
    filter: 'explore',
    filterRefresh: 0,
    searchQuery: '',
    nextRoundtableToLive: null,
    readyRoundtablesToLive: {},
    explore: {
      roundTables: [],
      page: 0
    },
    featured: {
      roundTables: [],
      page: 0
    },
    my: {
      roundTables: [],
      page: 0
    },
    search: {
      roundTables: [],
      page: 0
    }
  },
  homePageScrollState: {
    explore: 0,
    my: 0,
    search: 0
  },
  startRTState:{
    formData: null,
  }
}

const updateRoundtableInListState = (newRt, state) => {
  const updateList = (listState) => {
    let list = listState;
    let index = list.findIndex(rt => rt.id === newRt.id)
    //console.log("RTSTATE: updating list: " + index);
    if (index >= 0) {
      //console.log("RTSTATE: updating list: " + index);
      list[index] = newRt
    }
  }
  updateList(state.homePageState.explore.roundTables)
  updateList(state.homePageState.my.roundTables)
  updateList(state.homePageState.search.roundTables)
}

const roundTableState = createSlice({
  name: 'roundTable',
  initialState,
  reducers: {
    updateSelectedRoundTable: (state, action) => {
      const newRt = action.payload
      //console.log("RTSTATE: updating selected round table");
      // window.localStorage.setItem("SELECTED_ROUND_TABLE", JSON.stringify(newRt));
      updateRoundtableInListState(newRt, state)
      state.selectedRoundTable = action.payload;
    },
    updateAllReoundTables: (state, action) => {
      //console.log("RTSTATE: updating all round tables");
      const filter = action.payload.filter
      const page = action.payload.page
      const roundTables = action.payload.roundTables
      const isAllLoaded = action.payload.isAllLoaded
      const secondaryFilter = action.payload.secondaryFilter
      if (secondaryFilter) {
        const hpstate = state.homePageState[filter]
        if (!hpstate.secondaryFilters) {
          hpstate.secondaryFilters = {}
        }
        hpstate.secondaryFilters[secondaryFilter] = { roundTables, page, isAllLoaded };
      } else {
        state.homePageState[filter] = { roundTables, page, isAllLoaded };
      }
      state.homePageState.filterRefresh = state.homePageState.filterRefresh + 1
    },
    updateRoundtableInLists: (state, action) => {
      const newRt = action.payload
      //console.log("RTSTATE: updating RoundtableInLists");
      //console.log(newRt) // TODO: remvoe/ disable this log
      updateRoundtableInListState(newRt, state)
    },
    updateHomeFilter: (state, action) => {
      let filter = action.payload.filter
      if (!filter) {
        console.error("action.filter is missing.. hence set default filter: 'explore'")
        filter = 'explore'
      }
      if (filter === 'search') {
        const searchQuery = action.payload.searchQuery || ''

        const hpstate = { ...state.homePageState }
        hpstate.search = {
          roundTables: [],
          page: 0
        }
        state.homePageState = { ...hpstate, filter, searchQuery }
      } else {
        if (filter === state.homePageState.filter) {
          // refresh reqeust
          state.homePageState[filter] = {
            roundTables: [],
            page: 0
          }
          state.homePageState.filterRefresh = state.homePageState.filterRefresh + 1
          state.homePageState.filter = filter
        } else {
          state.homePageState.filter = filter
        }
      }
      //console.log("RTSTATE: updating updateHomeFilter");

    },
    addToMyRoundTables: (state, action) => {
      let newRt = action.payload
      let currentRTs = state.homePageState.my.roundTables
      let index = currentRTs.findIndex(rt => rt.id === newRt.id)
      if (index >= 0) {
        //console.log("RTSTATE: updating addToMyRoundTables: " + index);
        // console.log( state.homePageState.explore.roundTables)
        currentRTs[index] = newRt
      } else {
        if (currentRTs.length) {
          state.homePageState.my.roundTables = [newRt].concat(currentRTs)
        }
      }
    },
    removeFromMyRoundTables: (state, action) => {
      let rt = action.payload
      let currentRTs = state.homePageState.my.roundTables
      let index = currentRTs.findIndex(rtt => rt.id === rtt.id)
      if (index >= 0) {
        // console.log("RTSTATE: updating removeFromMyRoundTables: " + index);
        // console.log( state.homePageState.explore.roundTables)
        currentRTs.splice(index, 1);
      } 
    },
    updateMyRoundatableCount: (state, action) => {
      let counts = action.payload
      state.homePageState.myRoundtablesCount = counts.activeParticipateCount
    },
    updateHomeScroll: (state, action) => {
      let filter = action.payload.filter
      let scrollY = action.payload.scroll
      // console.log("RTSTATE: updating updateHomeScroll " + filter + ", " + scrollY)
      state.homePageScrollState[filter] = scrollY
    },
    updateNextRoundtableToLive: (state, action) => {
      let newRT = action.payload
      //console.log("RTSTATE: updating updateNextRoundtableToBegin ", newRT)
      state.homePageState.nextRoundtableToLive = newRT
    },
    updateeRadyRoundtablesToLive: (state, action) => {
      let newReadyMap = action.payload
      //console.log("RTSTATE: updating updateeRadyRoundtablesToLive ", newReadyMap)
      state.homePageState.readyRoundtablesToLive = newReadyMap
    },
    updateStartRTForm:(state, action)=> {
      state.startRTState.formData = action.payload;
    }
  }
});



export const {
  updateSelectedRoundTable, updateAllReoundTables, updateRoundtableInLists,
  updateHomeFilter, updateMyRoundatableCount, updateHomeScroll,
  addToMyRoundTables, removeFromMyRoundTables, updateNextRoundtableToLive,
  updateeRadyRoundtablesToLive,updateStartRTForm
} = roundTableState.actions

export default roundTableState.reducer