import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import RTBackdropPage from "../../components/RTBackdropPage";
import RTPaper from "../../components/RTPaper";
import VerifyEmail from "../invites/VerifyEmail";
import { sp4 } from "../../configs/spacings";
import profileService from "../../services/profileService";
import { CypeerLoading } from "../../components/CypeerLoading";

export default function ProfileVerifyEmailPage() {

    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        ReactGA.pageview(window.location.pathname);

        profileService.getUserProfile().then(data => {
            // TODO - Correcting the miss-spelled property name "counrty" 
            // Remove this after DB fix           
            data.country = data.counrty;
            delete data.counrty;

            if (data.emailVerified) {
                navigate('/profile/me', { replace: true });
            } else {
                setProfile(data);
            }
        });
    }, []);

    return (
        <RTBackdropPage styles={{ alignItems: "center", py: sp4 }}>
            {profile
                ? <RTPaper styles={{ minHeight: '70vh' }}>
                    <VerifyEmail profile={profile} />
                </RTPaper>
                : <CypeerLoading />
            }
        </RTBackdropPage>
    )
}