
let profileContent = {  
    'countryLabel': 'Location',
    'orgNameLabel': 'Your Company Name',
    'roleLabel': 'Your Role',
    'industryLabel': 'Industry',
    'interestsTitle': 'Your interests:',
    'interestsSubtitle': 'You can select up to 5 topics from the list or add your own.',
    'emptyInterestsError': ' Please select at least one topic of interest'
}

export { profileContent };