import { Paper } from "@mui/material";
import { sp3, sp4 } from "../configs/spacings";

//TODO: Malika - if this is only for RT just move it to /pages/RT/..
export default function RTPaper({ children, styles }) {
    return (
        <Paper variant='outlined' className="card"
            sx={{
                borderRadius: '0.8rem',
                px: sp4,
                py: sp3,
                ...styles
            }}
        >
            {children}
        </Paper>
    );
}