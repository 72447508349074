import { useParams } from "react-router-dom";
import ReactGA from 'react-ga';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LogoFull from '../../components/CypeerLogo';
import RTBackdropPage from "../../components/RTBackdropPage";
import RTPaper from "../../components/RTPaper";
import TermsAndConditionsNotice from '../../components/TermsAndConditionsNote';
import PublicService from '../../services/publicService';
import { useEffect, useState } from "react";
import authservice from "../../services/authService";
import { nominations } from "./content";

export default function Nomination() {
    const { ref } = useParams();
    const [profile, setProfile] = useState(null);

    function login() {
        localStorage.setItem('nomination', ref)
        authservice.signIn('nominationsButton');
    }

    useEffect(() => {

        ReactGA.pageview(window.location.pathname);

        if (ref) {
            PublicService.getPublicUserProfile(ref).then(data => {
                setProfile(data);
                if (data) {
                    PublicService.putShareRefHit(ref);
                }
            });
        }
    }, [])

    return (
        <RTBackdropPage>
            <RTPaper styles={{ minHeight: '85vh' }}>
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    minHeight='inherit'
                // border={1}
                >
                    {profile ?
                        <Grid className="inviter_profile"
                            sx={{
                                py: 1,
                                // border: 1
                            }}
                        >
                            <Grid className="profile_image"
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            // border={1}
                            >
                                <Grid className='image_column' item xs={3} sm={1} xl={1}
                                    visibility={profile.profilePicture ? 'true' : 'hidden'}
                                    sx={{
                                        // border: 1,
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                        pt: { xs: 3, lg: 4 },
                                        pb: 0
                                    }}
                                >
                                    <Grid className='combined_image' container columns={12} direction='row'
                                        sx={{
                                            // border: 1,
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid className='profile_image' item
                                            sx={{
                                                // border: 1,
                                                width: 1,
                                            }}
                                        >
                                            <img src={profile.profilePicture} alt=''
                                                style={{
                                                    maxWidth: '-webkit-fill-available',
                                                    borderRadius: '50%',
                                                    // border: 'solid',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant='h4' textAlign='center'>{profile.displayName} {nominations.title}</Typography>
                            </Grid>
                        </Grid>
                        :
                        <Grid item className="Logo"
                            sx={{
                                height: { xs: 40 },
                                pb: 1
                            }}
                        >
                            <LogoFull />
                        </Grid>
                    }
                    <Grid item sx={{ py: 2 }}>
                        <Typography variant='h6' textAlign='center'>
                            {nominations.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item className="join_button">
                        <Button
                            required
                            id="description"
                            name="description"
                            label="Short Description"
                            fullWidth
                            startIcon={<LinkedInIcon />}
                            variant="contained"
                            onClick={() => login()}
                        >
                            Join with LinkedIn
                        </Button>
                    </Grid>
                    <Grid item sx={{ textAlign: 'center', py: 1, }}>
                        <TermsAndConditionsNotice prefixText={'By joining with LinkedIn'} />
                    </Grid>
                </Grid>
            </RTPaper>
        </RTBackdropPage>
    );
}