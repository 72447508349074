import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import RoundTableList from './rountTablesHome/RoundTableList';
import { sp1, sp3, spx9 } from '../../configs/spacings';
import { RTHeaderBar } from './rountTablesHome/RTHeaderBar';
import StartRoundTable from './StartRoundTable';
import Interval from '../../components/Interval';
import roundTablesService from '../../services/roundTablesService';
import { updateNextRoundtableToLive, updateRoundtableInLists } from '../../states/roundTableState';
import moment from 'moment'

function RoundTablesHomePage() {
    const navigate = useNavigate();
    const myProfile = useSelector(state => state.appState.myProfile)

    const filter = useSelector(state => state.roundTableState.homePageState.filter)
    const nextRtToLive = useSelector(state => state.roundTableState.homePageState.nextRoundtableToLive)


    const dispatch = useDispatch()


    const UpdateRtLiveInterval = () => {
        const intervalAction = () => {
            // const updatedRT = await roundTablesService.getRoundTable(nextRtToLive.id)
            // updatedRT.currentState = roundTablesService.getCurrentState(updatedRT)
            // dispatch(updateRoundtableInLists(updatedRT))
            // dispatch(updateNextRoundtableToLive(updatedRT))
            roundTablesService.requestUpdateNextRtToLive()
        }
        const startBuffer = 5 //TODO: take this from configs
        const stTime = moment(nextRtToLive.tableDateTime).subtract(startBuffer,'minutes')
        return <Interval startTime={stTime} intervalInSecs={10} action={intervalAction} />
    }

    return <div>
        <Box position="sticky" top={{ xs: 50 }} zIndex={100}>
            <Box
                sx={{
                    pb: sp1,
                    px: spx9,
                    backgroundColor: 'backgroundSecondary.main'
                }}>
                <RTHeaderBar />
            </Box>
        </Box>
        <Box
            sx={{
                pb: sp3,
                pt: sp1,
                px: spx9,
                backgroundColor: "backgroundSecondary.main"
            }}>
            {filter === "new"
                ? <StartRoundTable />
                : <RoundTableList />
            }
        </Box>
        {nextRtToLive && <UpdateRtLiveInterval />}
    </div>;
}


export default RoundTablesHomePage;
