import { ThemeProvider } from '@emotion/react'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, Card, CardMedia, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CypeerLoading } from '../../../components/CypeerLoading'
import RTBackdropPage from '../../../components/RTBackdropPage'
import RTPaper from '../../../components/RTPaper'
import { sp3, sp7, spx8, spx9 } from '../../../configs/spacings'
import authService from '../../../services/authService'
import roundTablesService from '../../../services/roundTablesService'
import { CypeerRoundTableTheme } from '../../../Themes'
import { HostDetails,  RoudnTableStatus } from '../commonComponents'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TermsAndConditionsNotice from '../../../components/TermsAndConditionsNote'
import { displayAlertDialog } from '../../../components/AlertDialog'
import useWindowSize from '../../../components/hooks/useWindowSize'
import publicService from '../../../services/publicService'
import profileService from '../../../services/profileService'

const RoundTableShareLinkView = ({ isAuthenticated }) => {
    const { roundtableId } = useParams()
    const [roundTable, setRoundtable] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const shareId = searchParams.get("share")
    const shouldJoin = searchParams.get("join") === "true"
    const [joinLoadingState, setJoinLoading] = useState(false)
    const [rtLoadingState, setRtLoading] = useState(false)
    const navigate = useNavigate();
    const windowsiize = useWindowSize()

    const loadRoundtable = async () => {
        setRtLoading(true)
        try {
            let rt = await roundTablesService.getRoundTablePublicInstance(roundtableId)
            rt.participants = [rt.host,rt.host] //hack to avoid error of reusable fuctions created for full(authenticated) RT
            let cs = roundTablesService.getCurrentState(rt)
            rt.currentState = cs
            setRoundtable(rt)
            const publicProfile = await publicService.getPublicUserProfile(rt.host.id)
            publicProfile.id = rt.host.id
            profileService.putProfileInCache(publicProfile)
            setRoundtable(rt)
        } catch (error) {
            console.error(error)
            ReactGA.exception({
                symmary: "pubic GET roundtable failed",
                label: "roundtable_details_public",
                error: error
            })
        } finally {
            setRtLoading(false)
        }
    }

    const gotoRTdetailPage = () => {
        navigate(`/roundtables/${roundtableId}?share=${shareId}`, { replace: true })
    }

    const joinWithLikedIn = async () => {
        setJoinLoading(true)
        if (shouldJoin && isAuthenticated) {
            if (roundTable.currentState.isYetToStart) {
                try {
                    await roundTablesService.joinRoundTable(roundTable.id, true)
                } catch (e) {
                    if (e.name === "httpError" && e.httpStatus === 400) {
                        displayAlertDialog({
                            title: "Session full",
                            message: "This session is already full. Please explore to find more roundtables."
                        })
                    } else {
                        displayAlertDialog({
                            title: "Something went wrong",
                            message: "Something went wrong while joining the roundtable. Pleaase refresh the page and try again"
                        })
                    }
                }
            } else {
                const rtStatetxt = roundTable.currentState.isLive ? 'in live' : 'finished'
                displayAlertDialog({
                    title: "Cannot Join This Roundtable",
                    message: `You cannot join this roundtable now as it already ${rtStatetxt}. Please explore to find more roundtables.`
                })

            }
            gotoRTdetailPage()
            //joinMeToRoundTable(roundTable, setJoinLoading, gotoRTdetailPage)
        } else {
            setSearchParams({ join: true, share: shareId })
            await authService.signIn("shareRTPage")
        }
        ReactGA.event({
            category: 'Button_Clicks',
            action: 'Join_Roundtable_With_LinkedIn',
            label: "roundtable_details_public"
        });

    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname+ "#public");
    }, [])

    useEffect(() => {
        if (isAuthenticated && !shouldJoin) {
            gotoRTdetailPage()
        } else if (roundTable) {
            if (shouldJoin) {
                joinWithLikedIn()
            }
        } else {
            loadRoundtable()
        }
    }, [roundTable])

    const MainAction = ({ roundTable }) => {

        let cs = roundTable.currentState;
        let amIin = cs.amIin;

        if (cs.isYetToStart || true) {
            let fmtDate = roundTable.tableDateTime
            let avaialbe = roundTable.maxParticipants;
            return <>
                {/* <Typography variant="subtitle2" mr={2} fontWeight="600">
                    {`${avaialbe} slot${avaialbe > 1 ? 's ' : ' '} allocated`}
                </Typography> */}
                <LoadingButton
                    onClick={joinWithLikedIn}
                    loading={joinLoadingState}
                    startIcon={<LinkedInIcon />}
                    loadingIndicator="Joining..."
                    variant="contained"
                    sx={{ minWidth: '8em' }}
                >
                    Join with LinkedIn
                </LoadingButton>
                {/* <Button variant="outlined" startIcon={<GroupsIcon />} onClick={joinMeToRoundTable} > Join </Button> */}
            </>
        } else if (cs.isReadyToStart) {
            if (amIin && cs.isApproved) {
                // return <Button variant="contained" startIcon={<GroupsIcon />} onClick={handleInfoDialogOpen} > Connect </Button>
                // onClick={() => navigate('join')}
            } else {
                if (cs.isLive) {
                    // return <Button variant="outlined" startIcon={<LiveTvIcon />} onClick={() => navigate('live')} disabled> Watch Live </Button>
                }
            }
        }
        else if (cs.isEnded) {
            // as recording feature not implemented with current phase
            // return <>
            //     {(cs.isRecodingsReady)
            //         ? <Button variant="outlined"
            //             startIcon={<PlayCircleOutlineIcon />}
            //             onClick={() => navigate('recording')}
            //             sx={{ width: '100%' }} > Watch Now </Button>
            //         : <div>Awiat for recording..</div>
            //     }
            // </>
        }
        //TODO handle this well
        return <div></div>
    }

    return (
        <ThemeProvider theme={CypeerRoundTableTheme}>
            <RTBackdropPage styles={{ py: 0, px: 0 }}>
                <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center">
                    <Card className="banner_image"
                        sx={{
                            width: '100%',
                            borderRadius: 0,
                        }}>
                        <CardMedia
                            component="img"
                            image={windowsiize.width > 900 ? "/images/roundtables/top_banner_share_large.png" : "/images/roundtables/top_banner_share_small.png"}
                            alt="roundtables"
                        />
                    </Card>
                    <Box px={spx9}
                        flexGrow={1}
                        width="100%"
                    // maxWidth={980}
                    >
                        <RTPaper styles={{ my: sp7, p: spx8 }}>
                            {rtLoadingState
                                ?
                                <Box width='100%'>
                                    <CypeerLoading />
                                </Box>
                                : roundTable
                                    ?
                                    <Box>
                                        {/* {TopFlag(roundTable)} */}
                                        {Topic(roundTable)}
                                        {Description(roundTable)}
                                        <Grid container spacing={2} sx={{ pt: 0, pb: sp3 }}
                                            direction="row"
                                            alignItems="flex-end"
                                            justifyContent="space-between"
                                        >
                                            <Grid item sm={"auto"}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, justifyContent: 'space-between' }}>
                                                    {HostDetails(roundTable)}
                                                </Box>
                                            </Grid>
                                            {/* <Grid item sm={"auto"}>
                                {"SEC"}
                            </Grid> */}
                                        </Grid>
                                        <Grid container spacing={2} sx={{ pt: 0, flexWrap: 'wrap' }}
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Grid item sm={"auto"} sx={{ flexGrow: 1 }}>
                                                {RoudnTableStatus(roundTable)}
                                            </Grid>
                                            <Grid item sm={"auto"} sx={{ flexGrow: 1 }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end', //{ xs: 'center', sm: 'flex-end' },
                                                    alignItems: 'center' // { xs: 'center', sm: 'center' }
                                                }}
                                                >
                                                    <MainAction roundTable={roundTable} />
                                                </Box>
                                            </Grid>
                                        </Grid>


                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end', //{ xs: 'center', sm: 'flex-end' },
                                            alignItems: 'center', // { xs: 'center', sm: 'center' }
                                            textAlign: 'right',
                                            pt: 2,
                                            pr: 0.5
                                        }}>
                                            <TermsAndConditionsNotice prefixText={'By joining with LinkedIn'} />
                                        </Box>
                                    </Box>
                                    :
                                    <Alert
                                        severity="error"
                                        sx={{mt: 1}}
                                        action={
                                            <Button color="inherit" size="small" onClick={() => {
                                                loadRoundtable()
                                                ReactGA.event({
                                                    category: 'Button_Clicks',
                                                    action: 'Try_Reload_Roundtable',
                                                    label: "roundtable_details_public"
                                                });
                                            }
                                            }>
                                                Retry
                                            </Button>
                                        }>Something went wrong while requesting Roundtable from the server.</Alert>

                            }

                        </RTPaper>
                    </Box>
                </Box>

            </RTBackdropPage>
            {/* <CypeerFooter /> */}
        </ThemeProvider>
    )

}

const Topic = (roundTable) => {
    return <Typography component="div" variant="h4" align="left" sx={{ fontSize: { xs: 24, md: 32 } }}>
        {roundTable.tableTopic}
    </Typography>
}

const Description = (roundTable) => {
    if (roundTable.description) {
        return <Typography
            component="div"
            variant="subtitle1"
            color={"text.primary"}
            align="left"
            sx={{ pt: 1, pl: '1px', lineHeight: '1.3em' }}
        >
            {roundTable.description}
        </Typography>
    }
}



export default RoundTableShareLinkView