import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ShareInvite from "./ShareInvite";

function Invites() {
    return (
        <>
            <Routes>
                <Route path="/" element={<ShareInvite />} />
                <Route path="/nomination/*" element={<Navigate to="/" replace={true} />} />
            </Routes>
        </>
    );
};

export default Invites;