
let homeContent = {
    'mainHeader': 'Discover and connect <live>LIVE</live> with your cybersecurity peers',
    'mainDescription': "No prior introductions needed. Just meet, share, learn and make real connections.",
    'infoCards': [
        {
            'sortOrder': 1,
            'title': 'Start a roundtable',
            'text': 'You start a roundtable to discuss a challenge, share a learning or simply to network. You don\'t have to be an expert to gather people together to explore interests.',
            'icon': 'info_card_icon_1.svg',
        },
        {
            'sortOrder': 2,
            'title': 'Join a roundtable',
            'text': 'Roundtables are happening on many topics. Discover and join as many Roundtables as you like and meet with peers and experts.',
            'icon': 'info_card_icon_2.svg',
        },
        {
            'sortOrder': 3,
            'title': 'Make connections',
            'text': 'Discover new friends, make real connections, follow their interests and stay in touch.',
            'icon': 'info_card_icon_3.svg',
        },
    ],
    'howRTWorks': {
        'title': 'Here is how Cypeer works',
        'subTitle': 'Cypeer roundtable is a 40 mins live video roundtable meeting. It\'s open to anyone leading cybersecurity.',
    },
    'toWhomTitle': 'Who is Cypeer for?',
    'toWhomSubtitle': 'Cypeer is for anyone leading cybersecurity. ',
    'toWhomPoints': [
        {
            'sortOrder': 1,
            'text': 'Cybersecurity leader, CISOs, Risk leaders and security technology leaders.',
        },
        {
            'sortOrder': 2,
            'text': 'Business leaders, CIOs, CEOs, CFOs, CTO, CxO and Board members.',
        },
        {
            'sortOrder': 3,
            'text': 'Cybersecurity product leaders, service leaders, educators, thought leaders and researchers.',
        }
    ],
    'partnerCompanySlogan': 'Join with 1000+ other cybersecurity leaders',
    'joinUs': 'Join Cypeer',
};

export { homeContent };