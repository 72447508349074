import React from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ReactGA from 'react-ga';
import authService from "../../services/authService";

function SignUpButton(props) {

    const navigate = useNavigate();

    const handleSignUp = function () 
    {
        //   ReactGA.event({
        //     category: 'Button_Clicks',
        //     action: 'SignUp',
        //     label: props.gaLabel
        //   });
        //  navigate('/signup');
      authService.signIn(props.gaLabel);
    };

    return (
        <Button
            variant={props.variant ? props.variant : "contained"}
            color={props.color ? props.color : "primary"}
            size={props.size ? props.size : "large"}
            startIcon={props.showLIIcon ? <LinkedInIcon /> : undefined}
            onClick={handleSignUp}
            sx={props.styles ? props.styles :
                { width: 'fit-content', px: { md: 3 }, py: { md: 2 } }
            }
        >
            <Typography fontSize={{ xs: 16, md: 22 }} fontWeight={500}>
                {props.text}
            </Typography>
        </Button>
    );
};

export default SignUpButton;
