import Box from "@mui/material/Box";
import { sp3, sp1, spx9 } from "../configs/spacings";

//TODO: Malika - if this is only for RT just move it to /pages/RT/..
export default function RTBackdropPage({ children, styles }) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'backgroundSecondary.main',
                minHeight: 'inherit',
                py: sp1,
                px: spx9,
                ...styles,
            }}
        >
             <Box sx={{ width: '100%' }}>
                {/*<Box
                    sx={{
                        py: sp1,
                        px: spx9,
                        backgroundColor: "backgroundSecondary.main"
                    }}> */}
                    {children}
                {/* </Box> */}
            </Box> 
        </Box>
    );
}