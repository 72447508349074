import configs from "../configs/configs";
import httpSupportService from "./httpSupprotService";
import moment from 'moment';
import reduxStore from "../states/reduxStore";
import { updateeRadyRoundtablesToLive, updateMyRoundatableCount, updateNextRoundtableToLive, updateRoundtableInLists } from "../states/roundTableState";

class RoundTablesService {

    async addRoundTable(basicMeetingInfo) {
        const playload = {
            "tableTopic": basicMeetingInfo.topic,
            "tableDateTime": new Date(basicMeetingInfo.dateTime).toISOString(),
            "duration": configs.ROUND_TABLE.MAX_DURATION,
            "roundTableType": basicMeetingInfo.type,
            "tags": basicMeetingInfo.keywords,
            "customTags": basicMeetingInfo.newKeywords,
        };

        const postCfgs = await httpSupportService.getDefaultPostConfigs(playload);

        // TODO remove below comments
        // return fetch(configs.APIM.BASE_URL + configs.APIM.ROUND_TABLE_BASE_PATH + "/roundtable", options)
        //     .then((response) => {
        //         if (response.status === 200) {
        //             return response.json();
        //         } else {
        //             return 'No data';
        //         }
        //     })
        //     .then(data => {
        //         return data.result;
        //     })
        //     .catch(console.log);

        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable`, postCfgs);
        let newRT = (await response.json()).result;
        return newRT
    }

    async getStartRTMaterData() {

        const getCfgs = await httpSupportService.getDefaultGetConfigs();

        // TODO remove below comments
        // return fetch(configs.APIM.BASE_URL + configs.APIM.PROFILE_BASE_PATH + "/master", getCfgs)
        //     .then(res => res.json())
        //     .then((res) => {
        //         var interests = res.results.find(r => r.type === 'Interest')
        //             .data.map(i => {
        //                 return {
        //                     id: i.id,
        //                     value: i.value,
        //                 };
        //             });

        //         var types = res.results.find(r => r.type === 'RoundTableType')
        //             .data.map(i => {
        //                 return {
        //                     id: i.id,
        //                     value: i.value,
        //                 };
        //             });

        //         return {
        //             interests,
        //             types
        //         };

        //     })
        //     .catch(console.log);

        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.DATA_BASE_PATH}/master`, getCfgs);
        let res = (await response.json()).results;
        var interests = res.find(r => r.type === 'Interest')
            .data.map(i => {
                return {
                    id: i.id,
                    value: i.value,
                };
            });

        var types = res.find(r => r.type === 'RoundTableType')
            .data.map(i => {
                return {
                    id: i.id,
                    value: i.value,
                };
            });

        return {
            interests,
            types
        };
    }

    async getAllRoundTables(query) {
        if (!query) query = {
            pageSize: 100,
            pageNumber: 0,
            filter: 'All',
            orderBy: 'Created'
        }
        let queryStr = httpSupportService.getQueryStringForObj(query)
        let url = `${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable${queryStr}`
        let getCfg = await httpSupportService.getDefaultGetConfigs()
        let response = await httpSupportService.doRequest(url, getCfg)
        if (response.status === 200) {
            let roundTables = (await response.json()).results
            return roundTables
        } else {
            //TODO: handle this well
            throw new Error(`Unexpected error response ${response.status} from getMyProfile`);
        }
    }

    async getRoundTable(id) {
        let getCfg = await httpSupportService.getDefaultGetConfigs()
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable/${id}`, getCfg)
        let roundTable = (await response.json()).result
        return roundTable
    }

    async getRoundTablePublicInstance(id) {
        let headers = await httpSupportService.getDefaultHeadersWithoutAuth()
        let getCfg = {
            method: 'GET',
            headers: headers
        }
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.PUBLIC_BASE_PATH}/roundtable/${id}`, getCfg)
        let roundTable = (await response.json()).result
        
        return roundTable
    }

    async getRoundtableShare(id) {
        let getCfg = await httpSupportService.getDefaultGetConfigs()
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtablereference/${id}`, getCfg)
        let roundTableShare = (await response.json()).result
        return roundTableShare
    }

    async getRoundTableMeetingInfo(id) {
        let getCfg = await httpSupportService.getDefaultGetConfigs()
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable/${id}/meetinginfo`, getCfg)
        let meetingInfo = (await response.json()).result
        return meetingInfo
    }
    
    async getRoundTableAttachments(id) {
        let getCfg = await httpSupportService.getDefaultGetConfigs()
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable/${id}/attachments`, getCfg)
        let meetingInfo = (await response.json())
        return meetingInfo
    }

    async updateRoundTable(rt) {
        let putCfg = await httpSupportService.getDefaultPutConfigs(rt);
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}/roundtables/${rt.id}`, putCfg);

        let newRT = await response.json();
        return newRT
    }

    async joinRoundTable(RoundTableId, joined) {
        let putCfg = await httpSupportService.getDefaultPutConfigs({ joined });
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable/${RoundTableId}/participant`, putCfg);

        let newRT = (await response.json()).result;
        return newRT
    }

    async rateRoundTable(RoundTableId, rating) {
        let putCfg = await httpSupportService.getDefaultPutConfigs({ rating });
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable/${RoundTableId}/rating`, putCfg);

        let newRT = (await response.json()).result;
        return newRT
    }

    async updateLike(RoundTableId, liked) {
        let putCfg = await httpSupportService.getDefaultPutConfigs({ liked });
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable/${RoundTableId}/like`, putCfg);
        let newRT = (await response.json()).result;
        return newRT
    }

    async refreshMyRoundtableCount() {
        try {
            let getCfg = await httpSupportService.getDefaultGetConfigs()
            let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtablecount`, getCfg)
            let count = (await response.json()).result
            reduxStore.dispatch(updateMyRoundatableCount(count))

        } catch (e) {
            console.error("refreshMyRoundtableCount failed", e)
        }
    }

    async getRoundtableShareToken(roundTableId) {
        let putCfg = await httpSupportService.getDefaultPutConfigs()
        let response = await httpSupportService.doRequest(`${configs.APIM.BASE_URL}${configs.APIM.ROUND_TABLE_BASE_PATH}/roundtable/${roundTableId}/share`, putCfg)
        let token = (await response.json()).result
        return token
    }


    getCurrentState = (newRoundTable, myProfile) => {

        const oldState = newRoundTable.currentState
        const now = moment();

        let oldVersion, firstUpdateStr
        if (oldState) {
            oldVersion = oldState.version
            firstUpdateStr = oldState.firstUpdate
        } else {
            oldVersion = 0
            firstUpdateStr = now.format()
        }
        //current state
        const cs = {
            version: oldVersion,
            isApproved: true, //This means approved or higher stater after approved
            isLive: false, //Live true means zoom call is on going.
            isReadyToStart: false, // This means meeting is already in progress or can be started.
            isEnded: false,
            isRecodingsReady: false,
            isYetToStart: false,
            isNotHappened: false,
            isClosed: false, // closed true means (scheduled time + duration) is passed 
            liveDuration: 0,
            secondsToStart: 36000,
            amIin: false,
            firstUpdate: firstUpdateStr // the time of first current status generated for this rt instance.
        }

        const startTime = moment(newRoundTable.tableDateTime);
        const endTime = startTime.clone().add(newRoundTable.duration, 'm');
        const startBuffer = startTime.clone().subtract(5, 'm'); //TODO: take from configs
        // console.log("txxx start" + startTime.format());
        // console.log("txxx startbff" + startBuffer.format());
        // console.log("txxx buffend" + endTime.format());

        if (now.isBefore(startBuffer)) {
            cs.isYetToStart = true;
            cs.secondsToStart = startBuffer.diff(now, 'seconds')
        } else if (now.isBefore(endTime)) {
            cs.isReadyToStart = true;
        } else {
            cs.isClosed = true;
        }

        if (myProfile) {
            let me = newRoundTable.participants.filter(p => p.id === myProfile.id)[0]
            cs.amIin = me ? true : false
        }

        let instances = newRoundTable.meetingProgress ? newRoundTable.meetingProgress.instances : []
        let prg = instances.length ? instances[instances.length - 1] : {};
        const statusText = newRoundTable.statusText

        if (statusText === "Created" || statusText === "Rejected") {
            cs.isApproved = false
        } else if (cs.isReadyToStart && statusText === "MeetingStarted") {
            cs.isLive = true;
            let startedTime = moment(prg.startTime)
            cs.liveDuration = now.diff(startedTime, 'minutes')
        } else if (statusText === "MeetingEnded" || statusText === "RecordingCompleted" || statusText === "Completed") { // 5 is ended
            cs.isEnded = true;
            cs.liveDuration = prg.totalTime //TODO: Take this from root progress not form instance.
            if (statusText === "RecordingCompleted") {// 6 is recording ready
                cs.isRecodingsReady = true
            }
        } else {
            if (cs.isClosed && !prg.startTime) {
                if (endTime.isBefore(moment(firstUpdateStr))) {
                    cs.isNotHappened = true
                }
            }
        }
        let stateChanged = false
        if (oldState) {
            const keys = Object.keys(cs)
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                if (cs[key] !== oldState[key]) {
                    stateChanged = true
                    cs.version = oldVersion + 1
                    break;
                }
            }
            // console.log("##CS State changed? " + stateChanged + "   ", cs)
        } else {
            // console.log("##CS Fresh current state: " + stateChanged, cs)
            stateChanged = true;
        }
        // if (isPrgString) return { ...newRoundTable, currentState: cs, progress: prg }
        // else  
        return stateChanged ? cs : null
    }

    requestUpdateNextRtToLive = (newRoundtables) => {
        // console.log("requestUpdateNextRtToLive")
        const homeState = reduxStore.getState().roundTableState.homePageState
        const nextRt = homeState.nextRoundtableToLive
        const readyRts = { ...homeState.readyRoundtablesToLive }
        let isReadyListUpdated = false
        let nextRtCS = nextRt ? (this.getCurrentState(nextRt) || nextRt.currentState) : undefined
        let nearestTime = (nextRtCS && !nextRtCS.isClosed) ? moment(nextRt.tableDateTime).subtract(5,'minutes') : moment().add(7, 'days');   //TODO: take this 5 from configs
        let nearestRT = undefined

        const refreshReadyMap = async () => {
            const readyRtArr = Object.values(readyRts)
            if (readyRtArr.length) {
                for (let index = 0; index < readyRtArr.length; index++) {
                    let rt = readyRtArr[index]
                    const id = rt.id;
                    if (!newRoundtables) { //if newRoundtables passed assumes those RTs are fresh
                        try {
                            const newRt = await this.getRoundTable(id)
                            newRt.currentState = this.getCurrentState(newRt)
                            rt = newRt
                            reduxStore.dispatch(updateRoundtableInLists(newRt))
                        } catch (error) {
                            console.error("Error on get roundtable with id: " + id, error)
                        }
                    }

                    if (rt.currentState.isClosed) {
                        delete readyRts[id]
                        isReadyListUpdated = true
                    } 
                }
                if (isReadyListUpdated) reduxStore.dispatch(updateeRadyRoundtablesToLive(readyRts))
            }
        }

        const searchNearestRTinArray = (rts) => {
            if (rts && rts.length) {
                rts.forEach(rt => {
                    const cs = this.getCurrentState(rt) || rt.currentState
                    if (!cs.isClosed) {
                        const buffTime = moment(rt.tableDateTime).subtract(5,'minutes')  //TODO: take this from configs
                        if (nearestTime.isAfter(buffTime)) {
                            nearestTime = buffTime
                            // if (!rt.currentState) {
                            //     rt.currentState = cs
                            // }
                            nearestRT = rt
                        }
                        if (cs.isReadyToStart && !readyRts[rt.id]) {
                            readyRts[rt.id] = { ...rt, currentState: cs }
                            isReadyListUpdated = true
                        }
                    }
                });
            }
        }

        if (newRoundtables && newRoundtables.length) {
            searchNearestRTinArray(newRoundtables)
        } else {
            // check exploere list
            searchNearestRTinArray(homeState.explore.roundTables)
            searchNearestRTinArray(homeState.my.roundTables)
            searchNearestRTinArray(homeState.search.roundTables)
        }
        if (nearestRT) {
            // console.log("requestUpdateNextRtToLive: updateNextRoundtableToLive")
            reduxStore.dispatch(updateNextRoundtableToLive(nearestRT))
        } else if (nextRtCS && nextRtCS.isClosed) {
            // console.log("requestUpdateNextRtToLive: CLEARNextRoundtableToLive")
            reduxStore.dispatch(updateNextRoundtableToLive(null))
        }
        refreshReadyMap()
    }
}

export default new RoundTablesService()