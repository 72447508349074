import { useEffect } from "react";
import ReactGA from 'react-ga';
import "./flashStyles.css";

export function Flash() {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <div id="wr-web-main">
            <div id="wr-web-left">
                <img src="images/flash/logo-cypeer.png" width="250px" />
                <h1 className="hero">Stay up-to-date on cybersecurity news and events. <br /><br />Get Cypeer Flash now!</h1>
                <img src="images/flash/qr.png" />

                <div className="wr-download-btns">
                    <a target="_blank" href="https://apps.apple.com/us/app/cypeer-flash/id1610518229"><img src="images/flash/download-apple.png" width="200px" /></a>
                    &nbsp;
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.cypeer.flash"><img src="images/flash/download-android.png" width="200px" /></a>
                </div>
            </div>
            <div id="wr-web-right">
                <img src="images/flash/hero.png" width="100%" />
            </div>
            <div id="wr-footer">
                Copyright &copy; Protus Cyber Pte. Ltd. 2022. All rights reserved.
            </div>
        </div>
    );
}