import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const InfoCards = (props) => {
    return (
        <Box className="info_card"
            sx={{
                // border: 1,
                pb: { xs: 2, sm: 0 },
            }}
        >
            <Grid className="card_icon"
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: 1,
                    // border: 1,
                    // borderColor: 'green'
                }}
            >
                <Box 
                    sx={{
                        width: { xs: 60, sm: 75, md: 100 },
                        height: { xs: 60, sm: 75, md: 100 },
                        // border: 1,
                    }}>
                    <img src={'/icons/' + props.card.icon} alt=''
                        style={{
                            imageRendering: 'crisp-edges',
                            maxWidth: '-webkit-fill-available',
                            maxHeight: '-webkit-fill-available',
                            // border: 'solid',
                        }}>
                    </img>
                </Box>
            </Grid>
            <Box className="card_title"
                sx={{
                    width: 1,
                    // p: { md: 1 },
                    mb: { xs: 1, },
                    // border: 1,
                }}
            >
                <Typography variant='h4' fontWeight={400} textAlign={{xs: 'center', sm: 'left'}}>
                    {props.card.title}
                </Typography>
            </Box>
            <Box className="card_text"
                sx={{
                    // p: { md: 1 },
                    // border: 1,
                }}
            >
                <Typography variant='subtitle1' component="p" textAlign={{xs: 'center', sm: 'left'}}>
                    {props.card.text}
                </Typography>
            </Box>
        </Box>
    );
};