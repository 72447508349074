import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import RTBackdropPage from "../../components/RTBackdropPage";
import profileService from "../../services/profileService";
import RTPaper from "../../components/RTPaper";
import VerifyEmail from "../invites/VerifyEmail";
import { displayAlertDialog } from "../../components/AlertDialog";

export default function SignUpProcess() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [existingUser, setExistingUser] = useState(false);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [fullProfile, setFullProfile] = useState(undefined);

    let profile = useSelector(state => state.appState.myProfile);
    let infoString = localStorage.getItem('signUpInfo');
    let info = undefined;

    if (infoString) {
        try {
            info = JSON.parse(infoString);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (!info) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (profile.id && !profileLoaded) {
            setProfileLoaded(true);
        }
    }, [profile]);

    useEffect(() => {
        if (profileLoaded && !profile.isNewUser) {
            setLoading(false);
            setExistingUser(true);
        } else if (profileLoaded && profile.isNewUser) {
            if (profile.id) {
                updateProfile();
            }
        }
    }, [profileLoaded]);

    function updateProfile() {
        setLoading(true);
        setExistingUser(false);

        profileService.updateUserProfile(info)
            .then(data => {
                if (data === 200) {
                    localStorage.removeItem('signUpInfo');
                    setLoading(false);

                    profileService.getUserProfile().then(newProfile => {
                        // TODO - Correcting the miss-spelled property name "counrty" 
                        // Remove this after DB fix           
                        newProfile.country = newProfile.counrty;
                        delete newProfile.counrty;

                        setFullProfile(newProfile);

                        setTimeout(() => {
                            if (!newProfile.emailVerified) {
                                setVerifyEmail(true);
                            } else {
                                navigate('/');
                            }
                        }, 1000);
                    });

                } else {
                    setLoading(false);

                    displayAlertDialog({
                        title: "Something went wrong!",
                        message: "We couldn't update your profile. Please refresh the page."
                    });
                }
            });
    }

    function handleSkip() {
        if (!profile.emailVerified) {
            setFullProfile({...profile, email:"your email"});
            setExistingUser(false);
            setVerifyEmail(true);
        } else {
            navigate('/');
        }
    }

    return (
        <RTBackdropPage>
            <RTPaper>
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        minHeight: { xs: 400, lg: 500, xl: 700 },
                    }}
                >
                    {
                        loading ?
                            <Grid className='Loading'
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                // border={1}
                                height={{ xs: 400, lg: 500, xl: 700 }}
                            >
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                                {profileLoaded &&
                                    <Typography variant="h5">
                                        {profile.isNewUser ? 'Creating your Cypeer profile' : 'Updating your Cypeer profile'}
                                    </Typography>
                                }
                            </Grid>
                            :
                            !verifyEmail && !existingUser &&
                            <Grid className='Done'
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                // border={1}
                                height={{ xs: 400, lg: 500, xl: 700 }}
                            >
                                <Grid item>
                                    <CheckIcon sx={{ fontSize: 50 }} color="success" />
                                </Grid>
                                <Typography variant="h5">
                                    Done!
                                </Typography>
                            </Grid>
                    }
                    {
                        verifyEmail && <VerifyEmail profile={fullProfile} />
                    }
                    {
                        existingUser &&
                        <>
                            <Typography variant="h4" textAlign='center'>
                                You already have a profile in Cypeer.
                            </Typography>
                            <Typography variant="h4" textAlign='center'>
                                Would you like to update your profile with the new details?
                            </Typography>
                            <br />
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button variant="contained" onClick={updateProfile}>Update</Button>
                                <Button variant="outlined" sx={{ ml: 1 }} onClick={handleSkip}>Skip</Button>
                            </Grid>
                        </>
                    }
                </Grid>
            </RTPaper>
        </RTBackdropPage>
    );

}