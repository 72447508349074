import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  displayHeaderFooter: true,
  isValidAuthToken: false,
  alertState: {
    open: false,
    isYesNo: false,
    title: 'Cypeer',
    message: '',
    yesButtonText: 'OK',
    noButtonText: 'Cancel'
  }
}

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      //TODO: need to change this logic with checking token expiration
      let profile = action.payload;

      state.myProfile = profile;
    },
    updateLastRout: (state, action) => {
      //TODO: need to change this logic with checking token expiration
      console.log("updated last rout");

      state.lastRout = action.payload;
    },
    addRootNavigate: (state, action) => {
      //TODO: need to change this logic with checking token expiration
      console.log("updated root navigate");

      state.rooNavigate = action.payload;
    },
    setDisplayHeaderFooter: (state, action) => {
      state.displayHeaderFooter = action.payload;
    },
    setIsValidAuthToken: (state, action) => {
      state.isValidAuthToken = action.payload;
    },
    updateAlertStatus: (state, action) => {
      const payload = action.payload
      if (payload.open) {
        let newState = {
          open: true,
          isYesNo: payload.isYesNo,
          title: payload.title || '',
          message: payload.message || '',
          yesButtonText: payload.yesButtonText || 'OK',
          noButtonText: payload.noButtonText || 'Cancel'
        }
        state.alertState = newState

      } else {
        state.alertState.open = false
      }
    }
  }
});

export const { updateProfile, addRootNavigate, setDisplayHeaderFooter, setIsValidAuthToken, updateAlertStatus } = appStateSlice.actions

export default appStateSlice.reducer