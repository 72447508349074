import { Avatar, Badge, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';


const AwayBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#d9bb06',
        color: '#d9bb06',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
        },
    }
}));

const OfflineBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#bbbab6',
        color: '#bbbab6',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
        },
    }
}));

const OnlineBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0x90;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    let nameSplit = name.split(' ')
    let child = nameSplit[0][0]
    if (nameSplit[1]) child += nameSplit[1][0]
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: child,
    };
}

function getCongfigs(profile, sizeVal) {
    let cfg = {
        sx: {}
    }
    if (profile.profilePicture) {
        cfg.src = profile.profilePicture;
    } else {
        if (profile.id === "+rest#") {
            cfg = {
                sx: {
                    bgcolor: "#bdbdbd" //"#FFbdbd" //
                },
                children: profile.displayName
            }
        } else {
            cfg = stringAvatar(profile.displayName)
        }
    }
    let size = {};
    switch (sizeVal) {
        case "smaller":
            size = { width: '3rem', height: '3rem', fontSize: 'small' };
            break;
        case "small":
            size = { width: '4rem', height: '4rem', fontSize: 'smaller' };
            break;
        case "medium":
            size = { width: '5rem', height: '5rem', fontSize: 'medium' };
            break;
        case "large":
            size = { width: '6rem', height: '6rem', fontSize: 'larger' };
            break;
        default:
            break;
    }
    cfg.sx = { ...cfg.sx, ...size };
    return cfg;
}

const getMatchingAvatarForProfile = (profile, size, onClick, onlineStatus) => {
    const toolTip = profile.id === "+rest#" ? profile.displayName + " already joined" : profile.displayName
    //console.log("get avatar for: ", toolTip)
    if (!onlineStatus)
        return (
                <Tooltip title={toolTip} key={profile.id}>
                    <Avatar {...getCongfigs(profile, size)} alt={toolTip} onClick={onClick} key={profile.id} />
                </Tooltip>
        );
    if (onlineStatus === "Active") {
        return (
            <OnlineBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                key={profile.id}
            >
                <Tooltip title={toolTip} >
                    <Avatar {...getCongfigs(profile, size)} alt={toolTip} onClick={onClick} key={profile.id} />
                </Tooltip>
            </OnlineBadge>
        );
    } else if (onlineStatus === "Attended") {
        return (
            <AwayBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                key={profile.id}
            >
                <Tooltip title={toolTip} key={profile.id}>
                    <Avatar {...getCongfigs(profile, size)} alt={toolTip} onClick={onClick} key={profile.id} />
                </Tooltip>
            </AwayBadge>
        );
    } else {
        return (
            <OfflineBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                key={profile.id}
            >
                <Tooltip title={toolTip} key={profile.id}>
                    <Avatar {...getCongfigs(profile, size)} alt={toolTip} onClick={onClick} key={profile.id} />
                </Tooltip>
            </OfflineBadge>
        );
    }
}

export default getMatchingAvatarForProfile;