export const msalConfig = {
    auth: {
        clientId: window.AUTH_CLIENT_ID,
        authority: "https://" + window.AUTH_B2C_INSTANCE + "/" + window.AUTH_AZURE_INSTANCE + "/B2C_1_LinkedIn",
        knownAuthorities: [window.AUTH_B2C_INSTANCE],
        redirectUri: window.AUTH_REDIRECT_URI,
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    // system: { //# uncomment this if sso not worked properly after implement auth beyond linkedIn
    //     windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
    //     iframeHashTimeout: 10000, // Applies just to silent calls - In milliseconds
    //     loadFrameTimeout: 10000, // Applies to both silent and popup calls - In milliseconds
    //     allowRedirectInIframe: true
    // }
};

export const loginRequest = {
    scopes: ["openid", "offline_access", "profile", window.AUTH_CLIENT_ID]
};