import React, { useState } from 'react';
import { useSelector } from "react-redux";
import ReactGA from 'react-ga';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { OutlinedInput, Typography } from "@mui/material";
import { Box, Button } from '@mui/material'
import { shareInvite } from "./content";
import { sp3, sp1 } from '../../configs/spacings';
import RTBackdropPage from "../../components/RTBackdropPage";
import RTPaper from "../../components/RTPaper";

export default function ShareInvite({  }) {

    let profile = useSelector(state => state.appState.myProfile);
    let shareRef = profile.shareReference;
    const inviteLink = window.location.href + '/nomination/' + shareRef;
    const [linkCopied, setLinkCopied] = useState(false)
    let shareContent = {
        ...shareInvite.shareContent,
        shareUrl: inviteLink
    }

    ReactGA.pageview(window.location.pathname + '/shareInvite');

    return (
        <RTBackdropPage>
            <RTPaper styles={{ minHeight: '85vh' }}>
                <Box className="title"
                    sx={{
                        my: { xs: 1, md: 3 },
                        // border: 1
                    }}>
                    <Typography variant="h4">
                        {shareInvite.title}
                    </Typography>
                    <Typography variant="h5" sx={{ pt: 1 }}>
                        {shareInvite.subtitle}
                    </Typography>
                </Box>
                <Box sx={{
                    minWidth: { xs: 250, sm: 400 },
                    // border: 1
                }}>
                    <Box sx={{ display: 'flex', gap: sp1, alignItems: 'center', pt: sp3, flexDirection: 'row' }}>
                        <Box flexGrow={1}>
                            <OutlinedInput disabled defaultValue={shareContent.shareUrl}
                                sx={{
                                    width: '100%',
                                    "& legend": {
                                        width: 0,
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 80 }}>
                            {
                                linkCopied
                                    ? <Typography variant='subtitle1'> Copied </Typography>
                                    : <Button onClick={() => {
                                        navigator.clipboard.writeText(shareContent.shareUrl)
                                        setLinkCopied(true)
                                    }} >Copy Link</Button>
                            }
                        </Box>
                    </Box>

                    <Typography variant='subtitle2' align='center' py={sp3}>
                        {shareInvite.socialMediaButtonsTitle}
                    </Typography>

                    <Box sx={{ display: 'flex', gap: sp3, justifyContent: 'center', py: 1 }}>
                        <LinkedinShareButton
                            url={shareContent.shareUrl}
                            title={shareContent.title}
                            source={shareContent.shareUrl}
                            summary={shareContent.title}
                        >
                            <LinkedinIcon size={45} round />
                        </LinkedinShareButton>
                        <TwitterShareButton
                            url={shareContent.shareUrl}
                            title={shareContent.title}
                        >
                            <TwitterIcon size={45} round />
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={shareContent.shareUrl}
                            title={shareContent.title}
                            separator=":: "
                        >
                            <WhatsappIcon size={45} round />
                        </WhatsappShareButton>
                        <FacebookShareButton
                            url={shareContent.shareUrl}
                            quote={shareContent.title}
                        >
                            <FacebookIcon size={45} round />
                        </FacebookShareButton>
                        <EmailShareButton
                            url={shareContent.shareUrl}
                            subject={shareContent.subject}
                            body={shareContent.title}
                        >
                            <EmailIcon size={45} round />
                        </EmailShareButton>
                    </Box>
                </Box>
            </RTPaper>
        </RTBackdropPage>
    );
}