import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Navigate, useLocation } from 'react-router-dom'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box, Link, Paper } from '@mui/material';
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { CypeerLoading } from '../../components/CypeerLoading';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../services/authService';
import { setDisplayHeaderFooter } from '../../states/appState';
import LogoFull from '../../components/CypeerLogo';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TermsAndConditionsNotice from '../../components/TermsAndConditionsNote';

const Login = ({ redirectPath }) => {

  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const myProfile = useSelector(state => state.appState.myProfile);
  const { inProgress, accounts } = useMsal();
  const { state } = useLocation();
  const location = useLocation();
  const dispatch = useDispatch()

  const getTokenOrSignIn = (isManualRequest) => {

    //if (inProgress === InteractionStatus.None) {

    setLoadingAnimation(true);

    authService.refreshToken(isManualRequest)
      .catch(console.error)
      .finally(() => { setLoadingAnimation(false) });
    // }
  };

  useEffect(() => {

    ReactGA.pageview(window.location.pathname);

    let acc = accounts[0];

    if ((inProgress === InteractionStatus.None || inProgress === InteractionStatus.HandleRedirect) && location.pathname !== "/auth") {
      if (!myProfile && (acc || (state && state.directLogin))) {
        getTokenOrSignIn();
      }
    }
    else {
      console.log("authentication is still in progress")
      setLoadingAnimation(true);
    }
    
  }, [inProgress]);

  const login = () => {
    authService.signIn() 
    //TODO:  remove above line and uncomment below line if sso not worked after auth implementation beyond LinkedIn
    //getTokenOrSignIn(true);
  }

  const ManulaAuth = () => {
    const spacing = { xs: 40, md: 120 }
    return <Box sx={{
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'backgroundSecondary.main'
    }}

    >
      <Paper variant="outlined" sx={{
        position: "absolute",
        top: spacing,
        bottom: spacing,
        left: spacing,
        right: spacing,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 5
      }}>

        <Grid container spacing={2} alignItems="center" flexDirection="column" width={{ xs: 250, sm: 300 }}>
          <Grid item height={{ xs: 42, sm: 42 }} pb={1} >
            <LogoFull />
          </Grid>
          <Grid item>
            <Typography variant='h4' align='center'>
              {myProfile? 'Welcome Back':  'Welcome'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6' textAlign='center'>
              { myProfile?  'You need to signin again to continue' : 'Discover and connect with peers in a whole new way'}
              
            </Typography>
          </Grid>
          <Grid item>
            <Button
              required
              id="description"
              name="description"
              label="Short Description"
              fullWidth
              startIcon={<LinkedInIcon />}
              variant="contained"
              onClick={() => login()}
            > Sign In with LinkedIn</Button>
          </Grid>
          <Grid item container alignItems="center" justifyContent="center">
            <Typography variant='subtitle2'>New to Cypeer?</Typography>
            <Button
              variant="text"
              onClick={() => login()}
            //sx={{ fontSize: '.9em'}}
            > Join with LinkedIn</Button>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <TermsAndConditionsNotice prefixText={'By joining with LinkedIn'} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  }

  return (
    <div>
      {/* {loadingAnimation ?
        <Box position='absolute' top='30%' width='100%'>
          <CypeerLoading label="" />
        </Box>
        : authService.isLoggedIn() && myProfile ?
          myProfile.approved ?
            !myProfile.isNewUser ?
              redirectPath ? <Navigate to={redirectPath} replace={true} /> : <Navigate to="/" replace={true} />
              : <Navigate to="/profile/me#edit" replace={true} />
            : <Navigate to="/invite" replace={true} />
          : <ManulaAuth />
      } */}
      {loadingAnimation ?
        <Box position='absolute' top='30%' width='100%'>
          <CypeerLoading label="" />
        </Box>
        :  authService.isLoggedIn() && myProfile ?
          <Navigate to="/" replace={true} />
        : <ManulaAuth />
      }
    </div>
  );
};

export default Login;