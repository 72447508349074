import React from "react";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

function CypeerFooter() {
    return (
        <Grid className='footer'
            id = "cyp_footer"
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
                width: 1,
                maxWidth: 1,
                height: { xs: 100, lg: 150 },
                backgroundColor: 'primaryX.main',
                // border: 1,
            }}
        >
            <Grid className="copy_rights"
                container
                direction="row"
                alignItems="center"
                justifycontent="center"
                // border={1}
                height='fit-content'
                width='fit-content'
                item
                xs={4}
                px={1}
            >
                <Typography variant="subTextResponsive" color="white">Copyright &copy; 2022 Protus Cyber Pte. Ltd. All Rights Reserved</Typography>
            </Grid>
            <Grid className="policies"
                container
                direction="row"
                alignItems="center"
                justifycontent="center"
                // border={1}
                height='fit-content'
                width='fit-content'
                item
                xs={6}
                sm={4}
                px={1}
            >
                <Grid item paddingRight={5}>
                    <Link href="/terms-and-conditions"
                        underline="hover"
                        target="_blank"
                        rel="noreferrer">
                        <Typography variant="subTextResponsive" color="white">Terms and Conditions</Typography>
                    </Link>
                </Grid>
                <Grid item paddingRight={5}>
                    <Link href="/privacy-policy"
                        underline="hover"
                        target="_blank"
                        rel="noreferrer">
                        <Typography variant="subTextResponsive" color="white">Privacy Policy</Typography>
                    </Link>
                </Grid>
                <Grid item paddingRight={5}>
                    <Link href="/cookie-policy"
                        underline="hover"
                        target="_blank"
                        rel="noreferrer">
                        <Typography variant="subTextResponsive" color="white">Cookie Policy</Typography>
                    </Link>
                </Grid>
            </Grid>
            <Grid className="social_media_icons"
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                // border={1}
                height='fit-content'
                width='fit-content'
                item
                xs={2}
                sm={3}
            >
                <Grid item className="linked_in"
                    sx={{
                        width: { xs: 20 },
                        height: { xs: 20 },
                    }}
                >
                    <a href="https://www.linkedin.com/company/cypeer/"
                        target="_blank"
                        rel="noreferrer">
                        <img src='/icons/social_linked_in.png' alt='LinkedIn'
                            style={{
                                width: '-webkit-fill-available',
                                // border: 'solid',
                            }}
                        />
                    </a>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CypeerFooter;